import React, { useEffect, useRef } from "react";
import { useStore } from "store";

let tvScriptLoadingPromise: Promise<Event> | undefined;

declare global {
  interface Window {
    TradingView: any; // Replace 'any' with the actual type if available
  }
}
export default function TradingViewWidget() {
  const onLoadScriptRef = useRef<(() => void) | null>(null); // Corrected type
  const { isDarkMode } = useStore();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise<Event>((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }
    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => {
      onLoadScriptRef.current = null;
    };

    function createWidget() {
      if (
        document.getElementById("tradingview_dcc28") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          width: 556,
          radius: 32,
          height: 385,
          symbol: "BINANCE:ETHUSD",
          interval: "D",
          timezone: "Etc/UTC",
          theme: isDarkMode ? "light" : "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          hide_volume: true,
          gridColor: "rgba(247, 233, 242, 0)",
          container_id: "tradingview_dcc28",
        });
      }
    }
  }, [isDarkMode]);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_dcc28" />
      <div className="tradingview-widget-copyright"></div>
    </div>
  );
}