import axios, { AxiosResponse } from 'axios';
import { ethers } from 'ethers';
import { ABIs, Addresses } from './data';
import { tentativeStateForNextCycle, tentativeStateForNextCycleNew, vaultTokenValue } from './functions';
import { Timing } from './type';

export const fetchAddressAssets = async (address: string): Promise<any> => {
  const url = `https://deep-index.moralis.io/api/v2/${address}/erc20`;
  const params = {
    chain: 'bsc testnet',
  };
  const headers = {
    accept: 'application/json',
    'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
  };
  try {
    const response: AxiosResponse = await axios.get(url, { params, headers });
    const data = await response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};
export const fetchNativeToken = async (address: string): Promise<any> => {
  const url = `https://deep-index.moralis.io/api/v2.2/${address}/balance`;
  const params = {
    chain: 'bsc testnet',
  };
  const headers = {
    accept: 'application/json',
    'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
  };
  try {
    const response: AxiosResponse = await axios.get(url, { params, headers });
    const data = await response.data;
    return data.balance;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTokenList = async (chainId: number): Promise<any> => {
  const options = {
    method: 'GET',
    headers: { accept: 'application/json' },
  };

  const url = `https://ag.kanalabs.io/dev/getTokenListByChainId?chainId=${chainId}`;

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
export const fetchHistory = async (address: string): Promise<any> => {
  const options = {
    method: 'GET',
    headers: { accept: 'application/json' },
  };

  const url = `https://switch-testnet-backend.kanalabs.io/transcation?address=${address}`;

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetchHistory data:', error);
  }
};
export const fetchProfitData = async (address: string): Promise<any> => {
  const options = {
    method: 'GET',
    headers: { accept: 'application/json' },
  };

  const url = `https://ag.kanalabs.io/switch/fetchHistory?walletAddress=${address}`;

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetchProfitData data:', error);
  }
};
export const fetchDisclaimer = async (address: string): Promise<any> => {
  const options = {
    method: 'GET',
    headers: { accept: 'application/json' },
  };

  const url = `https://ag.kanalabs.io/switch/fetchDisclaimer?walletAddress=${address}`;

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetchProfitData data:', error);
  }
};
export const insertDisclaimerData = async (address: string,mail:string,result:boolean): Promise<any> => {
  const options = {
    method: 'POST',  
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify({ 
      walletaddress: address,
      result:result,
      mail:mail,
     }),  
  };

  const url = 'https://ag.kanalabs.io/switch/insertDisclaimer';  

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetchDisclaimerData data:', error);
  }
};

export function getUiAmount(number: number, tokenDecimal: number) {
  return number / Math.pow(10, tokenDecimal);
}

export const preventPasteNegativeNumber = (e: any) => {
  const clipboardData = e.clipboardData || (window as any).clipboardData;
  const value = clipboardData.getData('text');
  if (!value || value.includes('-')) {
    e.preventDefault();
  } else {
    const pastedData = parseFloat(value);
    if (pastedData < 0) {
      e.preventDefault();
    }
  }
};

export async function getFilteredNFTs(address: string, provider: any): Promise<any> {
  const url = `https://deep-index.moralis.io/api/v2/${address}/nft`;
  const params = {
    chain: 'bsc testnet',
    format: 'decimal',
    media_items: false,
    token_addresses: [Addresses.Vault],
  };
  const headers = {
    accept: 'application/json',
    'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
  };
  const contract = new ethers.Contract(Addresses.Vault, ABIs.Vault, provider);
  try {
    const response: AxiosResponse = await axios.get(url, { params, headers });
    if (response.data.result && response.data.result.length > 0) {
      const filteredItems = response.data.result.filter(async (item: any) => {
        return item.contract_type === 'ERC1155' && item.token_address.toLowerCase() === Addresses.Vault.toLowerCase();
      });
      let updatedData = await Promise.all(
        filteredItems.map(async (item: any) => {
          const [depositToken, claimToken] = await Promise.all([
            contract.depositToken(item.token_id),
            contract.claimToken(item.token_id),
          ]);
          const amount = getUiAmount(item.amount, 18);
          item['balance'] = amount;
          item['isAvailable'] = depositToken && claimToken;
          if (depositToken && claimToken) {
            item['TokenName'] =
              depositToken.toLowerCase() === Addresses.LongToken.toLowerCase()
                ? 'long'
                : depositToken.toLowerCase() === Addresses.ShortToken.toLowerCase()
                ? 'short'
                : 'usdc';

            item['ClaimName'] =
              claimToken.toLowerCase() === Addresses.LongToken.toLowerCase()
                ? 'long'
                : claimToken.toLowerCase() === Addresses.ShortToken.toLowerCase()
                ? 'short'
                : 'usdc';
          }
          return item;
        })
      );
      await Promise.all(
        filteredItems.map(async (item: any) => {
          const { finalTokenName, symbol, token, finalTokenNameInPosition, type }: any = await getFullTokenInfo(
            item.ClaimName,
            item.TokenName
          );
          const tokenData = await vaultTokenValue(address, item.token_id, provider);
          const TokenamountFinal = getUiAmount(Number(ethers.BigNumber.from(tokenData.tokenAmount).toString()), 18);
          const TokenProfitFinal = (TokenamountFinal - item.balance).toFixed(2);
          if (finalTokenName && symbol) {
            item.FullTokenName = finalTokenName;
            item.FullTokenNameSymbol = symbol;
            item.TokenNameInOpen = token;
            item.positions = finalTokenNameInPosition;
            item.TokenHistoryType = type;
            item.TokenFinalOutAmount = TokenamountFinal;
            item.TokenProfitFinal = TokenProfitFinal;
          }
          return item;
        })
      );
      return updatedData;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function getFilteredNFTsHistory(address: string, provider: any): Promise<any> {
  const url = `https://deep-index.moralis.io/api/v2/${address}/nft/transfers`;
  const params = {
    chain: 'bsc testnet',
    format: 'decimal',
    limit: 20,
    direction: 'both',
  };
  const headers = {
    accept: 'application/json',
    'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
  };
  const contract = new ethers.Contract(Addresses.Vault, ABIs.Vault, provider!);
  try {
    const response: AxiosResponse = await axios.get(url, { params, headers });
    if (response.data.result && response.data.result.length > 0) {
      let filteredItems = response.data.result.filter((item: any) => {
        return (
          item.contract_type === 'ERC1155' &&
          item.token_address.toLowerCase() === Addresses.Vault.toLowerCase() &&
          item.to_address.toLowerCase() === address.toLowerCase()
        );
      });
      let updatedData = await Promise.all(
        filteredItems.slice(0, 10).map(async (item: any) => {
          const [depositToken, claimToken] = await Promise.all([
            contract.depositToken(item.token_id),
            contract.claimToken(item.token_id),
          ]);
          const amount = getUiAmount(item.amount, 18);
          item['balance'] = amount;
          item['isAvailable'] = depositToken && claimToken;
          if (depositToken && claimToken) {
            item['TokenName'] =
              depositToken.toLowerCase() === Addresses.LongToken.toLowerCase()
                ? 'long'
                : depositToken.toLowerCase() === Addresses.ShortToken.toLowerCase()
                ? 'short'
                : 'usdc';

            item['ClaimName'] =
              claimToken.toLowerCase() === Addresses.LongToken.toLowerCase()
                ? 'long'
                : claimToken.toLowerCase() === Addresses.ShortToken.toLowerCase()
                ? 'short'
                : 'usdc';
          }
          return item;
        })
      );
      await Promise.all(
        filteredItems.slice(0, 10).map(async (item: any) => {
          const { finalTokenName, symbol, token, finalTokenNameInPosition, type }: any = await getFullTokenInfo(
            item.ClaimName,
            item.TokenName
          );

          if (finalTokenName && symbol) {
            item.FullTokenName = finalTokenName;
            item.FullTokenNameSymbol = symbol;
            item.TokenNameInOpen = token;
            item.positions = finalTokenNameInPosition;
            item.TokenHistoryType = type;
          }
          return item;
        })
      );
      updatedData = await Promise.all(
        updatedData.map(async (item: any) => {
          if (item.TokenHistoryType==="Sell") {
            try {
              const txReceipt = await provider.getTransactionReceipt(
                item.transaction_hash
              );
              if (txReceipt) {
                const logs = txReceipt.logs;
                const tokenAddresses = [
                  "0x30B74a8766563DbAbAf04e0FFE392817F2D02Da1",
                  "0x71915496973eE59e36E140e5BBc2589d3282A453",
                  "0xd3a6E24F03943bf56bdc275E5603bff8F7E2e4C8",
                ];
                if (logs.length > 0) {
                  const data = logs.filter((item:any) =>
                    tokenAddresses.some(
                      (address) =>
                        item.address.toLowerCase() === address.toLowerCase()
                    )
                  );
                  if (data.length > 0) {
                    const abi = [
                      "event Transfer(address indexed from, address indexed to, uint256 value)",
                    ];
                    const iface = new ethers.utils.Interface(abi);
                    const decodedData = iface.decodeEventLog(
                      "Transfer",
                      data[0].data,
                      data[0].topics
                    );
                    const bigNumberValue = ethers.BigNumber.from(
                      decodedData[2].toString()
                    );
                    const scaledValue = bigNumberValue.div(
                      ethers.BigNumber.from(10).pow(18)
                    ); // Dividing by 10^18 to scale down
                    item['Finaluiamount'] = Number(scaledValue.toString());
                  } else {
                    console.log(
                      "No logs found for this transaction from the specified token contract."
                    );
                  }
                } else {
                  console.log("No logs found for this transaction.");
                }
              } else {
                console.log(
                  "Transaction receipt not found for the provided hash."
                );
              }
            } catch (error) {
              console.error("Error fetching logs:", error);
            }
          }
          return item;
        })
      );
      return updatedData;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function getOrders(address: string, provider: any): Promise<any> {
  let data = await getFilteredNFTs(address, provider);
  const contract = new ethers.Contract(Addresses.SettlementManager, ABIs.SettlementManager, provider!);
  const lastSettlementPrice = await contract.lastSettlementPrice();
  const lastSettlementTime = await contract.lastSettlementTime();
  const final = await tentativeStateForNextCycle(lastSettlementTime, lastSettlementPrice, provider);
  if (final) {
    const availableTokens = [
      final.longDepositTokenId,
      final.shortDepositTokenId,
      final.longWithdrawalTokenId,
      final.shortWithdrawalTokenId,
    ];

    data = data.map((item: any) => {
      item['isAvailable'] = availableTokens.includes(item.token_id);
      return item;
    });
  }
  return data;
}
export function convertUTCToIST(utcTimestamp: string): string {
  const utcDate = new Date(utcTimestamp);
  const formattedTimestamp = utcDate.toISOString().replace('T', ' ').replace('Z', '').slice(0, -4);
  // Extract the hours and minutes
  const parts = formattedTimestamp.split(' ');
  const timeParts = parts[1].split(':');
  const hours = parseInt(timeParts[0]);
  // Calculate AM or PM
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12
  const formattedTime = `${formattedHours}:${timeParts[1]} ${amOrPm}`;
  const formattedDate = `${parts[0]}`;
  return `${formattedDate} ${formattedTime}`;
}

export const getERC20Allowance = async (signer: any, spender: any, amount: any) => {
  const amountInAllowance = ethers.utils.parseEther(amount.toString());
  let isAllowed = false;
  try {
    const contract = new ethers.Contract(Addresses.ProxyUSDC, ABIs.ProxyUSDC, signer);
    const allowance = await contract.allowance(signer._address, spender);
    if (Number(parseInt(allowance._hex)) >= Number(parseInt(amountInAllowance._hex))) {
      isAllowed = true;
    }
    return isAllowed;
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
};

export const getFullTokenInfo = async (claim: string, deposit: string) => {
  try {
    let finalTokenName: string;
    let symbol: string;
    let token: string;
    let finalTokenNameInPosition: string;
    let type: string;

    if (claim === 'usdc' && deposit === 'long') {
      finalTokenName = 'Long Switch Token Sell';
      symbol = 'USDC';
      token = 'Long;';
      type = 'Sell';
      finalTokenNameInPosition = 'From Long Switch Token (SELL)';
    } else if (claim === 'usdc' && deposit === 'short') {
      finalTokenName = 'Short Switch Token Sell';
      symbol = 'USDC';
      token = 'Short';
      type = 'Sell';
      finalTokenNameInPosition = 'From Short Switch Token (SELL)';
    } else if (claim === 'long' && deposit === 'usdc') {
      finalTokenName = 'Long Switch Token Buy';
      symbol = 'LS';
      token = 'Long';
      type = 'Buy';
      finalTokenNameInPosition = 'From Long Switch Token (BUY)';
    } else if (claim === 'short' && deposit === 'usdc') {
      finalTokenName = 'Short Switch Token Buy';
      symbol = 'SS';
      token = 'Short';
      type = 'Buy';
      finalTokenNameInPosition = 'From Short Switch Token (BUY)';
    } else {
      throw new Error('Invalid claim or deposit type');
    }

    return { finalTokenName, symbol, token, finalTokenNameInPosition, type };
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
};
export async function getProfit(address: string, balancedata: any, orders: any): Promise<any> {
  let LongSwitchTokenBuyResult: any = 0;
  let ShortSwitchTokenBuyResult: any = 0;
  let LongSwitchTokenSellResult: any = 0;
  let ShortSwitchTokenSellResult: any = 0;
  if (orders) {
    if (orders.length > 0) {
      const tokenCategoryMap: { [key: string]: number[] } = {
        'Long Switch Token Buy': [],
        'Short Switch Token Buy': [],
        'Long Switch Token Sell': [],
        'Short Switch Token Sell': [],
      };
      for (const record of orders) {
        const category = record.FullTokenName;
        if (tokenCategoryMap.hasOwnProperty(category)) {
          tokenCategoryMap[category].push(record.balance);
        }
      }
      const sumArray = (arr: number[]) => arr.reduce((acc, val) => acc + val, 0);
      LongSwitchTokenBuyResult = sumArray(tokenCategoryMap['Long Switch Token Buy']);
      ShortSwitchTokenBuyResult = sumArray(tokenCategoryMap['Short Switch Token Buy']);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      LongSwitchTokenSellResult = sumArray(tokenCategoryMap['Long Switch Token Sell']);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ShortSwitchTokenSellResult = sumArray(tokenCategoryMap['Short Switch Token Sell']);
    }
  }
  const long = getUiAmount(
    balancedata.longToken ? balancedata.longToken.balance : 0,
    balancedata.longToken ? balancedata.longToken.decimals : 0
  );
  const short = getUiAmount(
    balancedata.shortToken ? balancedata.shortToken.balance : 0,
    balancedata.shortToken ? balancedata.shortToken.decimals : 0
  );
  const data = await fetchHistory(address);
  const profit = await fetchProfitData(address);
  const differenceLongToken =
    (profit.BuyLongToken ? profit.BuyLongToken : 0) -
    (profit.SellLongToken ? profit.SellLongToken : 0) -
    (profit.CancelLongTokenBuy ? profit.CancelLongTokenBuy : 0) +
    (profit.CancelLongTokenSell ? profit.CancelLongTokenSell : 0);
  const differenceShortToken =
    (profit.BuyShortToken ? profit.BuyShortToken : 0) -
    (profit.SellShortToken ? profit.SellShortToken : 0) -
    (profit.CancelShortTokenBuy ? profit.CancelShortTokenBuy : 0) +
    (profit.CancelShortTokenSell ? profit.CancelShortTokenSell : 0);
  const longDiff = differenceLongToken - LongSwitchTokenBuyResult;
  const shortDiff = differenceShortToken - ShortSwitchTokenBuyResult;
  const final = {
    long: Number(long - longDiff).toFixed(2),
    short: Number(short - shortDiff).toFixed(2),
  };
  return final;
}
// function subtractAndMakePositive(a: number, b: number): number {
//   const result = a - b;
//   return Math.abs(result);
// }
export async function getTiming(signer: any): Promise<any> {
  const contract = new ethers.Contract(Addresses.SettlementManager, ABIs.SettlementManager, signer);
  const lastSettlementPrice = await contract.lastSettlementPrice();
  const lastSettlementTime = await contract.lastSettlementTime();
  const final: Timing = await tentativeStateForNextCycleNew(lastSettlementTime, lastSettlementPrice, signer);
  return final;
}

export async function calculateValue(amount: any, final: any, ETHPrice: any): Promise<any> {
  const result =
    (Number(amount) * ETHPrice * Number(final[0].tentativeLongTokenSupply)) /
    (Number(final[0].tentativeShortTokenSupply) - (Number(amount) * ETHPrice));
    return Math.abs(result);
}

export const getEventData = async () => {
  let data = await axios.get('https://ag.kanalabs.io/switch/fetchEventData');
  return data?.data.data;
};

export async function getDayBefore() {
  const DayBefore = await getEventData();
  const mappedLong = DayBefore.dayBefore.map((item: any, index: number) => {
    const rValue = (item.longAfter - item.longBefore) / item.longBefore;
    return rValue; // Return the rValue from the map function
  });
  const mappedShort = DayBefore.dayBefore.map((item: any, index: number) => {
    const rValue = (item.shortAfter - item.shortBefore) / item.shortBefore;
    return rValue; // Return the rValue from the map function
  });
  const nLong = mappedLong.length; // Calculate the value of n
  const nShort = mappedShort.length; // Calculate the value of n
  return {
    Long: Number((calculateGeometricMean(mappedLong, nLong) * 100).toFixed(2)),
    Short: Number((calculateGeometricMean(mappedShort, nShort) * 100).toFixed(2)),
  };
}
export async function getChart(): Promise<any[]> {
  const CHART = await getEventData();
  const slicedDay = CHART.day.slice(0, 6);
  const mappedData = slicedDay.map((item: any, index: number) => {
    const { time, date } = formatDateTime(CHART.day.slice(0, 7)[index+1].blockTimeStamp);
    const calculatePercentageChange = (before: number, after: number): string => {
      const percentageChange = (((after - before) / before) * 100).toFixed(2);
      return percentageChange;
    };
    const long = calculatePercentageChange(item.longBefore, item.longAfter);
    const short = calculatePercentageChange(item.shortBefore, item.shortAfter);
    const longValue = item.longBefore;
    const shortValue = item.shortBefore;
    // Calculate the time duration from the previous date and time, except for the first element
    let timeDuration = null;
    const currentDateTime = formatDateTime(item.blockTimeStamp);
    const previousDateTime = formatDateTime(CHART.day.slice(0, 7)[index+1].blockTimeStamp);
    timeDuration = calculateTimeDifference(
      previousDateTime.date,
      previousDateTime.time,
      currentDateTime.date,
      currentDateTime.time,
    );
    const currentEthPrice = item.ethPrice;
    const previousEthPrice = CHART.day.slice(0, 7)[index+1].ethPrice;
    const eth = calculateEthPriceDifference(Number(currentEthPrice),Number(previousEthPrice)).toFixed(2);
    const leverage = Number(shortValue / longValue).toFixed(2);
    const shortdate = convertDate(date);
    return {
      time,
      date,
      long,
      short,
      longValue,
      shortValue,
      timeDuration,
      eth,
      leverage,
      shortdate,
    };
  });
  return mappedData.reverse();
}

function formatDateTime(blocktimestamp: any) {
  const timestamp = new Date(blocktimestamp * 1000);
  const dateObj = new Date(timestamp);
  const formattedTimestamp = dateObj.toISOString().replace('T', ' ').replace('Z', '').slice(0, -4);
  // Extract the hours and minutes
  const parts = formattedTimestamp.split(' ');
  const timeParts = parts[1].split(':');
  const hours = parseInt(timeParts[0]);
  // Calculate AM or PM
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12
  const formattedTime = `${formattedHours}:${timeParts[1]} ${amOrPm}`;
  const formattedDate = `${parts[0]}`;
  return {
    time: formattedTime,
    date: formattedDate,
  };
}
function calculateTimeDifference(lastDate: string, lastTime: string, currentDate: string, currentTime: string): string {
  const lastDateTime = new Date(`${lastDate} ${lastTime}`);
  const currentDateTime = new Date(`${currentDate} ${currentTime}`);

  const timeDifferenceInMilliseconds: number = currentDateTime.getTime() - lastDateTime.getTime();

  const minutes: number = Math.floor((timeDifferenceInMilliseconds / (1000 * 60)) % 60);
  const hours: number = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));

  const formattedDifference = `${hours} Hrs ${minutes} Mins`;

  return formattedDifference;
}
function calculateEthPriceDifference(ethPrice1: number, ethPrice2: number): number {
  const priceDifference = ethPrice1 - ethPrice2;
  const percentageDifference = (priceDifference / ethPrice1) * 100;
  return percentageDifference;
}
function convertDate(dateString: any) {
  const parts = dateString.split('-');
  if (parts.length === 3) {
    const month = parts[1];
    const day = parts[2];
    return day + '/' + month;
  } else {
    return 'Invalid date';
  }
}

function calculateGeometricMean(values: number[], n: number): number {
  if (n <= 0) {
    throw new Error('n must be a positive integer');
  }

  if (values.length !== n) {
    throw new Error('The number of values must match n');
  }

  const product = values.reduce((accumulator, currentValue) => accumulator * (1 + currentValue), 1);
  const geometricMean = Math.pow(product, 1 / n) - 1;

  return geometricMean;
}
