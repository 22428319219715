import { StateSlice, Store } from 'store/types';
import { Timing } from 'utils/type';
export type AppSlice = {
  isDarkMode: boolean;
  isWalletConnectVisible: boolean;
  isSelectedChain: number;
  web3AuthInfo: string;
  isCalculateVisible: boolean;
  isDisclaimerVisible: boolean;
  switchSettlementList: any;
  dashboardInputValue: any;
  chartValue: any;
  ETHPrice: any;
  timing: Timing;
  expectedReturn: any,
  activeTab:any,
  updateIsDarkMode(payload: boolean): void;
  updateIsWalletConnectVisible(payload: boolean): void;
  updateIsSelectedChain(payload: number): void;
  updateWeb3AuthInfo(payload: string): void;
  updateIsCalculateVisible(payload: boolean): void;
  updateIsDisclaimerVisible(payload: boolean): void;
  updateSwitchSettlementList(payload: any): void;
  updateDashboardInputValue(payload: string): void;
  updateChartValue(payload: any): void;
  updateETHPrice(payload: any): void;
  updateTiming(payload: Timing): void;
  updateExpectedReturn(payload: any): void;
  updateActiveTab(payload: any): void;
};

export const createAppSlice: StateSlice<Store, AppSlice> = (set) => ({
  isDarkMode: localStorage.getItem('isDarkMode') === 'true',
  isWalletConnectVisible: false,
  isSelectedChain: 97,
  web3AuthInfo: '',
  isCalculateVisible: false,
  isDisclaimerVisible: false,
  switchSettlementList: [],
  dashboardInputValue: '',
  chartValue: [],
  ETHPrice:0,
  expectedReturn: {},
  timing: {
    cycleStartTimestampFinal: '00:00',
  tentativeCycleEndTimeFinal: '00:00',
  cycleStartPriceFinal: '0.00',
  tentativeCycleEndPriceFinal: '0.00',
  triggerPriceUp: '0.00',
  triggerPriceDown: '0.00',
  currentExpectedPremium: '0.00',
  nextExpectedPremium: '0.00',
  currentPercentageChart: {
    longTokenPercentage: 0,
    shortTokenPercentage: 0
  },
  nextPercentageChart: {
    longTokenPercentage: 0,
    shortTokenPercentage: 0
  },
  currentCyclelongTokenSupply: 0,
  currentCycleshortTokenSupply: 0,
  nextCyclelongTokenSupply: 0,
  nextCycleshortTokenSupply: 0,
  currentLeverage:0,
  nextLeverage:0,
  },
  activeTab:'switch',
  updateIsDarkMode(payload: AppSlice['isDarkMode']) {
    set({ isDarkMode: payload });
    localStorage.setItem('isDarkMode', payload.toString());
  },
  updateIsWalletConnectVisible(payload: AppSlice['isWalletConnectVisible']) {
    set({ isWalletConnectVisible: payload });
  },
  updateIsSelectedChain(payload: AppSlice['isSelectedChain']) {
    set({ isSelectedChain: payload });
  },
  updateWeb3AuthInfo(payload: AppSlice['web3AuthInfo']) {
    set({ web3AuthInfo: payload });
  },
  updateIsCalculateVisible(payload: AppSlice['isCalculateVisible']) {
    set({ isCalculateVisible: payload });
  },
  updateIsDisclaimerVisible(payload: AppSlice['isDisclaimerVisible']) {
    set({ isDisclaimerVisible: payload });
  },
  updateSwitchSettlementList(payload: AppSlice['switchSettlementList']) {
    set({ switchSettlementList: payload });
    localStorage.setItem('switchSettlementList', JSON.stringify(payload));
  },
  updateDashboardInputValue(payload: AppSlice['dashboardInputValue']) {
    set({ dashboardInputValue: payload });
  },
  updateChartValue(payload: AppSlice['chartValue']) {
    set({ chartValue: payload });
  },
  updateETHPrice(payload: AppSlice['ETHPrice']) {
    set({ ETHPrice: payload });
  },
  updateTiming(payload: AppSlice['timing']) {
    set({ timing: payload });
    localStorage.setItem('switchTiming', JSON.stringify(payload));
  },
  updateExpectedReturn(payload: AppSlice['expectedReturn']) {
    set({ expectedReturn: payload });
    localStorage.setItem('ExpectedReturn', JSON.stringify(payload));
  },
  updateActiveTab(payload: AppSlice['activeTab']) {
    set({ activeTab: payload });
    localStorage.setItem('activeTab', payload.toString());
  },
});
