import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

function Timer() {
  const [time, setTime] = useState(DateTime.utc());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(DateTime.utc());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      {'GMT Time:'} {`${time.toFormat('hh:mm a')}`}
    </div>
  );
}

export default Timer;
