import React, { useState } from 'react';
import close from '../../assets/icons/close.svg';
import { useStore } from 'store';
import WrongBlack from '../../assets/icons/Wrong_black.svg';
import { insertDisclaimerData } from 'utils/helper';
interface DisclaimerProps {
    address: string;
    email: string;
  }
const  DisclaimerPopup: React.FC<DisclaimerProps> = ({ address, email })=> {
    const { isDarkMode, updateIsDisclaimerVisible } = useStore();

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    }
    const handelOnClickContinue = async () => {
        if (address) {
            const disclaimer = await insertDisclaimerData(address,email,true);
            if(!disclaimer.error){
                updateIsDisclaimerVisible(false)
            }
          }
      };
    return (
        <div className="fixed z-[3] inset-0 h-full w-full   backdrop-blur-lg flex flex-row justify-center font-inter  items-center">
            <div
                className={`${isDarkMode
                    ? 'bg-white 2xl:w-[560px] xl:w-[560px] lg:w-[560px] md:w-full sm:w-full max-sm:w-full h-auto border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] border-2 rounded-[1rem] '
                    : 'bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] 2xl:w-[560px] xl:w-[560px] lg:w-[560px] md:w-full sm:w-full max-sm:w-full h-auto border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] border-2 rounded-[1rem] '
                    }`}
            >
                <div
                    className={`${isDarkMode
                        ? 'flex flex-row justify-between items-center py-[20px] rounded-t-[1rem] px-[24px] bg-[#F6FEFC]'
                        : 'flex flex-row justify-between items-center py-[20px] rounded-t-[1rem] px-[24px] bg-[rgba(255,255,255,0.06)]'
                        }`}
                >
                    <div
                        className={`${isDarkMode
                            ? ' text-[#000000]  font-inter text-[20px] font-[700]'
                            : 'text-[white] font-inter text-[20px] font-[700]'
                            }`}
                    >
                        Terms & Conditions
                    </div>
                    <div
                        className="cursor-pointer  "
                        onClick={() => {
                            updateIsDisclaimerVisible(false);
                          }}
                    >
                        {isDarkMode ? <img src={WrongBlack} alt="WrongBlack" /> : <img src={close} alt="close" />}
                    </div>
                </div>
                <div
                    className={`${isDarkMode
                        ? 'bg-[#E4F2F3] p-[24px] flex flex-col gap-[8px] w-full rounded-[0rem_0rem_1rem_1rem]'
                        : 'bg-[#1D1E20] p-[24px] flex flex-col gap-[8px] w-full rounded-[0rem_0rem_1rem_1rem]'
                        }`}
                >
                    <div
                        className={`${isDarkMode
                            ? 'text-[14px] font-[400] text-[rgba(0,0,0,0.80)] flex flex-row justify-start items-center'
                            : 'text-[14px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center'
                            }`}
                    >
                        Read the terms & conditions before proceeding:

                    </div>
                    <div className={`${isDarkMode
                        ? 'text-left text-[black] text-[20px] font-[500] bg-[#F6FEFC] border-[1px] border-[#CFE7EA] w-full rounded-[1rem] outline-none px-[24px] py-[16px] placeholder:text-[black]'
                        : 'text-left text-[white] text-[20px] font-[500] bg-[#111213] border-[1px] border-[rgba(255,255,255,0.10)] w-full rounded-[1rem] outline-none px-[24px] py-[16px]'
                        } h-[14.5rem] overflow-y-auto`}>

                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400]  `}>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>I am not a person or entity who resides in, is a citizen of, is incorporated in, or has a registered office in the USA. I confirm I am not a US person in any form. </div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>I am not in any Prohibited jurisdiction.</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>I am not a sanctioned person under OFAC or representative of any entity which is under OFAC.</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>  I will not in the future access the Kana Labs website or use the Kana Labs Web App DeFi platform while located within the United States or any Prohibited Jurisdictions.</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span> I am lawfully permitted to access this site and use the Kana Labs DeFi platform under the laws of the jurisdiction in which I reside and am located.</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span> I understand that Kana Labs is a blockchain cross-chain specialist which aggregates liquidity and DeFi services from a wide range of dApps & DeFi protocols under one roof and is not providing any direct DeFi services be the mainstream transaction activities like swap & stake or structured crypto products like yield vaults or crypto derivatives.</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>  I understand the risks associated with entering into and using DeFi products and services available in the Kana Labs Web App platform.</div>
                        </div>
                    </div>
                    <form >
                        <div className="  py-[0.25rem] flex flex-row justify-start items-center  gap-[0.5rem]">
                            <input type="checkbox"   className='cursor-pointer '
                                checked={isChecked}
                                onChange={handleCheckboxChange} />
                            <label
                                // htmlFor='mycheck_box'
                                className={`${isDarkMode
                                    ? 'text-[14px] font-[400] text-[rgba(0,0,0,0.80)] flex flex-row justify-start items-center'
                                    : 'text-[14px] font-[400] text-[#D2D2D2] flex flex-row justify-start items-center'
                                    }  `}
                            >
                                I have read and agree the terms & conditions
                            </label>
                        </div>
                        {isChecked?(                       
                        <div className='w-full outline-none '
                        onClick={() => {
                            handelOnClickContinue();
                          }}>
                            <div
                                className={`${isDarkMode ? 'buy-button-light' : 'buy-button'}`}
                            >
                                <div
                                    className={`${isDarkMode
                                        ? 'cursor-pointer buy-button-inner-light text-[#107569] text-[16px] font-[700] text-center'
                                        : 'cursor-pointer buy-button-inner text-[#00f9a9] text-[16px] font-[700]  text-center'
                                        }`}
                                >
                                    Continue
                                </div>
                            </div>
                        </div>):(                       
                        <button className=' cursor-not-allowed  w-full outline-none'
                        disabled={isChecked}>
                            <div
                                className={` cursor-not-allowed ${isDarkMode ? 'buy-button-light-disabled ' : 'buy-button-disabled'}`}
                            >
                                <div
                                    className={`${isDarkMode
                                        ? 'cursor-not-allowed buy-button-inner-light text-[#4A4B4D] text-[16px] font-[700] text-center'
                                        : 'cursor-not-allowed  buy-button-inner text-[#4A4B4D] text-[16px] font-[700]  text-center'
                                        }`}
                                >
                                    Continue
                                </div>
                            </div>
                        </button>)}
                    </form>

                </div>
            </div>
        </div>
    )
}

export default DisclaimerPopup
