import React from 'react';
import { Bullet, BulletConfig } from '@ant-design/plots';

interface BulletChartProps {
  data: {
    title: string;
    ranges: number[];
    measures: number[];
    target: number;
  }[];
  colors: {
    range: string[];
    measure: string[];
    target: string;
  };
}

const BulletChart: React.FC<BulletChartProps> = ({ data, colors }) => {
  const config: BulletConfig = {
    data,
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    xField: 'title',
    tooltip: false,
    color: colors,
    label: {
      measure: false,
    },
    xAxis: {
      line: null,
    },
    yAxis: false,
    legend: {
      custom: true,
      position: 'bottom',
      items: [],
    },
  };

  return <Bullet {...config} />;
};

export default BulletChart;
