import { ethers } from 'ethers';
import { ABIs, Addresses } from './data';
const DECIMAL_MULTIPLIER = ethers.utils.parseEther('1');

let dec = (val: any, scale: any) => {
  let shift;
  // eslint-disable-next-line eqeqeq
  if (scale == 'ether') {
    shift = 18;
  // eslint-disable-next-line eqeqeq
  } else if (scale == 'finney') shift = 15;
  else {
    shift = scale;
  }

  let strVal = val.toString();
  let decimalLoc = strVal.indexOf('.');

  if (decimalLoc >= 0) {
    strVal = strVal.substr(decimalLoc + 1);
    shift -= strVal.length - strVal.indexOf('.') - 1;
  }

  if (shift > 0) {
    return strVal.concat('0'.repeat(shift));
  } else {
    return strVal.substring(0, strVal.length + shift);
  }
};

export async function executeSettlement(
  cycleStartTimestamp: any,
  cycleEndTimestamp: any,
  cycleStartPrice: any,
  cycleEndPrice: any,
  optType: any,
  m0: any,
  longTokenSupply: any,
  shortTokenSupply: any,
  feesParams: any,
  signer: any
) {
  let vault = new ethers.Contract(Addresses.Vault, ABIs.Vault, signer);

  /** settlement */

  let m: any = 0;
  let x: any = 0;

  // eslint-disable-next-line eqeqeq
  if (!(longTokenSupply == 0 || shortTokenSupply == 0)) {
    m = m0
      .mul(dec(Math.sqrt(cycleEndTimestamp.sub(cycleStartTimestamp).toNumber() / 3600), 18))
      .div(DECIMAL_MULTIPLIER);

    let pctPriceChange = cycleEndPrice.mul(DECIMAL_MULTIPLIER).div(cycleStartPrice).sub(DECIMAL_MULTIPLIER);
    x = ethers.BigNumber.from(0);
    if (pctPriceChange.mul(optType) >= 0) {
      x = pctPriceChange.abs();
    }

    longTokenSupply = longTokenSupply
      .mul(
        DECIMAL_MULTIPLIER.add(x.mul(shortTokenSupply).div(longTokenSupply))
          .sub(m)
          .sub(feesParams.settlement_fees.mul(shortTokenSupply).div(longTokenSupply))
      )
      .div(DECIMAL_MULTIPLIER);

    shortTokenSupply = shortTokenSupply
      .mul(DECIMAL_MULTIPLIER.add(m.mul(longTokenSupply).div(shortTokenSupply)).sub(x).sub(feesParams.settlement_fees))
      .div(DECIMAL_MULTIPLIER);
  }

  /** withdrawals */
  let longWithdrawalTokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(
      ethers.utils.solidityPack(['string', 'uint256'], ['LONG_TOKEN_WITHDRAWAL', cycleStartTimestamp])
    )
  );

  let shortWithdrawalTokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(
      ethers.utils.solidityPack(['string', 'uint256'], ['SHORT_TOKEN_WITHDRAWAL', cycleStartTimestamp])
    )
  );

  longTokenSupply = longTokenSupply.sub(
    (await vault.totalDepositTokenAmount(longWithdrawalTokenId, Addresses.LongToken))
      .mul(DECIMAL_MULTIPLIER.sub(feesParams.unloading_fees))
      .div(DECIMAL_MULTIPLIER)
  );

  shortTokenSupply = shortTokenSupply.sub(
    (await vault.totalDepositTokenAmount(shortWithdrawalTokenId, Addresses.ShortToken))
      .mul(DECIMAL_MULTIPLIER.sub(feesParams.unloading_fees))
      .div(DECIMAL_MULTIPLIER)
  );

  /** switches */
  let longSwitchTokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(ethers.utils.solidityPack(['string', 'uint256'], ['LONG_TOKEN_SWITCH', cycleStartTimestamp]))
  );

  let shortSwitchTokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(
      ethers.utils.solidityPack(['string', 'uint256'], ['SHORT_TOKEN_SWITCH', cycleStartTimestamp])
    )
  );

  let longToShort = (await vault.totalDepositTokenAmount(longSwitchTokenId, Addresses.LongToken))
    .mul(DECIMAL_MULTIPLIER.sub(feesParams.switching_fees))
    .div(DECIMAL_MULTIPLIER);

  let shortToLong = (await vault.totalDepositTokenAmount(shortSwitchTokenId, Addresses.ShortToken))
    .mul(DECIMAL_MULTIPLIER.sub(feesParams.switching_fees))
    .div(DECIMAL_MULTIPLIER);

  longTokenSupply = longTokenSupply.add(shortToLong).sub(longToShort);

  shortTokenSupply = shortTokenSupply.add(longToShort).sub(shortToLong);

  /** deposits */
  let longDepositTokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(
      ethers.utils.solidityPack(['string', 'uint256'], ['LONG_TOKEN_DEPOSIT', cycleStartTimestamp])
    )
  );

  let shortDepositTokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(
      ethers.utils.solidityPack(['string', 'uint256'], ['SHORT_TOKEN_DEPOSIT', cycleStartTimestamp])
    )
  );

  longTokenSupply = longTokenSupply.add(
    (await vault.totalDepositTokenAmount(longDepositTokenId, Addresses.ProxyUSDC))
      .mul(DECIMAL_MULTIPLIER.sub(feesParams.loading_fees))
      .div(DECIMAL_MULTIPLIER)
  );

  shortTokenSupply = shortTokenSupply.add(
    (await vault.totalDepositTokenAmount(shortDepositTokenId, Addresses.ProxyUSDC))
      .mul(DECIMAL_MULTIPLIER.sub(feesParams.loading_fees))
      .div(DECIMAL_MULTIPLIER)
  );

  return {
    longTokenSupply: longTokenSupply,
    shortTokenSupply: shortTokenSupply,
    m: m,
    x: x,
    longWithdrawalTokenId: longWithdrawalTokenId,
    shortWithdrawalTokenId: shortWithdrawalTokenId,
    longSwitchTokenId: longSwitchTokenId,
    shortSwitchTokenId: shortSwitchTokenId,
    longDepositTokenId: longDepositTokenId,
    shortDepositTokenId: shortDepositTokenId,
  };
}

export async function tentativeStateForNextCycle(tentativeCycleEndTime: any, tentativeCycleEndPrice: any, signer: any) {
  let controller = new ethers.Contract(Addresses.Controller, ABIs.Controller, signer);
  let settlementManager = new ethers.Contract(Addresses.SettlementManager, ABIs.SettlementManager, signer);

  let longToken = new ethers.Contract(Addresses.LongToken, ABIs.SwitchToken, signer);
  let shortToken = new ethers.Contract(Addresses.ShortToken, ABIs.SwitchToken, signer);

  let longTokenSupply = await longToken.totalSupply();
  let shortTokenSupply = await shortToken.totalSupply();

  let optType = await controller.optType();
  let m0 = await controller.hourlyPremium();
  let feesParams = await controller.fees_params();

  let pendingSettlementCount = await settlementManager.pendingSettlementCyclesCount();
  let cycleStartTimestamp, cycleStartPrice;

  let settlementsCount = await settlementManager.settlementsCount();
  if (pendingSettlementCount > 0) {
    let cycleEndTimestamp, cycleEndPrice;
    for (let i = 0; i < pendingSettlementCount; i++) {
      let cycleData = await settlementManager.settlement_cycles(settlementsCount.add(i));
      cycleStartPrice = cycleData.cycle_start_price;
      cycleEndPrice = cycleData.cycle_end_price;

      cycleStartTimestamp = cycleData.cycle_start_time;
      cycleEndTimestamp = cycleData.cycle_end_time;

      let cycleEndState = await executeSettlement(
        cycleStartTimestamp,
        cycleEndTimestamp,
        cycleStartPrice,
        cycleEndPrice,
        optType,
        m0,
        longTokenSupply,
        shortTokenSupply,
        feesParams,
        signer
      );

      longTokenSupply = cycleEndState.longTokenSupply;
      shortTokenSupply = cycleEndState.shortTokenSupply;
    }
  }
  let cycleData = await settlementManager.settlement_cycles(settlementsCount.add(pendingSettlementCount));

  cycleStartPrice = cycleData.cycle_start_price;
  cycleStartTimestamp = cycleData.cycle_start_time;
  let tentativeCycleEndState = await executeSettlement(
    cycleStartTimestamp,
    tentativeCycleEndTime,
    cycleStartPrice,
    tentativeCycleEndPrice,
    optType,
    m0,
    longTokenSupply,
    shortTokenSupply,
    feesParams,
    signer
  );
  const weiPerEther = ethers.BigNumber.from('1000000000000000000');
  return {
    longTokenSupply: longTokenSupply.div(weiPerEther).toString(),
    shortTokenSupply: shortTokenSupply.div(weiPerEther).toString(),
    tentativeLongTokenSupply: tentativeCycleEndState.longTokenSupply.div(weiPerEther).toString(),
    tentativeShortTokenSupply: tentativeCycleEndState.shortTokenSupply.div(weiPerEther).toString(),
    tentative_m: tentativeCycleEndState.m.toString(),
    tentative_x: tentativeCycleEndState.x.toString(),
    longWithdrawalTokenId: tentativeCycleEndState.longWithdrawalTokenId.toString(),
    shortWithdrawalTokenId: tentativeCycleEndState.shortWithdrawalTokenId.toString(),
    longSwitchTokenId: tentativeCycleEndState.longSwitchTokenId.toString(),
    shortSwitchTokenId: tentativeCycleEndState.shortSwitchTokenId.toString(),
    longDepositTokenId: tentativeCycleEndState.longDepositTokenId.toString(),
    shortDepositTokenId: tentativeCycleEndState.shortDepositTokenId.toString(),
  };
}

export async function tentativeStateForNextCycleNew(
  tentativeCycleEndTime: any,
  tentativeCycleEndPrice: any,
  signer: any
) {
  let tentativeCycleEndState: any;
  let controller = new ethers.Contract(Addresses.Controller, ABIs.Controller, signer);
  let settlementManager = new ethers.Contract(Addresses.SettlementManager, ABIs.SettlementManager, signer);

  let longToken = new ethers.Contract(Addresses.LongToken, ABIs.SwitchToken, signer);
  let shortToken = new ethers.Contract(Addresses.ShortToken, ABIs.SwitchToken, signer);

  let longTokenSupply = await longToken.totalSupply();
  let shortTokenSupply = await shortToken.totalSupply();

  let optType = await controller.optType();
  let m0 = await controller.hourlyPremium();
  let feesParams = await controller.fees_params();

  let pendingSettlementCount = await settlementManager.pendingSettlementCyclesCount();
  let cycleStartTimestamp, cycleStartPrice;

  let settlementsCount = await settlementManager.settlementsCount();
  if (pendingSettlementCount > 0) {
    let cycleEndTimestamp, cycleEndPrice;
    for (let i = 0; i < pendingSettlementCount; i++) {
      let cycleData = await settlementManager.settlement_cycles(settlementsCount.add(i));
      cycleStartPrice = cycleData.cycle_start_price;
      cycleEndPrice = cycleData.cycle_end_price;

      cycleStartTimestamp = cycleData.cycle_start_time;
      cycleEndTimestamp = cycleData.cycle_end_time;
      let cycleEndState = await executeSettlement(
        cycleStartTimestamp,
        cycleEndTimestamp,
        cycleStartPrice,
        cycleEndPrice,
        optType,
        m0,
        longTokenSupply,
        shortTokenSupply,
        feesParams,
        signer
      );
      longTokenSupply = cycleEndState.longTokenSupply;
      shortTokenSupply = cycleEndState.shortTokenSupply;
    }
  }
  let cycleData = await settlementManager.settlement_cycles(settlementsCount.add(pendingSettlementCount));

  cycleStartPrice = cycleData.cycle_start_price;
  cycleStartTimestamp = cycleData.cycle_start_time;

  tentativeCycleEndState = await executeSettlement(
    cycleStartTimestamp,
    tentativeCycleEndTime,
    cycleStartPrice,
    tentativeCycleEndPrice,
    optType,
    m0,
    longTokenSupply,
    shortTokenSupply,
    feesParams,
    signer
  );
  const weiPerEther = ethers.BigNumber.from('1000000000000000000');
  const cycleStartTimestampFinal = formatTimestamp(cycleStartTimestamp.toString());
  const tentativeCycleEndTimeFinal = formatTimestamp(
    (Number(cycleStartTimestamp) + 7200).toString() // Adding 2 hour in seconds
  );
  let cycleStartPriceFinal = Removedecimal(cycleStartPrice).toFixed(2);
  let tentativeCycleEndPriceFinal = Removedecimal(tentativeCycleEndPrice).toFixed(2);
  let triggerPrice = calculateEthDeviation(Number(cycleStartPriceFinal), 0.3);
  let triggerPriceUp = triggerPrice.above.toFixed(2);
  let triggerPriceDown = triggerPrice.below.toFixed(2);
  let currentExpectedPremium = (
    (2 * Number(longTokenSupply.div(weiPerEther).toString())) /
    Number(shortTokenSupply.div(weiPerEther).toString())
  ).toFixed(2);
  let nextExpectedPremium = (
    (2 * Number(tentativeCycleEndState.longTokenSupply.div(weiPerEther).toString())) /
    Number(tentativeCycleEndState.shortTokenSupply.div(weiPerEther).toString())
  ).toFixed(2);
  let currentPercentageChart = calculatePercentageDifference(
    Number(longTokenSupply.div(weiPerEther).toString()),
    Number(shortTokenSupply.div(weiPerEther).toString())
  );
  let nextPercentageChart = calculatePercentageDifference(
    Number(tentativeCycleEndState.longTokenSupply.div(weiPerEther).toString()),
    Number(tentativeCycleEndState.shortTokenSupply.div(weiPerEther).toString())
  );
  let currentCyclelongTokenSupply= formatNumber(Number(longTokenSupply.div(weiPerEther).toString()));
  let currentCycleshortTokenSupply= formatNumber(Number(shortTokenSupply.div(weiPerEther).toString()));
  let nextCyclelongTokenSupply = formatNumber(Number(tentativeCycleEndState.longTokenSupply.div(weiPerEther).toString()));
  let nextCycleshortTokenSupply = formatNumber(Number(tentativeCycleEndState.shortTokenSupply.div(weiPerEther).toString()));
  let currentLeverage = Number((Number(shortTokenSupply.div(weiPerEther).toString())/Number(longTokenSupply.div(weiPerEther).toString())).toFixed(1));
  let nextLeverage = Number((Number(tentativeCycleEndState.shortTokenSupply.div(weiPerEther).toString())/Number(tentativeCycleEndState.longTokenSupply.div(weiPerEther).toString())).toFixed(1));
  return {
    cycleStartTimestampFinal,
    tentativeCycleEndTimeFinal,
    cycleStartPriceFinal,
    tentativeCycleEndPriceFinal,
    triggerPriceUp,
    triggerPriceDown,
    currentExpectedPremium,
    nextExpectedPremium,
    currentPercentageChart,
    nextPercentageChart,
    currentCyclelongTokenSupply,
    currentCycleshortTokenSupply,
    nextCyclelongTokenSupply,
    nextCycleshortTokenSupply,
    currentLeverage,
    nextLeverage
  };
}
function calculateEthDeviation(currentEthValue: number, thresholdPercent: number): { above: number; below: number } {
  if (currentEthValue < 0 || thresholdPercent < 0 || thresholdPercent >= 100) {
    throw new Error('Invalid input. ETH value and threshold percentage must be positive.');
  }

  const deviationAmount = (thresholdPercent / 100) * currentEthValue;
  const above = currentEthValue + deviationAmount;
  const below = currentEthValue - deviationAmount;

  return { above, below };
}
function formatTimestamp(timestamp: any) {
  const date = new Date(timestamp * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
}

function Removedecimal(numberValue2: number): number {
  const latestCycleStartPrice = numberValue2 / 100000000;
  return latestCycleStartPrice;
}

function calculatePercentageDifference(
  longTokenSupply: number,
  shortTokenSupply: number
): { longTokenPercentage: number; shortTokenPercentage: number } {
  const sum = longTokenSupply + shortTokenSupply;

  const longTokenPercentage = Number(((longTokenSupply / sum) * 100).toFixed());
  const shortTokenPercentage = Number(((shortTokenSupply / sum) * 100).toFixed());

  return { longTokenPercentage, shortTokenPercentage };
}

export async function vaultTokenValue(traderAddress: any, vaultTokenId: any, signer: any) {
  let vault = new ethers.Contract(Addresses.Vault, ABIs.Vault, signer);
  let traderBalance = await vault.balanceOf(traderAddress, vaultTokenId);
  // eslint-disable-next-line eqeqeq
  if (traderBalance == 0)
    return {
      canClaim: false,
      tokenAddress: ethers.constants.AddressZero,
      tokenAmount: 0,
    };
  let vaultTotalSupply = await vault.totalSupply(vaultTokenId);
  let depositTokenAddress = await vault.depositToken(vaultTokenId);
  let depositTokenAmount = await vault.totalDepositTokenAmount(vaultTokenId, depositTokenAddress);
  // eslint-disable-next-line eqeqeq
  if (depositTokenAmount != 0)
    return {
      canClaim: false,
      tokenAddress: depositTokenAddress,
      tokenAmount: depositTokenAmount.mul(traderBalance).div(vaultTotalSupply),
    };
  return {
    canClaim: true,
    tokenAddress: await vault.claimToken(vaultTokenId),
    tokenAmount: (await vault.totalClaimTokenAmount(vaultTokenId)).mul(traderBalance).div(vaultTotalSupply),
  };
}


export default function formatNumber(num:any, precision = 2) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}
