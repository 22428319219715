import React, { useEffect, useRef } from 'react';
import EthosX from '../assets/icons/EthosX.svg';
import sun from '../assets/icons/sun.svg';
import { useState } from 'react';
import Vectordown from '../assets/icons/Vectordown.svg';
import BSC_TESTNET from '../assets/icons/binance.svg';
import { Switch } from 'antd';
import menu from '../assets/icons/menu.svg';
import Mobilelogo from '../assets/icons/Mobilelogo.svg';
import Sidebar from './Sidebar';
import { useStore } from 'store';
import moon from '../assets/icons/moon.svg';
import menublack from '../assets/icons/menublack.svg';
import arrowLeft from '../assets/icons/arrow-left.svg';
import arrowLeftDark from '../assets/icons/arrow-left-dark.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const LearnHeader = () => {
    const { t } = useTranslation();
    const dropdownRef = useRef<HTMLElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const { isDarkMode, updateIsDarkMode, updateIsSelectedChain ,updateActiveTab} = useStore();
    const navigate = useNavigate();
    const toggleSelect = () => {
      setIsOpen(!isOpen);
    };
    const [sidebarOpen, setisSidebarOpen] = useState(false);
    const handleopen = () => {
      setisSidebarOpen(!sidebarOpen);
    };
    // Function to close the dropdown
    const closeDropdown = () => {
      setIsOpen(false);
    };
  
    // Listen for clicks outside the dropdown
    useEffect(() => {
      const handleClickOutside = (event: { target: any; }) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          closeDropdown();
        }
      };
      if (isOpen) {
        document.addEventListener('click', handleClickOutside);
      } else {
        document.removeEventListener('click', handleClickOutside);
      }
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [isOpen]);
    const handleTabClick = (tabName: any) => {
      updateActiveTab(tabName);
      switch (tabName) {
        case 'Learn':
          navigate("/learn");
          break;
        case 'switch':
          navigate("/");
          break;
        default:
          break;
      }
    };
  return (
    <div>
      <div className="flex justify-start font-inter !w-full ">
        <div
          className={`${isDarkMode ? 'bg-white' : 'bg-[#ffffff0f]'
            }  w-full h-[6rem] rounded-b-[1rem] 2xl:bg-[#17181a] xl:bg-[#17181a] lg:bg-[#17181a] md:bg-transparent sm:bg-transparent max-sm:bg-transparent  flex flex-row items-center  justify-between  px-3 xxl:mx-[1rem] xl:mx-[1rem] lg:mx-[1rem] md:mx-0 sm:mx-0 max-sm:mx-0 `}
        >
          <div className="2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline   cursor-pointer">
            <img src={Mobilelogo} alt="Mobilelogo" />
          </div>
          
           
          <div className="2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden  xxl:w-[30%] xl:w-[30%] lg:w-[40%] md:w-[90%] sm:w-[80%] max-sm:w-[80%]  ">
            <div
              className={`${isDarkMode
                  ? 'w-full h-[3.5rem] rounded-[2rem] bg-[#EFF7F8] ml-5 gap-[0.5rem] flex justify-start items-center p-[16px_32px] 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden  max-sm:hidden'
                  : 'w-full h-[3.5rem] rounded-[2rem] bg-[#ffffff0f] ml-5 gap-[0.5rem]  flex justify-start items-center p-[16px_32px] 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden  max-sm:hidden'
                } cursor-pointer`}
            >
              <div
                className={`${isDarkMode ? 'text-[1rem] text-[#107569] font-[400]' : 'text-[1rem] text-[#2ED3B7] font-[400]'
                  } flex flex-row justify-start items-center gap-[0.5rem]`}
              >
                <div>{isDarkMode ?<img src={arrowLeftDark} alt='' />:<img src={arrowLeft} alt='' />}</div>
                <div onClick={() => handleTabClick('switch')}>Use Switch</div>
              </div>
               
            </div>
          </div>
          <div
            className="2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden  max-sm:hidden"
            onClick={() => updateIsDarkMode(!isDarkMode)}
          >
            <div
              className={`${isDarkMode ? 'bg-white !rounded-[50%] ' : 'bg-[#ffffff0f] !rounded-[50%] '
                } w-[2.5rem] h-[2.5rem] ml-5   flex justify-center items-center !rounded-[50%] cursor-pointer 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden max-sm:hidden`}
            >
              <img src={isDarkMode ? moon : sun} alt="sun" className='rounded-[50%]' />
            </div>
          </div>
          <div className=" 2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline   cursor-pointer">
            <img src={isDarkMode ? menublack : menu} alt="/menu" onClick={handleopen} />
            {sidebarOpen && (
              <div>
                <Sidebar />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearnHeader
