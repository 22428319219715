/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import instruction from '../assets/icons/instruction.svg';
import downleftarrow from '../assets/icons/downleftarrow.svg';
import arrow from '../assets/icons/arrow.svg';
import arrowup from '../assets/icons/arrowup.svg';
import blackarrow from '../assets/icons/blackarrow.svg';
import blackarrowup from '../assets/icons/blackarrowup.svg';
import Graphic from './Component/Graphic';
import { TECollapse, TERipple } from 'tw-elements-react';
import { useState } from 'react';
import { ChartApp } from './Component/chart';
import BulletChart from './Component/antChart';
import { useStore } from 'store';
import instructiondark from '../assets/icons/instructiondark.svg';
import Downleftarrowlight from '../assets/icons/Downleftarrowlight.svg';
import kanaloader from '../assets/Kana loader.json';
import Lottie from 'react-lottie-player';
import { Button, Dropdown, Tooltip, notification } from 'antd';
import { useAccount, useSigner } from 'wagmi';
import { useSmartWalletProvider } from 'contexts/SmartWalletContext';
import { useTranslation } from 'react-i18next';
import ConnectWallet from './Component/ConnectWallet';
import Calculate from './Component/CalculatePopup';
import Timer from './Component/timer';
import kanaloaderred from '../assets/Kana loader Red.json';
import refreshLoader from '../assets/refresh.json';
import refreshLoaderBlack from '../assets/refreshBlack.json';
import DarkLeftArrow from '../assets/icons/Down_Left_Arrow_dark.svg';
import {
  convertUTCToIST,
  fetchAddressAssets,
  fetchDisclaimer,
  getChart,
  getDayBefore,
  getFilteredNFTsHistory,
  getOrders,
  getProfit,
  getTiming,
  getUiAmount,
  preventPasteNegativeNumber,
} from 'utils/helper';
import {
  BINANCE_BSC_TESTNET_LONGTOKEN,
  BINANCE_BSC_TESTNET_PROXYUSDC,
  BINANCE_BSC_TESTNET_SHORTTOKEN,
} from 'utils/defaultTokens';
import { executeCancelOrder, executeClaimOrder, executeOpenOrder, submitFaucetPayload } from 'utils/signTx';
import { ethers } from 'ethers';
import { ABIs, Addresses } from 'utils/data';
import { tentativeStateForNextCycle } from 'utils/functions';
import ChevronRed from '../assets/icons/chevronRed.svg';
import ChevronGreen from '../assets/icons/chevronGreen.svg';
import ChevronGreenDark from '../assets/icons/chevronGreenDark.svg';
import LinkExternal from '../assets/icons/link-external.svg';
import { BSC_TOKEN_1, BSC_TOKEN_2 } from 'utils/Constants';
import { ExpectedReturType, Timing, initialExpectedReturnValue, initialTiming } from 'utils/type';
import Refresh from '../assets/icons/refresh.svg';
import Refresh_black from '../assets/icons/refresh_black.svg';
import { DateTime } from "luxon";
import RunningClock from './Component/RunningClock';
import DisclaimerPopup from './Component/DisclaimerPopup';
const Dashboard = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [api, contextHolder] = notification.useNotification();
  const { data: signer } = useSigner();
  const { address, isConnected: web3IsConnected } = useAccount();
  const { isSocialLogin } = useSmartWalletProvider();
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const [isAdavanceViewTrue, setIsAdavanceViewTrue] = useState(localStorage.getItem('isAdavanceViewTrue') === 'true');
  const [isOrderType, setOrderType] = useState('');
  const [isClaimTokenID, setClaimTokenID] = useState('');
  const [isCancelType, setCancelType] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderClaim, setIsLoaderClaim] = useState(false);
  const [isLoaderCancel, setIsLoaderCancel] = useState(false);
  const [isLoaderFaucet, setIsLoaderFaucet] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const {
    isDarkMode,
    updateIsWalletConnectVisible,
    isWalletConnectVisible,
    updateIsCalculateVisible,
    isCalculateVisible,
    updateSwitchSettlementList,
    dashboardInputValue,
    updateDashboardInputValue,
    updateChartValue,
    updateETHPrice,
    ETHPrice,
    updateTiming,
    updateExpectedReturn,
    isDisclaimerVisible,
    updateIsDisclaimerVisible
  } = useStore();
  const savedTiming = localStorage.getItem('switchTiming');
  const timing: Timing = savedTiming ? JSON.parse(savedTiming) : initialTiming;
  const ExpectedReturnValue = localStorage.getItem('ExpectedReturn');
  const ExpectedReturnValuelocal: ExpectedReturType = ExpectedReturnValue ? JSON.parse(ExpectedReturnValue) : initialExpectedReturnValue;
  const switchSettlementList = JSON.parse(localStorage.getItem('switchSettlementList') || '[]');
  const [orderList, setOrdersList] = useState<any>(null);
  const [tokenList, setTokenList] = useState<any>(null);
  const [longToken, setLongToken] = useState<any>(null);
  const [shortToken, setShortToken] = useState<any>(null);
  const [pUSDCToken, setpUSDCToken] = useState<any>(null);
  const [userNft, setUserNft] = useState<any>(null);
  const [profitData, setProfitData] = useState<any>(null);
  const [tokenHistory, setTokenHistory] = useState<any>(null);
  const handleAdvancedView = (value: any) => {
    setIsAdavanceViewTrue(value);
    localStorage.setItem('isAdavanceViewTrue', value);
  };
  const BSCTestnetRPC = `https://bsc-testnet.nodereal.io/v1/${process.env.REACT_APP_NODEREAL_KEY}`; //BSC Testnet
  const provider = new ethers.providers.JsonRpcProvider(BSCTestnetRPC);
  const colors = {
    range: [isDarkMode ? '#ffffff' : '#191919', isDarkMode ? '#ffffff' : '#191919', isDarkMode ? '#ffffff' : '#191919'],
    measure: ['#00422d', '#217a7a'],
    target: '#39a3f4',
  };

  const onTokenAmountChange = (e: any) => {
    let value: any = e.target.value;
    // only accept digit . and numbers
    if (
      // eslint-disable-next-line no-useless-escape
      /^[0-9\.]*$/gm.test(value) &&
      // eslint-disable-next-line no-useless-escape
      ((value.match(/[\.,]/gm) && value.match(/^[0-9]{0,9}(\.|,)?[0-9]{0,8}$/gm)) ||
        // eslint-disable-next-line no-useless-escape
        (!value.match(/[\.,]/gm) &&
          value.match(/^[0-9]{0,9}$/gm) &&
          (!value.match(/\./gm) || value.match(/\./gm)?.length <= 1) &&
          (!value.match(/,/gm) || value.match(/,/gm)?.length <= 1)))
    ) {
      // replace duplication if needed
      const amount = value
        .replace(/\.+/gm, '.')
        .replace(/,+/gm, ',')
        .replace(/^0+/gm, '0')
        // if first character is . then replace them with 0.
        .replace(/^\./, '0.');
      updateDashboardInputValue(amount);
    }
  };

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
        if (response.status === 200) {
          const data = await response.json();
          const ethPriceUSD = data.ethereum.usd;
          updateETHPrice(ethPriceUSD);
          // Store the value in local storage
          localStorage.setItem('ethPrice', ethPriceUSD.toString());
        } else {
          // If API request fails, retrieve value from local storage
          const storedEthPrice = localStorage.getItem('ethPrice');
          if (storedEthPrice !== null) {
            updateETHPrice(parseFloat(storedEthPrice));
          }
        }
      } catch (error) {
        console.error('Error fetching ETH price:', error);
        // If API request fails, retrieve value from local storage
        const storedEthPrice = localStorage.getItem('ethPrice');
        if (storedEthPrice !== null) {
          updateETHPrice(parseFloat(storedEthPrice));
        }
      }
    };
    // Fetch ETH price initially
    fetchEthPrice();
    // Set up interval to fetch ETH price every 1 minute
    const intervalId = setInterval(fetchEthPrice, 60000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchSettlement = async () => {
    updateChartValue(await getChart());
    updateExpectedReturn(await getDayBefore());
    try {
      const contract = new ethers.Contract(Addresses.SettlementManager, ABIs.SettlementManager, provider!);
      const lastSettlementPrice = await contract.lastSettlementPrice();
      const lastSettlementTime = await contract.lastSettlementTime();
      const final = await tentativeStateForNextCycle(lastSettlementTime, lastSettlementPrice, provider);
      updateSwitchSettlementList([final]);
      const [Timing] = await Promise.all([getTiming(provider)]);
      updateTiming(Timing);
    } catch (error) {
      console.error('Error fetching ETH price:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSettlement();
      } catch (error) {
        console.error('Error in the useEffect fetchSettlement:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchData = async (address: any) => {
    try {
      if (address) {
        const data = await updatedBalance(address);
        const [historyData, orders] = await Promise.all([
          getFilteredNFTsHistory(address, provider),
          getOrders(address, provider)
        ]);
        const profit = await getProfit(address, data, orders)
        setOrdersList(orders.filter((x: any) => x.isAvailable === true));
        setTokenHistory(historyData);
        setUserNft(orders.filter((x: any) => x.isAvailable === false));
        setProfitData(profit)
      } else {
        setOrdersList(null);
        setTokenHistory(null);
        setUserNft(null);
        setProfitData(null)
        setTokenList(null);
        setLongToken(null);
        setShortToken(null);
        setpUSDCToken(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchDisclaimerData = async (address: any) => {
    try {
      if (address) {
        const disclaimer = await fetchDisclaimer(address);
        if(disclaimer.data.length >0){
        if(disclaimer.data[0].user_result){
          updateIsDisclaimerVisible(false)
        }else if(disclaimer.user_result){
          updateIsDisclaimerVisible(true)
        }
        }else{
          updateIsDisclaimerVisible(true)
        }
      }
    } catch (error) {
      console.error('Error fetchDisclaimerData:', error);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        await fetchDisclaimerData(address)
        await fetchData(address);
      } catch (error) {
        console.error('Error in the useEffect fetchData:', error);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  useEffect(() => {
    const eventLisiner = async () => {
      try {
        const BSCTestnetRPC = `https://bsc-testnet.nodereal.io/v1/${process.env.REACT_APP_NODEREAL_KEY}`; //BSC Testnet
        const provider = new ethers.providers.JsonRpcProvider(BSCTestnetRPC);
        const contract = new ethers.Contract(Addresses.Controller, ABIs.Controller, provider!);
        // Listen for the "SettlementDone" event
        contract.on(
          'SettlementDone',
          async (
            long_token_balance_before,
            short_token_balance_before,
            long_token_balance_after,
            short_token_balance_after,
            event
          ) => {
            console.log('long_token_balance_before:', long_token_balance_before.toString());
            console.log('short_token_balance_before:', short_token_balance_before.toString());
            console.log('long_token_balance_after:', long_token_balance_after.toString());
            console.log('short_token_balance_after:', short_token_balance_after.toString());
            console.log('Block Number:', event.blockNumber);
            await fetchSettlement();
            if (address) {
              await fetchData(address);
            }
          }
        );
      } catch (error) {
        console.error('Error eventLisiner data:', error);
      }
    };
    eventLisiner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  const setMax = (percentage: string, type: string) => {
    if (tokenList) {
      if (percentage === 'MAX' && type === 'LS') {
        let shortToken = tokenList.find(
          (x: any) => x.token_address.toLocaleLowerCase() === BINANCE_BSC_TESTNET_LONGTOKEN.address.toLocaleLowerCase()
        );
        if (shortToken) {
          let value = getUiAmount(shortToken.balance, shortToken.decimals).toString();
          updateDashboardInputValue(value);
        } else {
          updateDashboardInputValue('');
        }
      }

      if (percentage === 'MAX' && type === 'SS') {
        let shortToken = tokenList.find(
          (x: any) => x.token_address.toLocaleLowerCase() === BINANCE_BSC_TESTNET_SHORTTOKEN.address.toLocaleLowerCase()
        );
        if (shortToken && shortToken !== null && shortToken !== undefined) {
          let value = getUiAmount(shortToken.balance, shortToken.decimals).toString();
          updateDashboardInputValue(value);
        } else {
          updateDashboardInputValue('');
        }
      }
      if (percentage === 'MAX' && type === 'USDC') {
        let shortToken = tokenList.find(
          (x: any) => x.token_address.toLocaleLowerCase() === BINANCE_BSC_TESTNET_PROXYUSDC.address.toLocaleLowerCase()
        );
        if (shortToken && shortToken !== null && shortToken !== undefined) {
          let value = getUiAmount(shortToken.balance, shortToken.decimals).toString();
          updateDashboardInputValue(value);
        } else {
          updateDashboardInputValue('');
        }
      }
    }
  };
  const setSellAndWithdraw = (percentage: string) => {
    if (percentage === 'Sell') {
      openTab(0);
      if (longToken && longToken !== null && longToken !== undefined) {
        let value = getUiAmount(longToken.balance, longToken.decimals).toFixed(2);
        updateDashboardInputValue(value);
      } else {
        updateDashboardInputValue('');
      }
    } else if (percentage === 'Withdraw') {
      openTab(1);
      if (shortToken && shortToken !== null && shortToken !== undefined) {
        let value = getUiAmount(shortToken.balance, shortToken.decimals).toFixed(2);
        updateDashboardInputValue(value);
      } else {
        updateDashboardInputValue('');
      }
    }
  };
  const handelOnClickCalculate = () => {
    if (switchSettlementList.length !== 0) {
      updateIsCalculateVisible(true);
    }
  };
  const checkSufficientBalance = async (token: any, amount: any) => {
    let tokenBalance: any = null;
    let tokenName = '';
    switch (token) {
      case 'buyLong':
        if (pUSDCToken !== undefined && pUSDCToken !== null) {
          tokenBalance = getUiAmount(pUSDCToken.balance, pUSDCToken.decimals).toFixed(2);
        } else {
          tokenBalance = 0;
        }
        tokenName = 'pUSDCToken';
        break;
      case 'buyShort':
        if (pUSDCToken !== undefined && pUSDCToken !== null) {
          tokenBalance = getUiAmount(pUSDCToken.balance, pUSDCToken.decimals).toFixed(2);
        } else {
          tokenBalance = 0;
        }
        tokenName = 'pUSDCToken';
        break;
      case 'sellLong':
        if (longToken !== undefined && longToken !== null) {
          tokenBalance = getUiAmount(longToken.balance, longToken.decimals).toFixed(2);
        } else {
          tokenBalance = 0;
        }
        tokenName = 'longToken';
        break;
      case 'sellShort':
        if (shortToken !== undefined && shortToken !== null) {
          tokenBalance = getUiAmount(shortToken.balance, shortToken.decimals).toFixed(2);
        } else {
          tokenBalance = 0;
        }
        tokenName = 'shortToken';
        break;
      default:
        break;
    }
    if (tokenBalance === undefined || tokenBalance === null || tokenBalance === 0 || tokenBalance < Number(amount)) {
      notification.error({
        message: 'Balance Error',
        description: `Insufficient ${tokenName} balance. Please try again later.`,
      });
      return { error: true, data: null };
    }
    return { error: false, data: null };
  };
  const fetchGetprofile = async (address: any, balanceData: any, orders: any) => {
    try {
      const profit = await getProfit(address, balanceData, orders)
      setProfitData(profit)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const executeOrder = async (type: string, index?: number) => {
    try {
      if (Number(dashboardInputValue) <= 0) return;
      const isSwap = await checkSufficientBalance(type, dashboardInputValue);
      if (isSwap.error) {
        console.error('isSwapable returned an error:', isSwap);
        return;
      }
      setOrderType(type);
      setIsLoader(true);
      const orderTypeMap = {
        buyLong: 'Buy Long switch',
        sellLong: 'Sell Long switch',
        buyShort: 'Buy Short switch',
        sellShort: 'Sell Short switch',
      };
      const orderType = orderTypeMap[type as keyof typeof orderTypeMap];
      if (!orderType) return;
      const data = await executeOpenOrder(type, Number(dashboardInputValue), signer);

      let list = await getOrders(address!, provider);
      setOrdersList(list.filter((x: any) => x.isAvailable === true));

      if (data?.status === 1) {
        let balanceData = await updatedBalance(address);
        await fetchGetprofile(address!, balanceData, list)
        notification.success({
          message: `${orderType} order placed successfully.`,
          description: (
            <div>
              <p>
                <a
                  className="text-purple-700 underline hover:underline hover:text-purple-500"
                  href={`https://testnet.bscscan.com/tx/${data?.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View transaction
                </a>
              </p>
            </div>
          ),
        });
      }
    } catch (error: any) {
      console.log('Error executing order:', error);
      if (error === 'ACTION_REJECTED') {
        notification.error({
          message: 'Wallet Error',
          description: 'User Rejected the request.',
        });
      } else {
        notification.error({
          message: 'Error executing order',
          description: 'An error occurred while executing the order. Please try again later.',
        });
      }
    } finally {
      setIsLoader(false);
      updateDashboardInputValue('');
    }
  };

  const executeCancel = async (type: any) => {
    try {
      if (type) {
        setIsLoaderCancel(true);
        setCancelType(type);
        const data = await executeCancelOrder(type, signer);
        let list = await getOrders(address!, provider);
        setOrdersList(list.filter((x: any) => x.isAvailable === true));
        if (data?.status === 1) {
          let balanceData = await updatedBalance(address);
          await fetchGetprofile(address!, balanceData, list)
          notification.success({
            message: `Order Cancelled Successfully.`,
            description: (
              <div>
                <p>
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://testnet.bscscan.com/tx/${data?.transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                </p>
              </div>
            ),
          });
        }
      }
    } catch (error: any) {
      console.log('Error executing order:', error);
      if (error === 'ACTION_REJECTED') {
        notification.error({
          message: 'Wallet Error',
          description: 'User Rejected the request.',
        });
      } else {
        notification.error({
          message: 'Error executing order',
          description: 'An error occurred while executing the order. Please try again later.',
        });
      }
    } finally {
      setIsLoaderCancel(false);
    }
  };
  const executeClaim = async (token_id: any, amount: any) => {
    try {
      if (token_id) {
        setIsLoaderClaim(true);
        setClaimTokenID(token_id);
        const data = await executeClaimOrder(token_id, signer);
        let list = await getOrders(address!, provider);
        setUserNft(list.filter((x: any) => x.isAvailable === false));
        if (data?.status === 1) {
          let balanceData = await updatedBalance(address);
          await fetchGetprofile(address!, balanceData, list)
          notification.success({
            message: `Calimed Successfully.`,
            description: (
              <div>
                <p>
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://testnet.bscscan.com/tx/${data?.transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                </p>
              </div>
            ),
          });
        }
      }
    } catch (error: any) {
      console.log('Error executing order:', error);
      if (error === 'ACTION_REJECTED') {
        notification.error({
          message: 'Wallet Error',
          description: 'User Rejected the request.',
        });
      } else {
        notification.error({
          message: 'Error executing order',
          description: 'An error occurred while executing the order.',
        });
      }
    } finally {
      setIsLoaderClaim(false);
    }
  };
  const updatedBalance = async (address: any) => {
    try {
      const [assetsData] = await Promise.all([fetchAddressAssets(address)]);
      const longToken = assetsData.find(
        (x: any) => x.token_address.toLowerCase() === BINANCE_BSC_TESTNET_LONGTOKEN.address.toLowerCase()
      );
      const shortToken = assetsData.find(
        (x: any) => x.token_address.toLowerCase() === BINANCE_BSC_TESTNET_SHORTTOKEN.address.toLowerCase()
      );
      const pUSDCToken = assetsData.find(
        (x: any) => x.token_address.toLowerCase() === BINANCE_BSC_TESTNET_PROXYUSDC.address.toLowerCase()
      );
      setTokenList(assetsData);
      setLongToken(longToken);
      setShortToken(shortToken);
      setpUSDCToken(pUSDCToken);
      return {
        longToken,
        shortToken
      }
    } catch (error: any) {
      console.log('Error executing order:', error);
    }
  };
  const [isFaucet, setIsFaucet] = useState(false);
  const handleFaucet = () => {
    setIsFaucet(!isFaucet);
  };
  const items = [
    { key: '1', label: 'tBSC', logo: BSC_TOKEN_1.logoURI },
    { key: '2', label: 'pUSDC', logo: BSC_TOKEN_2.logoURI },
  ];
  const handleClick = async (token: any) => {
    if (web3IsConnected) {
      try {
        if (token === 'tBSC') {
          window.open('https://testnet.bnbchain.org/faucet-smart');
        } else {
          setIsLoaderFaucet(true);
          const data = await submitFaucetPayload(token, signer);
          notification.success({
            message: `Token minted successfully.`,
            description: (
              <div>
                <p>
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://testnet.bscscan.com/tx/${data?.transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                </p>
              </div>
            ),
          });
        }
      } catch (error: any) {
        console.log('Error executing order:', error);
        if (error === 'ACTION_REJECTED') {
          notification.error({
            message: 'Wallet Error',
            description: 'User Rejected the request.',
          });
        } else {
          notification.error({
            message: 'Error executing order',
            description: 'An error occurred while executing the order. Please try again later.',
          });
        }
      } finally {
        setIsLoaderFaucet(false);
      }
    }
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Initialize with the desired tab index

  // Function to programmatically open a specific tab
  const openTab = (index: any) => {
    setSelectedTabIndex(index);
  };
  const refreshRoutes = async (address:any) => {
    try {
      setIsRefresh(true)
      await fetchData(address);
    } catch (err: any) {
        console.error(err);
    }finally{
      setIsRefresh(false)
    }
};
  return (
    <div className={`${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#ffffff0f]'} h-screen font-inter flex w-full`}>
      {contextHolder}
      <div className=" 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
        <Sidebar />
      </div>
      <div className="flex flex-col overflow-auto 2xl:!ml-[19rem] xl:!ml-[19rem] lg:!ml-[19rem] md:ml-0   sm:ml-0 max-sm:ml-0 w-full ">
        <div className=" 2xl:mx-[32px] xl:mx-[32px] lg:mx-[32px] md:mx-[32px] sm:mx-0 max-sm:mx-0  xxl:!w-[1060px] xl:!w-auto lg:!w-auto md:!w-auto sm:!w-auto">
          <Navbar handleAdvancedView={handleAdvancedView} />
        </div>
        <div className="my-[16px] gap-[16px] flex 2xl:mx-[32px] xl:mx-[32px] lg:mx-[32px] md:mx-[32px] sm:mx-0 max-sm:mx-0  2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-sm:flex-col !justify-start !items-start ">
          <div
            className={`${isAdavanceViewTrue
              ? ' flex xxl:w-auto xl:w-auto lg:w-auto md:w-full sm:w-full max-sm:w-full  flex-col justify-start items-start gap-[16px]'
              : 'flex  xxl:w-auto xl:w-auto lg:w-auto md:w-full sm:w-full max-sm:w-full gap-[16px] xxl:flex-row xl:flex-row  lg:flex-row md:flex-col sm:flex-col max-sm:flex-col justify-start items-start'
              }  `}
          >
            <div
              className={`${isAdavanceViewTrue
                ? '  xxl:!w-[480px] xl:!w-[480px] lg:!w-[480px] md:!w-full sm:!w-full max-sm:!w-full   '
                : '  xxl:!w-[480px] xl:!w-[480px] lg:!w-[480px] md:!w-full sm:!w-full max-sm:!w-full '
                }  ,${isDarkMode
                  ? ' !w-full  h-auto bg-[#FFFFFF] border-[#DAECEF] border-2 rounded-[1rem] '
                  : '   !w-full    h-auto bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] border-2 rounded-[1rem]  bg_container'
                }`}
            >
              <Tabs
                selectedIndex={selectedTabIndex}
                onSelect={(index) => setSelectedTabIndex(index)}
                className="!w-full"
              >
                <TabList
                  className={`${isDarkMode
                    ? 'flex justify-around bg-[#eff7f8] rounded-t-[1rem] h-[3.5rem] border-b-2 border-[#eff7f8] cursor-pointer backdrop-blur-sm transition-all ease-in-out duration-500 w-full'
                    : 'flex justify-around bg-[#0f1012] rounded-t-[1rem] h-[3.5rem] border-b-2 border-[#292a2d] cursor-pointer backdrop-blur-sm transition-all ease-in-out duration-500 w-full'
                    }`}
                >
                  <Tab
                    className={`${isDarkMode
                      ? 'text-[#364152] items-center  w-full  hover:rounded-tl-[1rem] flex justify-center outline-none social_web_login_light'
                      : 'text-white items-center  w-full  hover:rounded-tl-[1rem] flex justify-center outline-none social_web_login'
                      }`}
                  >
                    {t('hedge')}
                  </Tab>
                  <Tab
                    className={`${isDarkMode
                      ? 'text-[#364152] items-center w-full  hover:rounded-tr-[1rem] flex justify-center outline-none social_web_login_1_light'
                      : 'text-white items-center w-full  hover:rounded-tr-[1rem] flex justify-center outline-none social_web_login_1'
                      }`}
                  >
                    {t('earn')}
                  </Tab>
                </TabList>
                <div className="flex justify-start items-start w-full px-5 pt-5 h-auto">
                  <TabPanel className="w-full">
                    <div
                      className={`${isDarkMode
                        ? 'w-full h-auto rounded-[1rem] border-none  '
                        : ' w-full h-auto rounded-[1rem] border-2 border-[#2c2d30]  '
                        }`}
                    >
                      <div
                        className={`${isDarkMode
                          ? 'flex justify-between text-[0.875rem] text-[#364152] leading-8 p-[3%_5%] font-[500]'
                          : 'flex justify-between text-[0.875rem] text-[#FFFFFFCC] leading-8 p-[3%_5%] font-[500]'
                          }`}
                      >
                        <ul>
                          <li>{t('1_long_switch')}</li>
                          <li>{t('1_eth')}</li>
                          <li className="flex">
                            {t('expected_return')}
                            <Tooltip placement="top" title={"Average return of the yesterday's cycles"} arrow={false}>
                              <img
                                src={isDarkMode ? instructiondark : instruction}
                                alt="instruction"
                                className="pl-2 cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                          <li className="flex">
                            {t('cycle')}
                            <Tooltip placement="top" title={"cycles"} arrow={false}>
                              <img
                                src={isDarkMode ? instructiondark : instruction}
                                alt="instruction"
                                className="pl-2 cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        </ul>
                        <ul>
                          <li className="text-right">{t('1_usdc')}</li>
                          <li className="text-right">{timing ? `$ ${Number(timing.cycleStartPriceFinal).toLocaleString('en-US')}` : '$ 0.00'}</li>
                          <li className="text-right">{ExpectedReturnValuelocal ? Number(ExpectedReturnValuelocal.Long) : 0.00} % / Cycle</li>
                          <li className="text-right">{t('1_cycle')}</li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={`${isDarkMode
                        ? 'w-full  h-auto rounded-[1rem] bg-[#F0FDF9] mt-3'
                        : ' w-full  h-auto  rounded-[1rem] bg-[#0000004D] mt-3'
                        }`}
                    >
                      <div
                        className={`${isDarkMode
                          ? 'text-[0.875rem] text-[#364152] font-[400]'
                          : 'text-[0.875rem] text-[#ffffffcc] font-[400]'
                          }`}
                      >
                        <div className=" flex justify-between p-[4%_5%]">
                          <div
                            className={`${isDarkMode
                              ? 'text-[#107569] flex font-[500] font-inter'
                              : 'text-[#67FFD1] flex font-[500] font-inter'
                              } ${switchSettlementList.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            onClick={() => {
                              handelOnClickCalculate();
                            }}
                          >
                            {t('calculate_hedge')}
                            <img src={isDarkMode ? DarkLeftArrow : downleftarrow} alt="/" className=" pl-2" />
                          </div>
                          <div className="text-[0.875rem] 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2 font-[400]">
                            {t('long_switch_token')}
                          </div>


                        </div>
                        <div className=" flex justify-center ">
                          <div
                            className={`${isDarkMode
                              ? 'flex justify-between border-b-2 w-[90%] border-[#e3e8ef] h-[2rem]'
                              : 'flex justify-between border-b-2  w-[90%] border-[#1e1f22] h-[2rem]'
                              }`}
                          >
                            <div className="flex font-[400]">
                              {t('quantity_sell')}
                            </div>
                            <input
                              id="long-switch-token-amount"
                              className={`${isDarkMode
                                ? 'text-[#364152] bg-transparent outline-none 1.25rem w-[13.625rem] text-[1.2rem] text-right'
                                : 'bg-transparent outline-none 1.25rem w-[13.625rem] text-[1.2rem] text-right'
                                }`}
                              type="text"
                              placeholder="0"
                              min="0"
                              autoFocus={true}
                              autoComplete="off"
                              onWheel={(event) => event.currentTarget.blur()}
                              onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                              onPaste={preventPasteNegativeNumber}
                              value={dashboardInputValue}
                              onChange={onTokenAmountChange}
                            />

                          </div>
                        </div>
                        <div className="flex justify-between 2xl:p-[2%_4%] xl:p-[2%_4%] lg:p-[2%_4%] md:p-[2%_4%] sm:p-[5%_4%] max-sm:p-[5%_4%]">
                          <div className=" text-[#707071]">
                            {t('holding')}
                            <span className={`${isDarkMode ? 'pl-2 text-[#364152]' : 'pl-2 text-[#FFFFFFCC]'}`}>
                              {longToken === null || longToken === undefined
                                ? 0
                                : Number(getUiAmount(longToken.balance, longToken.decimals).toFixed(2)).toLocaleString('en-US')}
                            </span>
                          </div>
                          <div className="flex">
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem]  border-2 border-[#5FE9D0] text-[#107569] font-[500] '
                                : 'bg-[#252527] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem]  font-[500] '
                                }${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => setMax('MAX', 'LS') : () => false}
                            >
                              {t('max')}
                            </button>
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2  border-2 border-[#F97066] font-[500] text-[#B42318] '
                                : 'bg-[#3c191c] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2  font-[500]'
                                } ${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => updateDashboardInputValue('') : () => false}
                            >
                              {t('clear')}
                            </button>
                          </div>
                        </div>
                        <div className="flex justify-between 2xl:p-[2%_4%] xl:p-[2%_4%] lg:p-[2%_4%] md:p-[2%_4%] sm:p-[5%_4%] max-sm:p-[5%_4%]">
                          <div className=" text-[#707071]">
                            {t('holding_p')}
                            <span className={`${isDarkMode ? 'pl-2 text-[#364152]' : 'pl-2 text-[#FFFFFFCC]'}`}>
                              {pUSDCToken === null || pUSDCToken === undefined
                                ? 0
                                : Number(getUiAmount(pUSDCToken.balance, pUSDCToken.decimals).toFixed(2)).toLocaleString('en-US')}
                            </span>
                          </div>
                          <div className="flex">
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] border-2 border-[#5FE9D0] text-[#107569] font-[500] '
                                : 'bg-[#252527] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] font-[500] '
                                }${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => setMax('MAX', 'USDC') : () => false}
                            >
                              {t('max')}
                            </button>
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2 border-2 border-[#F97066] font-[500] text-[#B42318] '
                                : 'bg-[#3c191c] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2 font-[500]'
                                }${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => updateDashboardInputValue('') : () => false}
                            >
                              {t('clear')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between mt-5 w-full">
                      {web3IsConnected || isSocialLogin ? (
                        <div className="flex justify-between w-full">
                          <button
                            className={`${isDarkMode ? 'buy-button-light w-1/2  ' : 'buy-button w-1/2'} ${isLoader ? 'cursor-not-allowed' : ''}`}
                            onClick={() => executeOrder('buyLong')}
                            disabled={isLoader}
                          >
                            <div className={`${isDarkMode ? 'buy-button-inner-light' : 'buy-button-inner'} ${isLoader ? 'cursor-not-allowed' : ''}`}>
                              {isLoader && isOrderType === 'buyLong' ? (
                                <div className={`flex justify-center items-center ${isLoader ? 'cursor-not-allowed' : ''}`}>
                                  <Lottie
                                    loop
                                    animationData={kanaloader}
                                    play
                                    className="w-[2.5rem] h-[1.5rem] flex justify-center items-center"
                                  />
                                </div>
                              ) : (
                                <span
                                  className={`${isDarkMode ? 'button-text-light font-bold' : 'button-text font-bold'}`}
                                >
                                  {t('buy')}
                                </span>
                              )}
                            </div>
                          </button>
                          <div className="flex justify-between mr-2"></div>
                          <button
                            className={`${isDarkMode ? 'buy-button-light   w-1/2' : 'buy-button  w-1/2'} ${isLoader ? 'cursor-not-allowed' : ''}`}
                            onClick={() => executeOrder('sellLong')}
                            disabled={isLoader}
                          >
                            <div className={`${isDarkMode ? 'buy-button-inner-light' : 'buy-button-inner'} ${isLoader ? 'cursor-not-allowed' : ''}`}>
                              {isLoader && isOrderType === 'sellLong' ? (
                                <div className={`flex justify-center items-center ${isLoader ? 'cursor-not-allowed' : ''}`}>
                                  <Lottie
                                    loop
                                    animationData={kanaloader}
                                    play
                                    className="w-[2.5rem] h-[1.5rem] flex justify-center items-center"
                                  />
                                </div>
                              ) : (
                                <span
                                  className={`${isDarkMode ? 'button-text-light font-bold' : 'button-text font-bold'}`}
                                >
                                  {t('sell')}
                                </span>
                              )}
                            </div>
                          </button>
                        </div>
                      ) : (
                        <div className="w-full">
                          <button
                            className={`${isDarkMode ? 'buy-button-light w-full  ' : 'buy-button w-full'}`}
                            onClick={() => {
                              updateIsWalletConnectVisible(true);
                            }}
                          >
                            <div className={`${isDarkMode ? 'buy-button-inner-light' : 'buy-button-inner'}`}>
                              <span
                                className={`${isDarkMode ? 'button-text-light font-bold' : 'button-text font-bold'}`}
                              >
                                {t('connect_wallet')}
                              </span>
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                </div>
                <div className="flex justify-start items-start w-full px-5 pb-5 h-auto">
                  <TabPanel className="w-full ">
                    <div
                      className={`${isDarkMode
                        ? ' w-full h-auto rounded-[1rem] border-none '
                        : 'w-full  h-auto  rounded-[1rem] border-2 border-[#2c2d30]  '
                        }`}
                    >
                      <div
                        className={`${isDarkMode
                          ? 'flex justify-between text-[0.875rem] text-[#364152] leading-8 p-[3%_5%] font-[500]'
                          : 'flex justify-between text-[0.875rem] text-[#FFFFFFCC] leading-8 p-[3%_5%] font-[500]'
                          }`}
                      >
                        <ul>
                          <li>{t('1_short_switch')}</li>
                          <li>{t('1_eth')}</li>
                          <li className="flex">
                            {t('expected_return')}
                            <Tooltip placement="top" title={"Average return of the yesterday's cycles"} arrow={false}>
                              <img
                                src={isDarkMode ? instructiondark : instruction}
                                alt="instruction"
                                className="pl-2 cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                          <li className="flex">
                            {t('cycle')}
                            <Tooltip placement="top" title={"cycles"} arrow={false}>
                              <img
                                src={isDarkMode ? instructiondark : instruction}
                                alt="instruction"
                                className="pl-2 cursor-pointer"
                              />
                            </Tooltip>
                          </li>
                        </ul>
                        <ul>
                          <li className="text-right">{t('1_usdc')}</li>
                          <li className="text-right">{timing ? `$ ${Number(timing.cycleStartPriceFinal).toLocaleString('en-US')}` : '$ 0.00'}</li>
                          <li className="text-right">{ExpectedReturnValuelocal ? Number(ExpectedReturnValuelocal.Short) : 0.00} % / Cycle</li>
                          <li className="text-right">{t('1_cycle')}</li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={`${isDarkMode
                        ? ' w-full h-auto rounded-[1rem] bg-[#F0FDF9] mt-3'
                        : ' w-full h-auto rounded-[1rem] bg-[#0000004D] mt-3'
                        }`}
                    >
                      <div
                        className={`${isDarkMode
                          ? 'text-[0.875rem] text-[#364152] font-[400]'
                          : 'text-[0.875rem] text-[#ffffffcc] font-[400]'
                          }`}
                      >
                        <div className=" flex justify-between p-[4%_5%]">
                          <div></div>
                          <div className="text-[0.875rem] 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-2 max-sm:pt-2 font-[400]">
                            {t('short_switch_token')}
                          </div>
                        </div>
                        <div className=" flex justify-center ">
                          <div
                            className={`${isDarkMode
                              ? 'flex justify-between border-b-2 w-[90%] border-[#e3e8ef] h-[2rem]'
                              : 'flex justify-between border-b-2  w-[90%] border-[#1e1f22] h-[2rem]'
                              }`}
                          >

                            <div className="flex font-[400]">
                              {t('quantity_sell')}
                            </div>
                            <input
                              id="short-switch-token-amount"
                              className={`${isDarkMode
                                ? 'text-[#364152] text-right bg-transparent outline-none 1.25rem w-[13.625rem] text-[1.2rem]'
                                : 'bg-transparent text-right outline-none 1.25rem w-[13.625rem] text-[1.2rem]'
                                }`}
                              type="text"
                              placeholder="0"
                              min="0"
                              autoFocus={true}
                              autoComplete="off"
                              onWheel={(event) => event.currentTarget.blur()}
                              onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                              onPaste={preventPasteNegativeNumber}
                              value={dashboardInputValue}
                              onChange={onTokenAmountChange}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between 2xl:p-[2%_4%] xl:p-[2%_4%] lg:p-[2%_4%] md:p-[2%_4%] sm:p-[5%_4%] max-sm:p-[5%_4%]">
                          <div className=" text-[#707071]">
                            {t('holdings_ss')}
                            <span className={`${isDarkMode ? 'pl-2 text-[#364152]' : 'pl-2 text-[#FFFFFFCC]'}`}>
                              {shortToken === null || shortToken === undefined
                                ? 0
                                : Number(getUiAmount(shortToken.balance, shortToken.decimals).toFixed(2)).toLocaleString('en-US')}
                            </span>
                          </div>
                          <div className="flex">
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem]  border-2 border-[#5FE9D0] text-[#107569] font-[500]'
                                : 'bg-[#252527] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem]  font-[500] 	'
                                } ${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => setMax('MAX', 'SS') : () => false}
                            >
                              {t('max')}
                            </button>
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2  border-2 border-[#F97066] font-[500] text-[#B42318] '
                                : 'bg-[#3c191c] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2  font-[500]'
                                } ${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => updateDashboardInputValue('') : () => false}
                            >
                              {t('clear')}
                            </button>
                          </div>
                        </div>
                        <div className="flex justify-between 2xl:p-[2%_4%] xl:p-[2%_4%] lg:p-[2%_4%] md:p-[2%_4%] sm:p-[5%_4%] max-sm:p-[5%_4%]">
                          <div className=" text-[#707071]">
                            {t('holding_p')}
                            <span className={`${isDarkMode ? 'pl-2 text-[#364152]' : 'pl-2 text-[#FFFFFFCC]'}`}>
                              {pUSDCToken === null || pUSDCToken === undefined
                                ? 0
                                : Number(getUiAmount(pUSDCToken.balance, pUSDCToken.decimals).toFixed(2)).toLocaleString('en-US')}
                            </span>
                          </div>
                          <div className="flex">
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] border-2 border-[#5FE9D0] text-[#107569] font-[500] '
                                : 'bg-[#252527] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] font-[500] '
                                }  ${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => setMax('MAX', 'USDC') : () => false}
                            >
                              {t('max')}
                            </button>
                            <button
                              className={`${isDarkMode
                                ? 'bg-[#FFFFFF] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2  border-2 border-[#F97066] font-[500] text-[#B42318] '
                                : 'bg-[#3c191c] rounded-[0.5rem] w-[3.25rem] h-[1.938rem] flex justify-center items-center text-[0.75rem] ml-2  font-[500]'
                                } ${isLoader ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                              onClick={!isLoader ? () => updateDashboardInputValue('') : () => false}
                            >
                              {t('clear')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between mt-5 w-full">
                      {!web3IsConnected || !address ? (
                        <div className="w-full">
                          <button
                            className={`${isDarkMode ? 'buy-button-light  w-full' : 'buy-button w-full'}`}
                            onClick={() => {
                              updateIsWalletConnectVisible(true);
                            }}
                          >
                            <div className={`${isDarkMode ? 'buy-button-inner-light' : 'buy-button-inner'}`}>
                              <span
                                className={`${isDarkMode ? 'button-text-light font-bold' : 'button-text font-bold'}`}
                              >
                                {t('connect_wallet')}
                              </span>
                            </div>
                          </button>
                        </div>
                      ) : (
                        <div className="flex justify-between w-full">
                          <button
                            className={`${isDarkMode ? 'buy-button-light w-full' : 'buy-button w-full'}  ${isLoader ? 'cursor-not-allowed' : ''}`}
                            disabled={isLoader}
                            onClick={() => executeOrder('buyShort')}
                          >
                            <div className={`${isDarkMode ? 'buy-button-inner-light' : 'buy-button-inner'}  ${isLoader ? 'cursor-not-allowed' : ''}`}>
                              {isLoader && isOrderType === 'buyShort' ? (
                                <div className={`flex justify-center items-center ${isLoader ? 'cursor-not-allowed' : ''}`}>
                                  <Lottie
                                    loop
                                    animationData={kanaloader}
                                    play
                                    className="w-[2.5rem] h-[1.5rem] flex justify-center items-center"
                                  />
                                </div>
                              ) : (
                                <span
                                  className={`${isDarkMode ? 'button-text-light font-bold' : 'button-text font-bold'}`}
                                >
                                  {t('deposit')}
                                </span>
                              )}
                            </div>
                          </button>
                          <div className="flex justify-between mr-2"></div>
                          <button
                            className={`${isDarkMode ? 'buy-button-light' : 'buy-button '} w-full ${isLoader ? 'cursor-not-allowed' : ''}`}
                            disabled={isLoader}
                            onClick={() => executeOrder('sellShort')}
                          >
                            <div className={`${isDarkMode ? 'buy-button-inner-light' : 'buy-button-inner'} ${isLoader ? 'cursor-not-allowed' : ''}`}>
                              {isLoader && isOrderType === 'sellShort' ? (
                                <div className={`flex justify-center items-center ${isLoader ? 'cursor-not-allowed' : ''}`}>
                                  <Lottie
                                    loop
                                    animationData={kanaloader}
                                    play
                                    className="w-[2.5rem] h-[1.5rem] flex justify-center items-center"
                                  />
                                </div>
                              ) : (
                                <span
                                  className={`${isDarkMode ? 'button-text-light font-bold' : 'button-text font-bold'}`}
                                >
                                  {t('withdraw')}
                                </span>
                              )}
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>


            <div
              className={`${isAdavanceViewTrue
                ? '  xxl:!w-[480px] xl:!w-[480px] lg:!w-[480px] md:1w-full sm:!w-full max-sm:!w-full   '
                : ' xxl:!w-[560px] xl:!w-[560px] lg:!w-[560px] md:!w-full sm:!w-full max-sm:!w-full '
                } `}
            >


              <div
                className={`${isAdavanceViewTrue ? ' !z-[0] ' : ' !z-[0]   '},${isDarkMode
                  ? ' !z-[0] w-full  rounded-[1rem] border-none    overflow-scroll'
                  : ' !z-[0] w-full   rounded-[1rem]   overflow-scroll'
                  }`}
              >
                {isAdavanceViewTrue ? <></> : <div
                  className={` ${isDarkMode
                    ? 'border-[#292a2d] w-full text-[black] bg-white font-[500] border-2 shadow-switch_shadow backdrop-blur-[54px] rounded-[16px] border-[rgba(245,247,250,0.06)]   flex flex-row justify-between items-center w-full p-[16px_32px]'
                    : 'border-[#292a2d] w-full text-[rgba(255,255,255,0.40)] font-[500] border-2 shadow-switch_shadow backdrop-blur-[54px] rounded-[16px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] flex flex-row justify-between items-center   p-[16px_32px]'
                    } mb-4`}
                >
                  <div className={` ${isDarkMode
                    ? '  text-[black]  font-[700]  text-[14px]'
                    : '  text-[white]  font-[700]  text-[14px]'
                    }`}>Mint tokens</div>
                  <Dropdown
                    menu={{
                      items: items.map((item) => ({
                        key: item.key,
                        label: (
                          <div className="flex items-center" onClick={() => handleClick(item.label)}>
                            <img src={item.logo} alt={item.label} className="w-5 h-5 rounded-full mt-2 mr-2" />
                            <span>{item.label}</span>
                          </div>
                        ),
                      })),
                    }}
                    placement="bottomLeft"
                    arrow
                  >
                    <Button
                      className={`${isAdavanceViewTrue
                        ? ' text-[12px] font-[700] p-[8px_16px] rounded-[8px] border-[1px]  '
                        : '  text-[12px] font-[700] p-[8px_16px] rounded-[8px] border-[1px] '
                        } ,${isDarkMode
                          ? ' text-[#107569] border-[#5FE9D0]     h-auto bg-[#FFFFFF] border-[#DAECEF] border-2 rounded-[1rem] '
                          : ' text-[#67FFD1] border-[rgba(1,252,206,0.20)]    h-auto bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] border-2 rounded-[1rem]  bg_container'
                        } ${isLoader ? 'cursor-not-allowed' : ' cursor-pointer '}`}
                      onClick={handleFaucet}
                    >
                      {isLoaderFaucet ? (
                        <div className=" flex justify-center items-center">
                          <Lottie
                            loop
                            animationData={kanaloader}
                            play
                            className="w-[2.2rem] h-[1.2rem] flex justify-center items-center"
                          />
                        </div>
                      ) : (
                        <span>{'Faucet'}</span>
                      )}
                    </Button>
                  </Dropdown>
                </div>}
                <div
                  className={`${isDarkMode
                    ? 'mb-[16px] text-[black] border-[#292a2d] bg-white border-2 shadow-switch_shadow backdrop-blur-[54px] rounded-[16px] border-[rgba(245,247,250,0.06)]   flex flex-col justify-start items-start !w-full py-[16px]'
                    : 'mb-[16px] text-[white] border-[#292a2d] border-2 shadow-switch_shadow backdrop-blur-[54px] rounded-[16px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] flex flex-col justify-start items-start !w-full py-[16px]'
                    }`}
                >
                  <div className='px-[2rem] pb-[1rem] flex flex-row justify-between items-center !w-full text-[14px] font-[700] font-inter'>
                    <div className={`${isDarkMode ? 'text-[black]' : 'text-[white]'}`}>Holdings</div>
                    {isRefresh?( 
                      isDarkMode? 
                      (<Lottie
                        loop
                        animationData={refreshLoaderBlack}
                        play
                        className="w-6 h-6 rounded-full mt-2 mr-2"
                     />):(<Lottie
                      loop
                      animationData={refreshLoader}
                      play
                      className="w-6 h-6 rounded-full mt-2 mr-2"
                   />)):(<div className='cursor-pointer'
                    onClick={address ? () => refreshRoutes(address) : () => false}
                    >{isDarkMode ? <img className="w-6 h-6 rounded-full mt-2 mr-2" src={Refresh_black} alt='Refresh' /> : <img  className="w-6 h-6 rounded-full mt-2 mr-2" src={Refresh} alt='refresh' />}
                    </div>)}

                  </div>
                  <div
                    className={`${isDarkMode
                      ? '  border-[0.1px] w-full border-[#e3e8ef]'
                      : ' border-[0.5px] w-full border-[rgba(255,255,255,0.10)]'
                      }`}
                  ></div>
                  <div className="py-[16px] px-[2rem] flex flex-col justify-between items-center w-full">
                    <div className="gap-[8px] text-[14px] font-[500] flex flex-row justify-start items-center w-full">
                      <div className={`${isDarkMode ? 'text-[#364152]' : 'text-[#BBBBBC] '} font-[500] text-[0.875rem]`}>
                        Long Switch Holdings
                      </div>
                      <Tooltip title={`${profitData && profitData.long >= 0 ? 'Profit': 'Loss'}`}
                      arrow={false}>
                      <div className={` cursor-default text-[12px] font-[500] flex flex-row justify-start items-center ${profitData && profitData.long >= 0 ? ` ${isDarkMode
                          ? ' text-[#107569]'
                          : 'text-[#00DFD1]   '
                          }` : ' text-[#F04438]'}`}>
                        {profitData ? Number(profitData.long).toLocaleString('en-US') : '0' } USDC <img className="ml-1" src={profitData && profitData.long >= 0 ? `${isDarkMode
                          ? ChevronGreenDark
                          : ChevronGreen
                          }` : ChevronRed}  alt={profitData && profitData.long >= 0 ? 'chevron green' : 'chevron red'} />
                      </div>
                    </Tooltip>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full'>
                      <div className='flex flex-row justify-start gap-[16px] items-center '>
                        <div className={`${isDarkMode ? 'text-[0.875rem] font-[500] text-[rgba(0,0,0,0.80)]' : 'text-[0.875rem] font-[500] text-[rgba(255,255,255,0.80)]'} font-[500]`}>
                          {longToken === null || longToken === undefined
                            ? 0
                            : Number(getUiAmount(longToken.balance, longToken.decimals).toFixed(2)).toLocaleString('en-US')} LS
                        </div>
                        <div className={`${isDarkMode ? 'text-[14px] font-[400] text-[#777879]' : 'text-[14px] font-[400] text-[#BBBBBC]'} `}>
                        {longToken === null || longToken === undefined
                            ? 0
                            : Number(getUiAmount(longToken.balance, longToken.decimals).toFixed(2)).toLocaleString('en-US')} USDC
                        </div>
                      </div>
                      <div
                        className={`  text-[12px] font-[700] p-[8px_16px] rounded-[8px] border-[1px] ${isDarkMode
                          ? 'text-[#107569] border-[#5FE9D0]'
                          : 'text-[#67FFD1] border-[rgba(1,252,206,0.20)]'
                          } ${isLoader ? 'cursor-not-allowed' : ' cursor-pointer '}`}
                        onClick={!isLoader ? () => setSellAndWithdraw('Sell') : () => false}
                      >
                        Sell
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${isDarkMode
                      ? '  border-[0.1px] w-full border-[#e3e8ef]'
                      : ' border-[0.5px] w-full border-[rgba(255,255,255,0.10)]'
                      }`}
                  ></div>
                  <div className="py-[16px] px-[2rem] flex flex-col justify-between items-center w-full">
                    <div className="gap-[8px] text-[14px] font-[500] flex flex-row justify-start items-center w-full">
                      <div className={`${isDarkMode ? 'text-[#364152]' : 'text-[#BBBBBC] '} font-[500] text-[0.875rem]`}>
                        Short Switch Holdings
                      </div>
                      <Tooltip title={`${profitData && profitData.short >= 0 ? 'Profit': 'Loss'}`}
                      arrow={false}>
                      <div className={` cursor-default text-[12px] font-[500] flex flex-row justify-start items-center ${profitData && profitData.short >= 0 ? ` ${isDarkMode
                          ? ' text-[#107569]'
                          : 'text-[#00DFD1]   '
                          }` : ' text-[#F04438]'}`}>
                        {profitData ? Number(profitData.short).toLocaleString('en-US') : '0'} USDC <img className="ml-1" src={profitData && profitData.short >= 0 ? `${isDarkMode
                          ? ChevronGreenDark
                          : ChevronGreen
                          }` : ChevronRed} alt={profitData && profitData.short >= 0 ? 'chevron green' : 'chevron red'} />
                      </div>
                      </Tooltip>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full'>
                      <div className='flex flex-row justify-start gap-[16px] items-center '>
                        <div className={`${isDarkMode ? 'text-[0.875rem] font-[500]  text-[rgba(0,0,0,0.80)]' : 'text-[0.875rem] font-[500] text-[rgba(255,255,255,0.80)]'} font-[500]`}>
                          {shortToken === null || shortToken === undefined
                            ? 0
                            : Number(getUiAmount(shortToken.balance, shortToken.decimals).toFixed(2)).toLocaleString('en-US')} SS
                        </div>
                        <div className={`${isDarkMode ? 'text-[14px] font-[400] text-[#777879]' : 'text-[14px] font-[400] text-[#BBBBBC]'} `}>
                        {shortToken === null || shortToken === undefined
                            ? 0
                            : Number(getUiAmount(shortToken.balance, shortToken.decimals).toFixed(2)).toLocaleString('en-US')} USDC
                        </div>
                      </div>
                      <div
                        className={`  text-[12px] font-[700] p-[8px_16px] rounded-[8px] border-[1px]       ${isDarkMode
                          ? 'text-[#107569] border-[#5FE9D0]'
                          : 'text-[#67FFD1] border-[rgba(1,252,206,0.20)]'
                          } ${isLoader ? 'cursor-not-allowed' : ' cursor-pointer '}`}
                        onClick={!isLoader ? () => setSellAndWithdraw('Withdraw') : () => false}
                      >
                        Withdraw
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${isDarkMode
                    ? 'rounded-[1rem] text-[black] border-[1px] w-full !min-h-[80px] !max-h-auto border-none bg-white '
                    : 'rounded-[1rem] text-[white] border-[1px] w-full !min-h-[80px] !max-h-auto border-[#292a2d] bg-gradient-to-r from-[#f5fffa0f] to-[#f5fffa00]  '
                    }`}
                >
                  <Tabs>
                    <TabList
                      className={`${isDarkMode
                        ? 'flex justify-center items-center rounded-tl-[1rem] border-b-2 border-[#e3e8ef]'
                        : 'flex justify-center items-center rounded-tl-[1rem] border-b-2 border-[#292a2d]'
                        }`}
                    >
                      <Tab
                        className={`${isDarkMode
                          ? 'text-center flex flex-row justify-center items-center 2xl:text-[0.875rem] xl:text-[0.875rem] lg:text-[0.875rem] rounded-tl-[1rem] md:text-[0.875rem] sm:text-[0.75rem] max-sm:text-[0.75rem] outline-none text-[#364152] font-[700]   hover:rounded-tl-[1rem] cursor-pointer  h-[3.5rem] w-full social_web_login_2_light bg-[#eff7f8]'
                          : 'text-center  flex flex-row justify-center items-center 2xl:text-[0.875rem] xl:text-[0.875rem] lg:text-[0.875rem] rounded-tl-[1rem] md:text-[0.875rem] sm:text-[0.75rem] max-sm:text-[0.75rem] outline-none text-[#FFFFFF] font-[700]    hover:rounded-tl-[1rem] cursor-pointer  h-[3.5rem] w-full social_web_login_2'
                          }`}
                      >
                        {t('open_orders')}
                      </Tab>
                      <Tab
                        className={`${isDarkMode
                          ? 'text-center flex flex-row justify-center items-center  2xl:text-[0.875rem] xl:text-[0.875rem] lg:text-[0.875rem] md:text-[0.875rem] sm:text-[0.75rem] max-sm:text-[0.75rem] outline-none text-[#364152] font-[700]    hover:rounded-t-[0rem] cursor-pointer  h-[3.5rem] w-full social_web_login_3_light bg-[#eff7f8]'
                          : 'text-center flex flex-row justify-center items-center  2xl:text-[0.875rem] xl:text-[0.875rem] lg:text-[0.875rem] md:text-[0.875rem] sm:text-[0.75rem] max-sm:text-[0.75rem] outline-none text-[#FFFFFF] font-[700]   hover:rounded-t-[0rem] cursor-pointer  h-[3.5rem] w-full social_web_login_3'
                          }`}
                      >
                        {t('open_claims')}
                      </Tab>
                      <Tab
                        className={`${isDarkMode
                          ? 'text-center flex flex-row justify-center items-center  2xl:text-[0.875rem] xl:text-[0.875rem] lg:text-[0.875rem] md:text-[0.875rem] sm:text-[0.75rem] max-sm:text-[0.75rem] outline-none text-[#364152] font-[700]   rounded-tr-[1rem] hover:rounded-tr-[1rem] cursor-pointer  h-[3.5rem] w-full social_web_login_4_light bg-[#eff7f8]'
                          : 'text-center flex flex-row justify-center items-center  2xl:text-[0.875rem] xl:text-[0.875rem] lg:text-[0.875rem] md:text-[0.875rem] sm:text-[0.75rem] max-sm:text-[0.75rem] outline-none text-[#FFFFFF] font-[700]   rounded-tr-[1rem] hover:rounded-tr-[1rem] cursor-pointer  h-[3.5rem] w-full social_web_login_4'
                          }`}
                      >
                        {t('history')}
                      </Tab>
                    </TabList>
                    <div>
                      <TabPanel className="w-full">
                        <div
                          className={`border-r-transparent border-l-transparent border-t-transparent w-full border-b-[1px] flex flex-row justify-start items-center text-[14px] font-[700] font-inter   p-[20px_32px] ${isDarkMode
                            ? '  border-[0.1px] w-full border-[#e3e8ef]'
                            : ' border-[0.5px] w-full border-[rgba(255,255,255,0.10)]'
                            }`}
                        >
                          <div className='mr-2'>Next Cycle Starts In : </div>
                          <div>{timing ? <RunningClock startTime={timing.cycleStartTimestampFinal} endTime={timing.tentativeCycleEndTimeFinal} /> : '00:00'}</div>
                        </div>
                        <div className="flex flex-col justify-center w-full  ">
                          {orderList &&
                            orderList.map((item: any, index: any) => {
                              return (
                                <div
                                  className={`${isDarkMode
                                    ? 'w-full h-[4.625rem] p-[20px_32px] flex justify-between border-b-2 border-[#e3e8ef]'
                                    : 'w-full  h-[4.625rem] p-[20px_32px] flex justify-between border-b-2 border-[#1e1f22]'
                                    }`}
                                >
                                  <div
                                    className={`${isDarkMode
                                      ? 'text-[0.75rem] text-[#697586] flex flex-col'
                                      : 'text-[0.75rem] text-[#FFFFFF66] flex flex-col'
                                      }`}
                                  >
                                    Pending {item.FullTokenName}
                                    <span
                                      className={`${isDarkMode ? 'text-[#364152] font-[700]' : 'text-white font-[700]'
                                        }`}
                                    >
                                      {item.TokenHistoryType === 'Buy'
                                        ? Number(item.balance.toFixed(2)).toLocaleString('en-US')
                                        : Number(item.TokenFinalOutAmount.toFixed(2)).toLocaleString('en-US')}{' '}
                                      {item.FullTokenNameSymbol}
                                    </span>
                                  </div>
                                  <div className=" flex justify-center">
                                    {isLoaderCancel && isCancelType === item.FullTokenName ? (
                                      <Lottie
                                        loop
                                        animationData={kanaloaderred}
                                        play
                                        className="w-[4.5rem] h-[1.5rem] flex justify-center items-center"
                                      />
                                    ) : (
                                      <button
                                        className={`${isDarkMode
                                          ? 'text-[#B42318] bg-[#FEF3F2] 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.625rem] max-sm:text-[0.625rem] font-bold ml-4 border-2 border-[#FDA29B] rounded-[0.5rem] 2xl:w-[4.563rem] xl:w-[4.563rem] lg:w-[4.563rem] md:w-[4.563rem] sm:w-[3.125rem] max-sm:w-[3.125rem] h-[2rem] flex justify-center items-center'
                                          : 'text-[#FF5555] 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.625rem] max-sm:text-[0.625rem] font-bold ml-4 border-2 border-[#FF4D5433] rounded-[0.5rem] 2xl:w-[4.563rem] xl:w-[4.563rem] lg:w-[4.563rem] md:w-[4.563rem] sm:w-[3.125rem] max-sm:w-[3.125rem] h-[2rem] flex justify-center items-center'
                                          }`}
                                        onClick={() => executeCancel(item.FullTokenName)}
                                      >
                                        {t('cancel')}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </TabPanel>
                      <TabPanel className="w-full">
                        {userNft &&
                          userNft.map((item: any, index: any) => {
                            return (
                              <div className="w-full h-[4.625rem] flex justify-between p-[20px_32px]">
                                <div
                                  className={`${isDarkMode
                                    ? 'text-[0.75rem] text-[#697586] flex flex-col'
                                    : 'text-[0.75rem] text-[#FFFFFF66] flex flex-col'
                                    }`}
                                >
                                  {item.positions.toUpperCase()}
                                  <span
                                    className={`${isDarkMode ? 'text-[#364152] font-[700]' : 'text-white font-[700]'}`}
                                  >
                                    {item.TokenHistoryType === 'Buy'
                                      ? Number(getUiAmount(item.amount, 18).toFixed(2)).toLocaleString('en-US')
                                      : Number(item.TokenFinalOutAmount.toFixed(2)).toLocaleString('en-US')}{' '}
                                    {item.FullTokenNameSymbol}
                                  </span>
                                </div>

                                <div className=" flex justify-start">
                                  {isLoaderClaim && isClaimTokenID === item.token_id ? (
                                    <Lottie
                                      loop
                                      animationData={kanaloader}
                                      play
                                      className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                                    />
                                  ) : (
                                    <Tooltip
                                      title="Please click the Claim button to get the tokens added to your wallet"
                                      arrow={false}
                                    >
                                      <button
                                        className={`${isDarkMode
                                          ? 'text-[#107569] 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.625rem] max-sm:text-[0.625rem] font-bold border-2 border-[#5FE9D0] rounded-[0.5rem] 2xl:w-[4.563rem] xl:w-[4.563rem] lg:w-[4.563rem] md:w-[4.563rem] sm:w-[3.125rem] max-sm:w-[3.125rem] h-[2rem] flex items-center justify-center mr-4'
                                          : 'text-[#00F9A9] 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.625rem] max-sm:text-[0.625rem] font-bold border-2 border-[#01FCCE33] rounded-[0.5rem] 2xl:w-[4.563rem] xl:w-[4.563rem] lg:w-[4.563rem] md:w-[4.563rem] sm:w-[3.125rem] max-sm:w-[3.125rem] h-[2rem] flex items-center justify-center mr-4'
                                          }`}
                                        onClick={() => executeClaim(item.token_id, item.amount)}
                                      >
                                        {t('claim')}
                                      </button>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </TabPanel>
                      <TabPanel className="w-full">
                        <div
                          className={`${isDarkMode
                            ? 'text-[0.75rem] w-full text-[#364152]  '
                            : 'text-[0.75rem] w-full text-[#FFFFFF66]  '
                            }`}
                        >
                          {tokenHistory &&
                            tokenHistory.map((item: any, index: any) => {
                              return (
                                <div
                                  className={`${isDarkMode
                                    ? 'flex p-[26px_32px] 2xl:justify-center xl:justify-center item-center lg:justify-center md:justify-center sm:justify-center max-sm:justify-center    w-full  py-2 border-b-2 border-b-[#eef2f6]'
                                    : 'flex p-[26px_32px]  2xl:justify-center xl:justify-center item-center lg:justify-center md:justify-center sm:justify-center max-sm:justify-center    w-full   py-2 border-b-2 border-b-[#1e1f22]'
                                    }`}
                                >
                                  <div className="flex flex-row justify-start items-center text-[0.625rem]  w-full h-[1.938rem]   ">
                                    {convertUTCToIST(item.block_timestamp)}
                                  </div>
                                  <div
                                    className={`${isDarkMode
                                      ? 'bg-[#F5FAFB] w-[6.813rem] h-[1.938rem] flex justify-center items-center rounded-[0.5rem] text-center ml-12'
                                      : 'bg-[#000000] w-[6.813rem] h-[1.938rem] flex justify-center items-center rounded-[0.5rem] text-center ml-12'
                                      }`}
                                  >
                                    {item.TokenHistoryType.toUpperCase()}
                                  </div>
                                  <div
                                    className={`${isDarkMode
                                      ? 'bg-[#F5FAFB] w-[12rem] h-[1.938rem] flex justify-start  ps-4 rounded-[0.5rem] text-[#364152] font-bold items-center ml-2 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.65rem] max-sm:text-[0.65rem]'
                                      : 'bg-[#000000] w-[12rem] h-[1.938rem] flex justify-start ps-4 rounded-[0.5rem] text-white font-bold items-center ml-2 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.65rem] max-sm:text-[0.65rem]'
                                      }`}
                                  >
                                    {item.TokenHistoryType==="Sell"?(Number(item.Finaluiamount).toLocaleString('en-US')) :(Number(getUiAmount(item.amount, 18).toFixed(2)).toLocaleString('en-US')) } {item.FullTokenNameSymbol}
                                    <span
                                      className={`${isDarkMode
                                        ? ' pl-1 font-normal text-[#364152);] 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.65rem] max-sm:text-[0.65rem] text-center'
                                        : ' pl-1 font-normal text-[#FFFFFF66] 2xl:text-[0.75rem] xl:text-[0.75rem] lg:text-[0.75rem] md:text-[0.75rem] sm:text-[0.65rem] max-sm:text-[0.65rem] text-center'
                                        }`}
                                    >
                                      {item.functionName}
                                    </span>
                                  </div>
                                  <div className="flex flex-col justify-center h-[1.938rem]  items-center">
                                    <a
                                      className="text-purple-700 underline hover:underline hover:text-purple-500"
                                      href={`https://testnet.bscscan.com/tx/${item?.transaction_hash}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img className="ml-2" src={LinkExternal} alt="link external" />
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>

          </div>
          <div className='flex flex-col xxl:!w-[560px] xl:!w-[560px] lg:!w-[560px] md:!w-full sm:!w-full max-sm:!w-full w-full'>

            {isAdavanceViewTrue && (

              <div className="flex flex-col   justify-start items-start ">
                <div
                  className={` ${isDarkMode
                    ? 'border-[#292a2d] w-full text-[black] bg-white font-[500] border-2 shadow-switch_shadow backdrop-blur-[54px] rounded-[16px] border-[rgba(245,247,250,0.06)]   flex flex-row justify-between items-center w-full p-[16px_32px]'
                    : 'border-[#292a2d] w-full text-[rgba(255,255,255,0.40)] font-[500] border-2 shadow-switch_shadow backdrop-blur-[54px] rounded-[16px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] flex flex-row justify-between items-center   p-[16px_32px]'
                    } mb-4`}
                >
                  <div className={` ${isDarkMode
                    ? '  text-[black]  font-[700]  text-[14px]'
                    : '  text-[white]  font-[700]  text-[14px]'
                    }`}>Mint tokens</div>
                  <Dropdown
                    menu={{
                      items: items.map((item) => ({
                        key: item.key,
                        label: (
                          <div className="flex items-center" onClick={() => handleClick(item.label)}>
                            <img src={item.logo} alt={item.label} className="w-5 h-5 rounded-full mt-2 mr-2" />
                            <span>{item.label}</span>
                          </div>
                        ),
                      })),
                    }}
                    placement="bottomLeft"
                    arrow
                  >
                    <Button
                      className={`${isAdavanceViewTrue
                        ? ' text-[12px] font-[700] p-[8px_16px] rounded-[8px] border-[1px]  '
                        : '  text-[12px] font-[700] p-[8px_16px] rounded-[8px] border-[1px] '
                        } ,${isDarkMode
                          ? ' text-[#107569] border-[#5FE9D0]     h-auto bg-[#FFFFFF] border-[#DAECEF] border-2 rounded-[1rem] '
                          : ' text-[#67FFD1] border-[rgba(1,252,206,0.20)]    h-auto bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] border-2 rounded-[1rem]  bg_container'
                        } ${isLoader ? 'cursor-not-allowed' : ' cursor-pointer '}`}
                      onClick={handleFaucet}
                    >
                      {isLoaderFaucet ? (
                        <div className=" flex justify-center items-center">
                          <Lottie
                            loop
                            animationData={kanaloader}
                            play
                            className="w-[2.2rem] h-[1.2rem] flex justify-center items-center"
                          />
                        </div>
                      ) : (
                        <span>{'Faucet'}</span>
                      )}
                    </Button>
                  </Dropdown>
                </div>
                <div className="  w-full">
                  <div
                    className={`${isDarkMode
                      ? 'w-full bg-white rounded-[1rem]    shadow-[#090D141F] h-auto'
                      : 'w-full bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] border-2 rounded-[1rem]   shadow-[#090D141F] h-auto'
                      }`}
                  >
                    <TERipple className="w-full h-auto outline-none">
                      <div
                        className={`${isDarkMode
                          ? 'flex justify-between items-center text-[0.875rem] text-[#364152] h-auto w-auto border-[#2f3033] p-[3%_6%]'
                          : 'flex justify-between items-center text-[0.875rem] text-[white] h-auto w-auto border-[#2f3033] p-[3%_6%]'
                          }`}
                        onClick={toggleShow}
                      >
                        <div className="  font-[700]">{t('chart')}</div>
                        <div
                          className={`${isDarkMode
                            ? 'w-[3.688rem] h-[1.75rem] cursor-pointer bg-[#EFF7F8] rounded-[0.5rem] flex justify-center items-center text-[0.625rem] font-[700]'
                            : 'w-[3.688rem] h-[1.75rem] cursor-pointer bg-[#FFFFFF1A] rounded-[0.5rem] flex justify-center items-center text-[0.625rem] font-[700]'
                            }`}
                        >
                          {!show ? t('open'): t('hide')}
                          {!show ? (
                            <img src={isDarkMode ? blackarrow : arrow} alt="/Downarrow" className=" pl-2" />
                          ) : (
                            <img src={isDarkMode ? blackarrowup : arrowup} alt="/Downarrow" className=" pl-2" />
                          )}
                        </div>
                      </div>
                    </TERipple>
                    <TECollapse show={show}>
                      <div className="2xl:inline-flex xl:inline-flex lg:inline-flex md:inline-flex sm:hidden max-sm:hidden border-t-2 border-[#1e1f22]">
                        <Graphic />
                      </div>
                    </TECollapse>
                  </div>
                </div>
                <div className=" my-[16px] !w-full">
                  <div
                    className={`${isDarkMode
                      ? 'w-full h-full bg-white rounded-[1rem]  shadow-[#090D141F]   '
                      : ' w-full h-full bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] border-2 rounded-[1rem]  shadow-[#090D141F]    '
                      }`}
                  >
                    <div className={`${isDarkMode
                      ? '  border-[#e3e8ef] border-b-2  '
                      : '  border-[#2f3033] border-b-2  '
                      } flex flex-row justify-between items-center`}>
                      <div
                        className={`${isDarkMode
                          ? 'text-[#364152] text-[0.875rem] font-bold h-auto w-auto   py-3.5 pl-5'
                          : 'text-white text-[0.875rem] font-bold h-auto w-auto   py-3.5 pl-5'
                          }`}
                      >
                        Current and Next Cycle
                      </div>
                      <div className={`${isDarkMode
                        ? 'text-[#364152] text-[0.75rem] font-[700] h-auto w-auto   py-3.5 pr-5'
                        : 'text-white text-[0.75rem] font-[700] h-auto w-auto   py-3.5 pr-5'
                        }`}><Timer /></div>
                    </div>
                    <div className="flex w-full justify-start m-5 2xl:overflow-hidden xl:overflow-hidden lg:overflow-hidden md:overflow-hidden sm:overflow-x-scroll max-sm:overflow-x-scroll gap-[16px]">
                      <div className="w-[45%] h-auto ">
                        <div
                          className={`${isDarkMode
                            ? 'text-[#4B5565] text-[0.75rem] 2xl:pl-0 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0 max-sm:pl-4 font-[500] '
                            : 'text-[0.75rem] text-white 2xl:pl-0 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0 max-sm:pl-4 font-[500]'
                            }`}
                        >
                          {t('current_cycle')}
                        </div>
                        <div
                          className={`${isDarkMode
                            ? 'bg-[#F5FAFB]  h-auto my-2 font-[400]    rounded-[0.5rem]  text-[#1D1E20] text-[0.75rem] flex py-2.5 px-4'
                            : '   font-[400]  my-2 h-auto rounded-[0.5rem] bg-[#0000004d] text-[#a0a0a1] text-[0.75rem] flex py-2.5 px-4'
                            }`}
                        >
                          {t('cycle_start_time')}
                          <span className={`${isDarkMode ? 'text-[#697586]' : 'text-white'} pl-5`}>
                            {timing ? timing.cycleStartTimestampFinal : '00:00'}
                          </span>
                        </div>
                        <div className="flex mt-2 justify-between w-full gap-[8px]">
                          <div
                            className={`${isDarkMode
                              ? ' w-1/2 h-auto rounded-[0.5rem] bg-[#F5FAFB] text-[0.75rem] text-[#1D1E20] px-4 py-2'
                              : ' w-1/2 h-auto rounded-[0.5rem] bg-[#0000004d] text-[0.75rem] text-[#a0a0a1] px-4 py-2'
                              }`}
                          >
                            <div>{t('start_price')}</div>
                            <div>{t('eth_90')}</div>
                            <div
                              className={`${isDarkMode ? 'text-[#697586] font-[700] pt-2' : 'text-white font-[700] pt-2'
                                }`}
                            >
                              {timing ? `$ ${Number(timing.cycleStartPriceFinal).toLocaleString('en-US')}` : '$ 0.00'}
                            </div>
                          </div>
                          <div className="w-1/2 h-auto rounded-[0.5rem] ">
                            <div
                              className={`${isDarkMode
                                ? 'w-full h-auto rounded-[0.5rem] bg-[#F5FAFB] text-[0.75rem] text-[#1D1E20] px-4 py-2'
                                : 'w-full h-auto rounded-[0.5rem] bg-[#0000004d] text-[0.75rem] text-[#a0a0a1] px-4 py-2'
                                }`}
                            >
                              <div>{t('current_price')}</div>
                              <div>{t('eth_90')}</div>
                              <div
                                className={`${isDarkMode ? 'text-[#697586] font-[700] pt-2' : 'text-white font-[700] pt-2'
                                  }`}
                              >
                                {ETHPrice ? `$ ${Number(ETHPrice).toLocaleString('en-US')}` : '$ 0.00'}
                              </div>{' '}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${isDarkMode
                            ? 'w-full flex flex-col mt-2 h-auto rounded-[0.5rem] bg-[#F5FAFB] text-[#1D1E20] text-[0.75rem] flex py-2.5 px-4'
                            : 'w-full flex flex-col mt-2 h-auto rounded-[0.5rem] bg-[#0000004d] text-[#a0a0a1] text-[0.75rem] flex py-2.5 px-4'
                            }`}
                        >
                          <div className="flex flex-row justify-between items-center py-1">
                            <div className="flex flex-row justify-start gap-[8px] items-center">
                              {t('expected_premium')}
                              <Tooltip placement="top" title={"The Premium to be received by Short Switch holders."} arrow={false}>
                                <img
                                  src={isDarkMode ? instructiondark : instruction}
                                  alt="/"
                                  className="cursor-pointer w-[0.75rem] h-[0.75rem] mt-0.5 ml-1"
                                />
                              </Tooltip>
                            </div>
                            <span className={`${isDarkMode ? 'text-[#697586]' : 'text-white'} pl-5`}>
                              {timing ? timing.nextExpectedPremium : '0.00'} %
                            </span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1">
                            <div className="flex flex-row justify-start gap-[8px] items-center">
                              {t('leverage')}
                              <Tooltip placement="top" title={"The Leverage that Long Switch holders have."} arrow={false}>
                                <img
                                  src={isDarkMode ? instructiondark : instruction}
                                  alt="/"
                                  className="cursor-pointer w-[0.75rem] h-[0.75rem] mt-0.5 ml-1"
                                />
                              </Tooltip>
                            </div>
                            <span className={`${isDarkMode ? 'text-[#697586]' : 'text-white'} pl-5`}>{timing ? timing.currentLeverage : '0'} </span>
                          </div>
                        </div>
                      </div>
                      <div className="w-[45%] h-auto">
                        <div
                          className={`${isDarkMode
                            ? 'text-[0.75rem] text-[#4B5565] font-[500] 2xl:pl-0 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0 max-sm:pl-4'
                            : 'text-[0.75rem] text-white font-[500] 2xl:pl-0 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0 max-sm:pl-4'
                            }`}
                        >
                          {t('next_cycle')}
                        </div>
                        <div
                          className={`${isDarkMode
                            ? 'w-full my-2 h-auto rounded-[0.5rem] bg-[#F5FAFB] text-[#1D1E20] text-[0.75rem] flex flex-row items-center py-2.5 px-4'
                            : 'w-full my-2 h-auto rounded-[0.5rem] bg-[#0000004d] text-[#a0a0a1] text-[0.75rem] flex flex-row items-center py-2.5 px-4'
                            }`}
                        >
                          {t('cycle_start_time')}
                          <Tooltip placement="top" title={"Tentative next cycle start time provided ETH's price deviation doesn't go beyond 2.5%"} arrow={false}>
                            <img
                              src={isDarkMode ? instructiondark : instruction}
                              alt="/"
                              className="cursor-pointer w-[0.75rem] h-[0.75rem]  pl-[2px]"
                            />
                          </Tooltip>
                          <span className={`${isDarkMode ? 'text-[#697586]' : 'text-white'} pl-3`}>
                            {timing ? timing.tentativeCycleEndTimeFinal : '00:00'}
                          </span>
                        </div>
                        <div className="flex mt-2 justify-between w-full gap-[8px]">
                          <div
                            className={`${isDarkMode
                              ? 'bg-[#F5FAFB] w-1/2 h-auto rounded-[0.5rem]  text-[0.75rem] text-[#1D1E20] px-4 py-2'
                              : 'bg-[#0000004d] w-1/2 h-auto rounded-[0.5rem]  text-[0.75rem] text-[#a0a0a1] px-4 py-2'
                              }`}
                          >
                            <div className="flex">
                              {t('trigger')}
                              <Tooltip placement="top" title={"ETH's upper price that can trigger next cycle"} arrow={false}>
                                <img
                                  src={isDarkMode ? instructiondark : instruction}
                                  alt="/"
                                  className="cursor-pointer w-[0.75rem] h-[0.75rem] mt-1  pl-[2px]"
                                />
                              </Tooltip>
                            </div>
                            <div>Up (ETH):</div>
                            <div className={`${isDarkMode ? 'text-[#697586]' : 'text-white'} font-[700] pt-2`}>
                              {timing ? `$ ${Number(timing.triggerPriceUp).toLocaleString('en-US')}` : '$ 0.00'}
                            </div>
                          </div>
                          <div className="w-1/2 h-full">
                            <div
                              className={`${isDarkMode
                                ? 'bg-[#F5FAFB] w-full h-auto rounded-[0.5rem] text-[0.75rem] text-[#1D1E20] px-4 py-2'
                                : 'w-full h-auto rounded-[0.5rem] bg-[#0000004d] text-[0.75rem] text-[#a0a0a1] px-4 py-2'
                                }`}
                            >
                              <div className="flex ">
                                {t('trigger')}
                                <Tooltip placement="top" title={"ETH's lower price that can trigger next cycle"} arrow={false}>
                                  <img
                                    src={isDarkMode ? instructiondark : instruction}
                                    alt="instruction/"
                                    className="cursor-pointer w-[0.75rem] h-[0.75rem] mt-1  pl-[2px]"
                                  />
                                </Tooltip>
                              </div>
                              <div>Down (ETH):</div>
                              <div className={`${isDarkMode ? 'text-[#697586]' : 'text-white'} font-[700] pt-2`}>
                                {timing ? `$ ${Number(timing.triggerPriceDown).toLocaleString('en-US')}` : '$ 0.00'}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${isDarkMode
                            ? 'w-full flex flex-col mt-2 h-auto rounded-[0.5rem] bg-[#F5FAFB] text-[#1D1E20] text-[0.75rem] flex py-2.5 px-4'
                            : 'w-full flex flex-col mt-2 h-auto rounded-[0.5rem] bg-[#0000004d] text-[#a0a0a1] text-[0.75rem] flex py-2.5 px-4'
                            }`}
                        >
                          <div className="flex flex-row justify-between items-center py-1">
                            <div className="flex flex-row justify-start gap-[8px] items-center">
                              {t('expected_premium')}
                              <Tooltip placement="top" title={"The Premium to be received by Short Switch holders."} arrow={false}>
                                <img
                                  src={isDarkMode ? instructiondark : instruction}
                                  alt="/"
                                  className="w-[0.75rem] h-[0.75rem] mt-0.5 ml-1 cursor-pointer"
                                />
                              </Tooltip>
                            </div>
                            <span className={`${isDarkMode ? 'text-[#697586]' : 'text-white'} pl-5`}>
                              {timing ? timing.nextExpectedPremium : '0.00'} %
                            </span>
                          </div>
                          <div className="flex flex-row justify-between items-center py-1">
                            <div className="flex flex-row justify-start gap-[8px] items-center">
                              {t('leverage')}
                              <Tooltip placement="top" title={"The Leverage that Long Switch holders have."} arrow={false}>
                                <img
                                  src={isDarkMode ? instructiondark : instruction}
                                  alt="/"
                                  className="w-[0.75rem] h-[0.75rem] mt-0.5 ml-1 cursor-pointer"
                                />
                              </Tooltip>
                            </div>
                            <span className={`${isDarkMode ? 'text-[#697586]' : 'text-white'}  pl-5`}>{timing ? timing.nextLeverage : '0'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between  w-full gap-[16px] px-4   h-auto">
                      <div className="  w-1/2 py-3">
                        <div className="font-bold text-[0.625rem] text-[#a0a0a1]   flex justify-start items-start">
                          <span className=" gap-0.5 flex flex-row justify-center items-center">
                            <div>{timing ? timing.currentPercentageChart?.longTokenPercentage : ''}</div> <div>% </div>
                          </span>

                          <div className="!w-[80%] h-[1.5rem]  mt-0.5 ml-1  bg-center">
                            {timing ? (
                              <BulletChart
                                data={[
                                  {
                                    title: '',
                                    ranges: [],
                                    measures: [
                                      timing.currentPercentageChart?.longTokenPercentage,
                                      timing.currentPercentageChart?.shortTokenPercentage,
                                    ],
                                    target: 100,
                                  },
                                ]}
                                colors={colors}
                              />
                            ) : (
                              <BulletChart
                                data={[
                                  {
                                    title: '',
                                    ranges: [],
                                    measures: [50, 50],
                                    target: 100,
                                  },
                                ]}
                                colors={colors}
                              />
                            )}
                          </div>
                          <span className="pl-2 gap-0.5 flex flex-row justify-center items-center">
                            <div> {timing ? timing.currentPercentageChart?.shortTokenPercentage : ''}</div>
                            <div>% </div>{' '}
                          </span>
                        </div>
                        <div
                          className={`${isDarkMode
                            ? 'flex w-full justify-between text-[0.625rem] text-[#1D1E20]'
                            : 'flex w-full justify-between text-[0.625rem] text-[rgba(255,255,255,0.60)] '
                            } `}
                        >
                          <div>{timing ? timing.currentCyclelongTokenSupply : 50} LS</div>
                          <div>{timing ? timing.currentCycleshortTokenSupply : 50} SS</div>
                        </div>
                      </div>
                      <div className=" w-1/2 py-3 ">
                        <div className=" font-bold text-[0.625rem] text-[#a0a0a1]   flex justify-between items-start">
                          <span className="  gap-0.5 flex flex-row justify-center items-center">
                            <div> {timing ? timing.nextPercentageChart?.longTokenPercentage : ''}</div>
                            <div> %</div>
                          </span>
                          <div className="!w-[80%] h-[1.5rem]  mt-0.5 ml-1  bg-center">
                            {timing ? (
                              <BulletChart
                                data={[
                                  {
                                    title: '',
                                    ranges: [],
                                    measures: [
                                      timing.nextPercentageChart?.longTokenPercentage,
                                      timing.nextPercentageChart?.shortTokenPercentage,
                                    ],
                                    target: 100,
                                  },
                                ]}
                                colors={colors}
                              />
                            ) : (
                              <BulletChart
                                data={[
                                  {
                                    title: '',
                                    ranges: [],
                                    measures: [50, 50],
                                    target: 100,
                                  },
                                ]}
                                colors={colors}
                              />
                            )}
                          </div>
                          <span className="pl-2 gap-0.5 flex flex-row justify-center items-center">
                            <div> {timing ? timing.nextPercentageChart?.shortTokenPercentage : ''}</div> <div>%</div>
                          </span>
                        </div>
                        <div
                          className={`${isDarkMode
                            ? 'flex w-full justify-between text-[0.625rem] text-[#1D1E20]'
                            : 'flex w-full justify-between text-[0.625rem] text-[rgba(255,255,255,0.60)] '
                            } `}
                        >
                          <div>{timing ? timing.nextCyclelongTokenSupply : 50} LS</div>
                          <div>{timing ? timing.nextCycleshortTokenSupply : 50} SS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="    w-full">
                  <div
                    className={`${isDarkMode
                      ? 'w-full h-auto bg-white rounded-[1rem] shadow-[#090D141F]  '
                      : '  h-auto bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] border-2 rounded-[1rem] shadow-[#090D141F]  '
                      }`}
                  >
                    <div>
                      <div
                        className={`${isDarkMode
                          ? 'flex justify-between text-[0.875rem] text-[#364152] font-[700] h-[3.5rem] w-auto border-[#e3e8ef] border-b-2 p-[3%_6%]'
                          : 'flex justify-between text-[0.875rem] text-[white] font-[700] h-[3.5rem] w-auto border-[#2f3033] border-b-2 p-[3%_6%]'
                          }`}
                      >
                        <div>{t('previous_cycle')}</div>
                        {/* <img src={isDarkMode ? instructiondark : instruction} alt="/instruction" className=" pl-2" /> */}
                      </div>
                      <div>
                        <ChartApp />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isWalletConnectVisible && <ConnectWallet />}
        {isCalculateVisible && <Calculate />}
        {isDisclaimerVisible && <DisclaimerPopup address={address!} email={''}/>}
      </div>
    </div>
  );
};

export default Dashboard;
