import React, { useEffect, useRef } from 'react';
import EthosX from '../assets/icons/EthosX.svg';
import sun from '../assets/icons/sun.svg';
import { useState } from 'react';
import Vectordown from '../assets/icons/Vectordown.svg';
import BSC_TESTNET from '../assets/icons/binance.svg';
import { Switch } from 'antd';
import menu from '../assets/icons/menu.svg';
import Mobilelogo from '../assets/icons/Mobilelogo.svg';
import Sidebar from './Sidebar';
import { useStore } from 'store';
import moon from '../assets/icons/moon.svg';
import menublack from '../assets/icons/menublack.svg';
import Vectorlight from '../assets/icons/Vectorlight.svg';
import { useTranslation } from 'react-i18next';

const Navbar = (props: any) => {
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLElement | null>(null);
  const { handleAdvancedView } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { isDarkMode, updateIsDarkMode, updateIsSelectedChain } = useStore();
  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };
  const [sidebarOpen, setisSidebarOpen] = useState(false);
  const handleopen = () => {
    setisSidebarOpen(!sidebarOpen);
  };
  // Function to close the dropdown
  const closeDropdown = () => {
    setIsOpen(false);
  };

  // Listen for clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: { target: any; }) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);
  return (
    <div ref={(dropdownRef as React.RefObject<HTMLDivElement>)}>
      <div className="flex justify-start font-inter xxl:!w-[1050px] xl:!w-[1050px] lg:!w-[1040px] md:!w-full sm:!w-full ">
        <div
          className={`${isDarkMode ? 'bg-white' : 'bg-[#ffffff0f]'
            }  w-full h-[6rem] rounded-b-[1rem] 2xl:bg-[#17181a] xl:bg-[#17181a] lg:bg-[#17181a] md:bg-transparent sm:bg-transparent max-sm:bg-transparent  flex flex-row items-center  justify-between  px-3`}
        >
          <div className="2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline   cursor-pointer">
            <img src={Mobilelogo} alt="Mobilelogo" />
          </div>
          <div
            className={`${isDarkMode
                ? 'bg-[#EFF7F8] 2xl:bg-[#EFF7F8] xl:bg-[#EFF7F8] lg:bg-[#EFF7F8] md:bg-[#EFF7F8] sm:bg-[#EFF7F8] max-sm:bg-[#EFF7F8]'
                : 'bg-[#ffffff0f] 2xl:bg-[#ffffff0f] xl:bg-[#ffffff0f] lg:bg-[#ffffff0f] md:bg-[#252729] sm:bg-[#252729] max-sm:bg-[#252729]'
              }   xxl:w-full xl:w-full lg:w-full md:w-[80%] sm:w-[90%] h-[3.5rem]  2xl:rounded-[2rem] xl:rounded-[2rem] lg:rounded-[2rem] md:rounded-[2rem] sm:rounded-[1rem] max-sm:rounded-[1rem]  2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-0 sm:ml-0 max-sm:ml-0 `}
          >
            <div
              className="flex flex-row   justify-between cursor-pointer  items-center w-auto p-[16px]"
              onClick={toggleSelect}
            >
              <div className="flex flex-row justify-between items-center gap-[8px] ">
                <img src={BSC_TESTNET} alt="BSC" className="  w-[1.5rem]" />
                <span
                  className={`${isDarkMode
                      ? '  font-bold text-[#364152] 2xl:text-[1rem] xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[0.875rem] max-sm:text-[0.875rem]'
                      : ' font-bold text-white 2xl:text-[1rem] xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[0.875rem] max-sm:text-[0.875rem]'
                    }`}
                >
                  {t('binance_test')}
                </span>
              </div>
              <img src={isDarkMode ? Vectorlight : Vectordown} alt="VectorDown" />

              {isOpen && (
                <div
                  id="dropdown-options"
                  className={`${isDarkMode
                      ? 'bg-[#EFF7F8] mt-2 absolute !z-[100] rounded-[1rem] overflow-auto scroll-smooth backdrop-blur-sm w-[250px]   h-auto     top-[5rem] '
                      : 'bg-[#ffffff0f] mt-2 absolute !z-[100] rounded-[1rem] overflow-auto scroll-smooth backdrop-blur-sm  w-[250px] h-auto      top-[5rem] '
                    }`}
                >
                  <div className=" flex py-3.5">
                    <div>
                      <img src={BSC_TESTNET} alt="BSC" className=" ml-5 w-[1.5rem]" />
                    </div>
                    <div
                      className={isDarkMode ? 'text-[#364152] px-2 ' : 'text-[#ffffff80] px-2 '}
                      onClick={() => updateIsSelectedChain(97)}
                    >
                      {t('binance_test')}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`${isDarkMode ? ' bg-[#EFF7F8] ' : 'bg-[#ffffff0f] '
              } w-full h-[3.5rem] rounded-[2rem] p-[16px_32px] ml-5   2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden`}
          >
            <div
              className={`${isDarkMode ? 'text-[#364152] ' : 'text-[white] '
                }text-[0.875rem] flex justify-between items-center  font-[700]`}
            >
              {t('advanced')}
              <Switch
                onClick={(e) => handleAdvancedView(e)}
                defaultChecked={(localStorage.getItem('isAdavanceViewTrue') === 'true')!}
              />
            </div>
          </div>
          <div className="2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden w-full  max-sm:hidden">
            <div
              className={`${isDarkMode
                  ? 'w-full h-[3.5rem] rounded-[2rem] bg-[#EFF7F8] ml-5   flex justify-between items-center p-[16px_32px] 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden  max-sm:hidden'
                  : 'w-full h-[3.5rem] rounded-[2rem] bg-[#ffffff0f] ml-5   flex justify-between items-center p-[16px_32px] 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden  max-sm:hidden'
                }`}
            >
              <div
                className={`${isDarkMode ? 'text-[0.875rem] text-[#364152] font-[700]' : 'text-[0.875rem] text-[#FFFFFF80] font-[700]'
                  }`}
              >
                {t('switch')}
              </div>
              <div className="flex  flex flex-row justify-center items-center gap-[8px]">
                <div className={`${isDarkMode ? 'text-[0.75rem] text-[#364152]' : 'text-[0.75rem] text-[#FFFFFF80]'}`}>
                  {t('powered')}
                </div>
                <img src={EthosX} alt="Ethosx" />
              </div>
            </div>
          </div>
          <div
            className="2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden  max-sm:hidden"
            onClick={() => updateIsDarkMode(!isDarkMode)}
          >
            <div
              className={`${isDarkMode ? 'bg-white !rounded-[50%] ' : 'bg-[#ffffff0f] !rounded-[50%] '
                } w-[2.5rem] h-[2.5rem] ml-5   flex justify-center items-center !rounded-[50%] cursor-pointer 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden max-sm:hidden`}
            >
              <img src={isDarkMode ? moon : sun} alt="sun" className='rounded-[50%]' />
            </div>
          </div>
          <div className=" 2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline   cursor-pointer">
            <img src={isDarkMode ? menublack : menu} alt="/menu" onClick={handleopen} />
            {sidebarOpen && (
              <div>
                <Sidebar />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
