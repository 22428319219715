import React from 'react'
import Sidebar from './Sidebar'
import { Button, Dropdown, Tooltip, notification } from 'antd';
import { useStore } from 'store';
import LearnHeader from './LearnHeader';
import LinkExternal from '../assets/icons/link-external-green.svg';
import LinkExternalDark from '../assets/icons/link-external-dark.svg';
import XCircle from '../assets/icons/x-circle_dark.svg';
import XCircleWhite from '../assets/icons/x-circle-white.svg';
import Search from '../assets/icons/search-dark.svg';
const Learn = () => {
    const [api, contextHolder] = notification.useNotification();
    const {
        isDarkMode,
        updateIsWalletConnectVisible,
        isWalletConnectVisible,
        updateIsCalculateVisible,
        isCalculateVisible,
        updateSwitchSettlementList,
        dashboardInputValue,
        updateDashboardInputValue,
        updateChartValue,
        updateETHPrice,
        ETHPrice,
        updateTiming,
        updateExpectedReturn
    } = useStore();
    return (
        <div className={`${isDarkMode ? 'bg-[#E4F2F3]' : 'bg-[#ffffff0f]'} h-screen font-inter flex !w-full`}>
            {contextHolder}
            <div className=" 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden">
                <Sidebar />
            </div>
            <div className="flex flex-col overflow-auto 2xl:!ml-[19rem] xl:!ml-[19rem] lg:!ml-[19rem] md:ml-0   sm:ml-0 max-sm:ml-0 1w-full gap-[0.5rem]">
                <div >
                    <LearnHeader />
                </div>
                <div className="p-[0.8rem] my-[16px]  gap-[16px] flex 2xl:mx-[32px] xl:mx-[32px] lg:mx-[32px] md:mx-[32px] sm:mx-0 max-sm:mx-0 flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-sm:flex-col  items-start !justify-between  ">
                    <div className='xxl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-[100%] sm:w-[100%] msx-sm:w-[100%]'>
                        <div className='flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-sm:flex-col  justify-between items-start w-full'>
                            <div className={`${isDarkMode ? '!text-[#000]' : '!text-[#fff] '} font-manrope text-[1.5rem] font-[800] `} >Learn Switch</div>
                        </div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[1.5rem] `}>How SWITCH Works</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>Switch operates on a pool-versus-pool model, creating a zero-sum game. Long Switch (LS) holders pay a predetermined premium (m) to Short Switch (SS) holders, receiving protection against a declining market price of the underlying asset. Time is divided into cycles of fixed* duration, typically 2 hours. At the end of each cycle, there's a settlement where Switch tokens are transferred, either from the Long Switch Pool to the Short Switch Pool or vice versa, depending on the direction of the underlying asset's price movement.</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[1.5rem] `}>Cycle Example:</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] `}>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Premium (m): 2%</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Long Switch Tokens (LS): 1,000,000</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Short Switch Tokens (SS): 5,000,000</div>
                        </div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[1.5rem] `}>Settlement in Different Market Conditions:</div>
                        <div className='flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-sm:flex-col justify-between items-start w-full py-[0.5rem]'>
                            <div className='xxl:w-[49%] xl:w-[49%] lg:w-[49%] md:w-[100%] sm:w-[100%] max-sm:w-[100%]'>
                                <div className=' text-[#2CE] text-[0.9rem] font-[800] font-manrope'>Market Falls:</div>
                                <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify  `}>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>1.</span>2% of tokens are transferred from the Long Pool to the Short Pool as a premium. [20,000 Tokens]</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>2.</span> 1.5% of tokens are transferred from the Short Pool to the Long Pool due to the market fall. [75,000 Tokens]</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>3.</span> A net total of 55,000 tokens is transferred from the Short Pool to the Long Pool.</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>4.</span> Long Switch holders gain 5.5%, and Short Switch holders incur a loss of 1.1% in this cycle.</div>
                                </div>
                            </div>
                            <div className='xxl:w-[49%] xl:w-[49%] lg:w-[49%] md:w-[100%] sm:w-[100%] max-sm:w-[100%]'>
                                <div className=' text-[#2CE] text-[0.9rem] font-[800] font-manrope'>Stable or Rising Market:</div>
                                <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>1.</span> 2% of tokens are transferred from the Long Pool to the Short Pool as a premium. [20,000 Tokens]</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>2.</span> 1.5% of tokens are transferred from the Short Pool to the Long Pool due to the market fall. [75,000 Tokens]</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>3.</span> A net total of 55,000 tokens is transferred from the Short Pool to the Long Pool.</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>4.</span> Long Switch holders gain 5.5%, and Short Switch holders incur a loss of 1.1% in this cycle.</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[1.5rem] `}>*Protection Against Large Market Moves:</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>In the event of significant market shifts, Short Switch (SS) holders may be at risk of substantial losses within a single cycle. To safeguard users against such market volatility, Switch incorporates an inbuilt mechanism. The cycle concludes if the market moves either up or down by a certain percentage (e.g., 2.5%). This mechanism helps protect against excessive losses.</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[1.5rem] `}>Protection Against Large Market Moves:</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>In the event of substantial market shifts, the settlement process appears as follows:</div>
                        <div className='flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-sm:flex-col justify-between items-start w-full py-[0.5rem]'>
                            <div className='xxl:w-[49%] xl:w-[49%] lg:w-[49%] md:w-[100%] sm:w-[100%] max-sm:w-[100%]'>
                                <div className=' text-[#2CE] text-[0.9rem] font-[800] font-manrope'>Market Decline by 2.5% (30 minutes after cycle start):</div>
                                <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify  `}>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>1.</span> 0.5% (calculated as 2% * 30 minutes / 2 hours) of tokens are transferred from the Long Pool to the Short Pool as a premium. [5,000 Tokens]</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>2.</span> 2.5% of tokens are transferred from the Short Pool to the Long Pool due to the market fall. [125,000 Tokens]</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>3.</span> A net total of 120,000 tokens is transferred from the Short Pool to the Long Pool.</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>4.</span> Long Switch holders gain 12%, while Short Switch holders incur a loss of 2.4% in this cycle.</div>
                                </div>
                            </div>
                            <div className='xxl:w-[49%] xl:w-[49%] lg:w-[49%] md:w-[100%] sm:w-[100%] max-sm:w-[100%]'>
                                <div className=' text-[#2CE] text-[0.9rem] font-[800] font-manrope'>Market Increase by 2.5% (30 minutes after cycle start):</div>
                                <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>1.</span> 0.5% (calculated as 2% * 30 minutes / 2 hours) of tokens are transferred from the Long Pool to the Short Pool as a premium. [5,000 Tokens]</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>2.</span> No other transfers occur since the market did not fall.</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>3.</span> A net total of 5,000 tokens is transferred from the Long Pool to the Short Pool.</div>
                                    <div className='flex flex-row justify-start items-start'><span className='pr-1'>4.</span>Long Switch holders incur a loss of 0.5%, while Short Switch holders gain 0.1% in this cycle.</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[1.5rem] `}>Why use Switch?</div>
                        <div className=' text-[#2CE] text-[0.9rem] font-[800] font-manrope'>Long Switch</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>If you hold assets like ETH and anticipate long-term growth but are concerned about potential short-term declines in their value, Long Switch offers a solution. By purchasing Long Switch tokens, you can safeguard your assets in a falling market while still maintaining exposure to the upside potential. To secure this protection, you pay a fixed premium. If the market rises, you profit from your asset holdings, and if it falls, you receive compensation in Long Switch tokens, ensuring that the combined value of your assets and Long Switch holdings remains consistent (excluding the premium paid).</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[0.5rem] `}>Note</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify`}>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Switch tokens are available for purchase in both small and large amounts without restrictions.</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>While this example uses ETH, Switch will be initially launched for ETH and BTC, and it can be applied to any volatile asset.</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>You can profit from falling markets with Long Switch, even without holding the underlying volatile asset.</div>
                        </div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[0.5rem] `}>Example</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Holding: 10 ETH</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>ETH Price: $2,000</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Premium (m): 2%</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span> Switch Tokens: 1,000,000 (of which you hold 2,000, or 0.002%)</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Short Switch Tokens: 10,000,000</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Large Move Barrier: 10%</div>
                        </div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>To safeguard your $20,000 exposure (10 ETH * $2,000/ETH), you would acquire $2,000 worth of Long Switch (equivalent to 2,000 tokens) in this scenario. If the market falls by 5% by the end of the cycle (ETH price = $1,900), a net transfer of 480,000 tokens (5% * 10,000,000 - 2% * 1,000,000) occurs from the Short Pool to the Long Pool. Consequently, the Long Pool contains 1,480,000 tokens, translating to a 0.002% ownership of 2,960 tokens. This results in a profit of $960 in Long Switch holdings, effectively offsetting your $1,000 loss in ETH (10 * ($2,000 - $1,900)), minus the $40 premium paid for protection.</div>
                        <div className=' text-[#2CE] text-[0.9rem] font-[800] font-manrope mt-[1.5rem]'>Short Switch</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>If you anticipate that ETH will experience an increase or remain stable in the near future, Short Switch is an option to earn a consistent premium each cycle. You receive the premium in its entirety each cycle, and if the market declines, you compensate the Longs for the market's fall.</div>

                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[800] pt-[0.5rem] `}>Example</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Premium (m): 2%</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Long Switch Tokens: 1,000,000</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Short Switch Tokens: 10,000,000</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Large Move Barrier: 10%</div>
                            <div className='flex flex-row justify-start items-start'> <span className={`${isDarkMode ? ' ' : ' '}  `}><li></li> </span>Over the course of the next 5 cycles, ETH returns as follows: 0.2%, 0.3%, -0.1%, 0%, -0.2%.</div>
                        </div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>Over the course of the next 5 cycles, ETH returns as follows: 0.2%, 0.3%, -0.1%, 0%, -0.2%.</div>
                        <div className={`${isDarkMode ? '!text-[rgba(0,0,0,0.70)]' : '!text-[rgba(255,255,255,0.70)] '} font-manrope text-[0.9rem] font-[400] pt-[0.5rem] text-justify `}>This example illustrates how Short Switch can generate a return of 0.66% within a relatively short period, even when the market experiences various price changes.</div>

                    </div>
                    {/* <div className='xxl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[100%] sm:w-[100%] max-sm:w-[100%]'>
                        <div className='flex flex-col justify-start items-start w-full gap-[0.5rem]'>
                            <div className={`${isDarkMode ? '!text-[#000]' : '!text-[#fff] '} text-[24px] font-[800] font-manrope pb-[1rem]`}>Useful links</div>
                            <div className={`${isDarkMode ? '!text-[#107569] border-[1px] border-[#5FE9D0]' : '!text-[#2ED3B7] border-[1px] border-[rgba(1,252,206,0.20)] '} !w-full cursor-pointer rounded-[0.5rem] text-[12px] font-[700] font-inter p-[0.5rem_1rem] flex flex-row justify-between items-center`}>
                                Watch Video
                                {isDarkMode ? <img src={LinkExternalDark} alt='' /> : <img src={LinkExternal} alt='' />}
                            </div>
                            <div className={`${isDarkMode ? '!text-[#107569] border-[1px] border-[#5FE9D0]' : '!text-[#2ED3B7] border-[1px] border-[rgba(1,252,206,0.20)] '} !w-full cursor-pointer rounded-[0.5rem] text-[12px] font-[700] font-inter p-[0.5rem_1rem] flex flex-row justify-between items-center`}>
                                View Workflow
                                {isDarkMode ? <img src={LinkExternalDark} alt='' /> : <img src={LinkExternal} alt='' />}
                            </div>
                            <div className={`${isDarkMode ? '!text-[#107569] border-[1px] border-[#5FE9D0]' : '!text-[#2ED3B7] border-[1px] border-[rgba(1,252,206,0.20)] '} !w-full cursor-pointer rounded-[0.5rem] text-[12px] font-[700] font-inter p-[0.5rem_1rem] flex flex-row justify-between items-center`}>
                                Try Switch
                                {isDarkMode ? <img src={LinkExternalDark} alt='' /> : <img src={LinkExternal} alt='' />}
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Learn
