import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import NotFoundPage from './Pages/NotFoundPage';
import { notification } from 'antd';
import './i18n';
import Learn from 'Pages/Learn';
import DisclaimerPopup from 'Pages/Component/DisclaimerPopup';
const App = () => {
  notification.config({
    placement: 'bottomRight',
    maxCount: 1,
  });
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path='/learn' element={<Learn />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
