import { ethers } from 'ethers';

export const web3AuthClientId = `BHu-fep3dHRLAMMFZNSd7Yudq8imJ_lqtmfRtqUvBJnsk1vj1VSBe5fZFmvmw_YJonvYydTO30mxhC4GWBPnDIk`;
export const REACT_APP_CHAIN_ID_HEX = {
  POLYGON: '0x89',
  BSC: '0x38',
};
export const EVM_CHAIN_ID = {
  POLYGON: '137',
  BINANCE: '56',
  ETHEREUM: '1',
};

export const EVM_RPC_MAINNET = {
  POLYGON: `https://polygon-mainnet.g.alchemy.com/v2/4vVUgB3He49orUzsWg3gIinHY86NGy7i`,
  BINANCE: `https://bsc-dataseed1.ninicoin.io`,
  ETHEREUM: `https://eth-mainnet.g.alchemy.com/v2/NUKQ1VR_hdZlUjzVUN4Gh19diQe2KUQn`,
};

export const BSCTestnetRPC = `https://bsc-testnet.nodereal.io/v1/${process.env.REACT_APP_NODEREAL_KEY}`; //BSC Testnet
export const provider = new ethers.providers.JsonRpcProvider(BSCTestnetRPC);
export const BSC_TOKEN_1 = {
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  decimals: 18,
  symbol: 'BNB',
  logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
};
export const BSC_TOKEN_2 = {
  address: '0x30B74a8766563DbAbAf04e0FFE392817F2D02Da1',
  decimals: 6,
  symbol: 'pUSDC',
  logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
};

export const CHART = [
  {
    Time: '2023-10-01 14:57:16',
    Numbers: [
      {
        long_token_balance_before: 454045,
        short_token_balance_before: 9536527,
        long_token_balance_after: 435694,
        short_token_balance_after: 9554839,
      },
    ],
  },
  {
    Time: '2023-10-01 17:05:43',
    Numbers: [
      {
        long_token_balance_before: 435694,
        short_token_balance_before: 9554839,
        long_token_balance_after: 422923,
        short_token_balance_after: 9567572,
      },
    ],
  },
  {
    Time: '2023-10-01 19:55:49',
    Numbers: [
      {
        long_token_balance_before: 422923,
        short_token_balance_before: 9567572,
        long_token_balance_after: 408664,
        short_token_balance_after: 9581793,
      },
    ],
  },
  {
    Time: '2023-10-01 23:13:52',
    Numbers: [
      {
        long_token_balance_before: 408664,
        short_token_balance_before: 9581793,
        long_token_balance_after: 482108,
        short_token_balance_after: 9508310,
      },
    ],
  },
  {
    Time: '2023-10-02 01:34:16',
    Numbers: [
      {
        long_token_balance_before: 482108,
        short_token_balance_before: 9508310,
        long_token_balance_after: 498325,
        short_token_balance_after: 9492055,
      },
    ],
  },
  {
    Time: '2023-10-02 03:46:16',
    Numbers: [
      {
        long_token_balance_before: 498325,
        short_token_balance_before: 9492055,
        long_token_balance_after: 483523,
        short_token_balance_after: 9506819,
      },
    ],
  },
];
