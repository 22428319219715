import React, { useEffect, useState } from 'react';
import close from '../../assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import instruction from '../../assets/icons/instruction.svg';
import instructiondark from '../../assets/icons/instructiondark.svg';
import { useStore } from 'store';
import { calculateValue, preventPasteNegativeNumber } from 'utils/helper';
import useDebounce from '../../hooks/useDebounce';
import WrongBlack from '../../assets/icons/Wrong_black.svg';
import alertTriangle from '../../assets/icons/alert-triangle.svg';
import { Tooltip } from 'antd';
const CalculatePopup = () => {
  const [inputValue, setInputValue] = useState('');
  const [inputValueUSD, setInputValueUSD] = useState(0);
  const [calculate, setCalculate] = useState('');
  const { t } = useTranslation();
  const { isDarkMode, isCalculateVisible, updateIsCalculateVisible, updateDashboardInputValue, ETHPrice } =
    useStore();
  const switchSettlementList = JSON.parse(localStorage.getItem('switchSettlementList') || '[]');
  let debounceAmount = useDebounce<string>(inputValue, 1500);
  useEffect(() => {
    if (debounceAmount !== inputValue) return;

    const fetchData = async () => {
      if (inputValue && Number(inputValue) !== 0) {
        const calculateValueData = await calculateValue(inputValue, switchSettlementList, ETHPrice);
        setCalculate(calculateValueData);
      } else {
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceAmount, inputValue]);
  const onTokenAmountChange = async (e: any) => {
    let value: any = e.target.value;
    // only accept digit . and numbers
    if (
      // eslint-disable-next-line no-useless-escape
      /^[0-9\.]*$/gm.test(value) &&
      // eslint-disable-next-line no-useless-escape
      ((value.match(/[\.,]/gm) && value.match(/^[0-9]{0,9}(\.|,)?[0-9]{0,8}$/gm)) ||
        // eslint-disable-next-line no-useless-escape
        (!value.match(/[\.,]/gm) &&
          value.match(/^[0-9]{0,9}$/gm) &&
          (!value.match(/\./gm) || value.match(/\./gm)?.length <= 1) &&
          (!value.match(/,/gm) || value.match(/,/gm)?.length <= 1)))
    ) {
      // replace duplication if needed
      const amount = value
        .replace(/\.+/gm, '.')
        .replace(/,+/gm, ',')
        .replace(/^0+/gm, '0')
        // if first character is . then replace them with 0.
        .replace(/^\./, '0.');
      setInputValue(amount);
      setInputValueUSD(Number(amount)*Number(ETHPrice))
    }
  };
  const setApply = (number: any) => {
    if (number) {
      updateIsCalculateVisible(false);
      updateDashboardInputValue(number);
    }
  };
  return (
    <>
      {isCalculateVisible ? (
        <div className="fixed z-[3] inset-0 h-full w-full   backdrop-blur-lg flex flex-row justify-center font-inter  items-center">
          <div
            className={`${isDarkMode
              ? 'bg-white 2xl:w-[560px] xl:w-[560px] lg:w-[560px] md:w-full sm:w-full max-sm:w-full h-auto border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] border-2 rounded-[1rem] '
              : 'bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] 2xl:w-[560px] xl:w-[560px] lg:w-[560px] md:w-full sm:w-full max-sm:w-full h-auto border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] border-2 rounded-[1rem] '
              }`}
          >
            <div
              className={`${isDarkMode
                ? 'flex flex-row justify-between items-center py-[20px] rounded-t-[1rem] px-[24px] bg-[#F6FEFC]'
                : 'flex flex-row justify-between items-center py-[20px] rounded-t-[1rem] px-[24px] bg-[rgba(255,255,255,0.06)]'
                }`}
            >
              <div
                className={`${isDarkMode
                  ? ' text-[#000000]  font-inter text-[20px] font-[700]'
                  : 'text-[white] font-inter text-[20px] font-[700]'
                  }`}
              >
                {t('calculate')}
              </div>
              <div
                onClick={() => {
                  updateIsCalculateVisible(false);
                }}
                className="cursor-pointer  "
              >
                {isDarkMode ? <img src={WrongBlack} alt="WrongBlack" /> : <img src={close} alt="close" />}
              </div>
            </div>
            <div
              className={`${isDarkMode
                ? 'bg-[#E4F2F3] p-[24px] flex flex-col gap-[8px] w-full rounded-[0rem_0rem_1rem_1rem]'
                : 'bg-[#1D1E20] p-[24px] flex flex-col gap-[8px] w-full rounded-[0rem_0rem_1rem_1rem]'
                }`}
            >
              <div
                className={`${isDarkMode
                  ? 'text-[14px] font-[400] text-[#1D1E20] flex flex-row justify-start items-center'
                  : 'text-[14px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center'
                  }`}
              >
                {t('amount_to_be_hedged')}
                <Tooltip placement="top" title={"Please key in your ETH holdings"} arrow={false}>
                  <img src={isDarkMode ? instructiondark : instruction} alt="/instruction" className=" pl-2" />
                </Tooltip>
              </div>
              <div className="flex flex-row justify-start items-center w-full">
                <input
                  id="long-switch-token-amount"
                  type="text"
                  placeholder="0.00"
                  min="0"
                  autoFocus={true}
                  autoComplete="off"
                  onWheel={(event) => event.currentTarget.blur()}
                  onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                  onPaste={preventPasteNegativeNumber}
                  value={inputValue}
                  onChange={onTokenAmountChange}
                  className={`${isDarkMode
                    ? 'text-right text-[black] text-[20px] font-[500] bg-[#F6FEFC] border-[1px] border-[#CFE7EA] w-full rounded-[1rem] outline-none px-[24px] py-[16px] placeholder:text-[black]'
                    : 'text-right text-[white] text-[20px] font-[500] bg-[#111213] border-[1px] border-[rgba(255,255,255,0.10)] w-full rounded-[1rem] outline-none px-[24px] py-[16px]'
                    }`}
                />
                <div
                  className={`${isDarkMode
                    ? 'rounded-[8px] bg-[#C5E1E6] p-[8px] text-[12px] font-[400] text-[black] cursor-pointer ml-[-95%]'
                    : 'rounded-[8px] bg-[#1D1E20] p-[8px] text-[12px] font-[400] text-[rgba(255,255,255,0.60)] cursor-pointer ml-[-95%]'
                    }`}
                >
                  {t('eth')}
                </div>
              </div>
              <div className={`${isDarkMode
                ? '   text-[14px] font-[400] text-[black]  '
                : '   text-[14px] font-[400] text-[rgba(255,255,255,0.40)] '
                }flex flex-row justify-end items-end w-full px-1`}>
                  {Number(inputValueUSD).toLocaleString('en-US')} USD
                </div>
              <div
                className={`${isDarkMode
                  ? 'text-[14px] font-[400] text-[#1D1E20] flex flex-row justify-start items-center'
                  : 'text-[14px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center'
                  }`}
              >
                {t('long_switch_token_to_buy')}
                <Tooltip placement="top" title={"LS tokens to buy to hedge your ETH position"} arrow={false}>
                  <img src={isDarkMode ? instructiondark : instruction} alt="/instruction" className=" pl-2" />
                </Tooltip>
              </div>
              <div className="flex flex-row justify-start items-center w-full">
                <input
                  readOnly
                  placeholder="0.00"
                  value={Number(calculate).toFixed(2)}
                  className={`${isDarkMode
                    ? 'text-right text-[black] text-[20px] font-[500] bg-transparent border-[1px] border-[#CFE7EA] w-full rounded-[1rem] outline-none px-[24px] py-[16px] placeholder:text-[black]'
                    : 'text-right text-[white] text-[20px] font-[500] bg-transparent border-[1px] border-[rgba(255,255,255,0.10)] w-full rounded-[1rem] outline-none px-[24px] py-[16px]'
                    }`}
                />
                <div
                  className={`${isDarkMode
                    ? 'rounded-[8px] bg-[#C5E1E6] p-[8px] text-[12px] font-[400] text-[black] cursor-pointer ml-[-95%]'
                    : 'rounded-[8px] bg-[#1D1E20] p-[8px] text-[12px] font-[400] text-[rgba(255,255,255,0.60)] cursor-pointer ml-[-95%]'
                    }`}
                >
                  {t('ls')}
                </div>
              </div>
              <div className="p-[32px] w-full flex flex-row justify-start items-center gap-[16px] font-manrope">
                <img src={alertTriangle} alt="alert" />
                <div
                  className={`${isDarkMode
                    ? 'text-[black] text-[14px] font-[400] flex flex-col justify-start items-start'
                    : 'text-[#D2D2D2] text-[14px] font-[400] flex flex-col justify-start items-start'
                    }`}
                >
                  <div className={`${isDarkMode ? 'text-[#777879] ' : 'text-[#777879] '}`}>Note:</div>
                  <div>We are not considering any existing LS/SS holdings in wallet</div>
                </div>
              </div>
              <div
                className={`${isDarkMode ? 'buy-button-light ' : 'buy-button'}`}
                // className=" flex flex-row justify-center items-center backdrop-blur-[54.36563491821289px]   text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent  w-full   py-[16px] mt-[8px] cursor-pointer text-[16px] font-[700] rounded-[16px] "
                onClick={() => {
                  setApply(Number(calculate).toFixed(2));
                }}
              >
                <div
                  className={`${isDarkMode
                    ? 'cursor-pointer buy-button-inner-light text-[#107569] text-[16px] font-[700] text-center'
                    : 'cursor-pointer buy-button-inner text-[#00f9a9] text-[16px] font-[700]  text-center'
                    }`}
                >
                  {t('apply')}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CalculatePopup;
