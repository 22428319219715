import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import close from '../../assets/icons/close.svg';
import close_black from '../../assets/icons/close_black.svg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import iconApple_white from '../../assets/icons/icon-apple-white.svg';
import iconApple_black from '../../assets/icons/icon-apple-black.svg';
import iconDiscord from '../../assets/icons/icon-discord.png';
import iconFacebook from '../../assets/icons/icon-facebook.png';
import iconGithub_white from '../../assets/icons/github_white.svg';
import iconGithub_balck from '../../assets/icons/github_black.svg';
import iconGoogle from '../../assets/icons/icon-google.png';
import iconTwitch from '../../assets/icons/icon-twitch.png';
import iconTwitter from '../../assets/icons/icon-twitter.png';
import MetaMask from '../../assets/icons/metamask.svg';
import Coinbase from '../../assets/icons/coinbase.svg';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { useSmartWalletProvider } from 'contexts/SmartWalletContext';
import { useTranslation } from 'react-i18next';

const ConnectWallet: React.FC = () => {
  const { t } = useTranslation();
  const { web3Auth, connect, disconnect, isSigningIn, setShowEmailModal } = useSmartWalletProvider();
  const { connect: WagmiConnect, connectors } = useConnect();
  const { connector: WagmiConnector } = useAccount();
  const { disconnect: EVMDisconnect } = useDisconnect();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabSelect = (index:any) => {
    setSelectedTab(index);
  };
  const { isDarkMode, updateIsWalletConnectVisible, updateWeb3AuthInfo, web3AuthInfo, isSelectedChain } = useStore();
  const tabClasses = `flex 2xl:w-[35rem] xl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] max-sm:w-[21rem] h-[3.5rem] cursor-pointer transition-all ease-in-out duration-500 ${isDarkMode ? 'bg-[#eff7f8]' : 'bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-b-2 border-b-[#313337]'
    }`;
  const panelClasses = `2xl:w-[35rem] xl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] max-sm:w-[21rem] 2xl:h-[21.3rem] xl:h-[21.3rem] lg:h-[21.3rem] md:h-[21.3rem] sm:h-[21.3rem] max-sm:h-[21.3rem] bg-${isDarkMode ? 'bg-[#0f1012]' : 'gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] rounded-b-[1rem] '
    } overflow-scroll`;
  useEffect(() => {
    const update = async () => {
      if (web3Auth != null) {
        if (web3Auth!.status === 'connected') {
          const wagmiWeb3Provider = await web3Auth?.getUserInfo();
          updateWeb3AuthInfo(wagmiWeb3Provider?.typeOfLogin!);
        }
      }
    };
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Auth]);

  const web3soicalSign = (item: any) => {
    EVMDisconnect();
    connect(item);
  };
  const visibleSignInOptions = useMemo(() => {
    const signInOptions = [
      {
        title: 'Google',
        icon: <img src={iconGoogle} alt="google" className="w-[1.5rem] h-[1.5rem]" />,
        onClick: () => web3soicalSign('google'),
      },
      {
        title: 'Apple',
        icon: (
          <img src={isDarkMode ? iconApple_black : iconApple_white} alt="apple" className="w-[1.5rem] h-[1.5rem]" />
        ),
        onClick: () => web3soicalSign('apple'),
      },
      {
        title: 'Facebook',
        icon: <img src={iconFacebook} alt="facebook" className="w-[1.5rem] h-[1.5rem]" />,
        onClick: () => web3soicalSign('facebook'),
      },
      {
        title: 'Discord',
        icon: <img src={iconDiscord} alt="discord" className="w-[1.5rem] h-[1.5rem]" />,
        onClick: () => web3soicalSign('discord'),
      },
      {
        title: 'Twitter',
        icon: <img src={iconTwitter} alt="twitter" className="w-[1.5rem] h-[1.5rem]" />,
        onClick: () => web3soicalSign('twitter'),
      },
      {
        title: 'GitHub',
        icon: (
          <img src={isDarkMode ? iconGithub_balck : iconGithub_white} alt="github" className="w-[1.5rem] h-[1.5rem]" />
        ),
        onClick: () => web3soicalSign('github'),
      },
      {
        title: 'Twitch',
        icon: <img src={iconTwitch} alt="twitch" className="w-[1.5rem] h-[1.5rem]" />,
        onClick: () => web3soicalSign('twitch'),
      },
    ];
    return signInOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect, setShowEmailModal]);
  const switchEvmNetwork = (chainId: any, connector: any) => {
    switch (chainId) {
      case 97:
        connector?.switchChain && connector?.switchChain(97);
        WagmiConnect({ connector });
        break;
      default:
        break;
    }
  };

  return (
    <div className="fixed z-[2] inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[54px] flex flex-row justify-center 2xl:items-center xl:items-center lg:items-center md:items-center sm:items-center max-sm:items-center">
      <div
        className={`2xl:w-[35rem] xl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] max-sm:w-[21rem] h-[28.875rem] ${isDarkMode
          ? 'bg-[#FFFFFF] border-[#DAECEF]'
          : 'bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] backdrop-blur-[54.36563491821289px]'
          } border-2 rounded-[1rem]`}
      >
        <div className="flex justify-between p-[3%_5%] connect_bg_container  rounded-t-[1rem]  ">
          <div className={`text-${isDarkMode ? '#364152' : 'white'} font-[500] text-[1.25rem]`}>
            {t('connect_wallet')}
          </div>
          <img
            src={isDarkMode ? close_black : close}
            alt="close"
            className="cursor-pointer"
            onClick={() => {
              updateIsWalletConnectVisible(false);
            }}
          />
        </div>
        <div className="flex justify-center   ">
          <Tabs>
            <TabList className={tabClasses}>
              <Tab className={`text-${isDarkMode ? '#364152 ' : 'white'} pt-3 outline-none Tab_container px-8 ${selectedTab === 0 ? 'selected-tab' : ''}`} onClick={() => handleTabSelect(0)}>
                {t('social_wallets')}
              </Tab>
              <Tab className={`text-${isDarkMode ? '#364152' : 'white'} pt-3 outline-none Tab_container px-8 ${selectedTab === 1 ? 'selected-tab' : ''}`} onClick={() => handleTabSelect(1)}>
                {t('web3_wallets')}
              </Tab>
            </TabList>
            <TabPanel>
              <div className={panelClasses}>
                <ul className="flex flex-col ">
                  {visibleSignInOptions.map((signInOption) => (
                    <div
                      key={signInOption.title}
                      onClick={isSigningIn ? undefined : signInOption.onClick}
                      className={`${isDarkMode ? 'text-black' : 'text-white'
                        } flex flex-row items-center font-bold text-[1.3rem] py-6 px-6 my-1 rounded-2xl cursor-pointer hover:bg-[#00000033]`}
                    >
                      {signInOption.icon}
                      <div className="font-inter font-bold leading-5 pl-4">
                        <div className="text-lg ">{signInOption.title}</div>
                      </div>
                      {web3Auth?.status === 'connected' && web3AuthInfo === signInOption.title.toLowerCase() && (
                        <div
                          className="flex-1 text-right text-greyText"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            disconnect();
                          }}
                        >
                          {t('disconnect')}
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel>
              <div className={panelClasses}>
                <ul className="flex flex-col">
                  {connectors &&
                    connectors.map((connector, index) => {
                      return (
                        <div
                          className={` ${isDarkMode ? 'text-black' : 'text-white'
                            } flex items-center py-7 px-10 my-1 rounded-2xl cursor-pointer hover:bg-[#00000033]`}
                          onClick={() => switchEvmNetwork(isSelectedChain, connector)}
                          key={index}
                        >
                          {connector.id === 'metaMask' ? (
                            <img className="w-[1.5rem] h-[1.5rem]" src={MetaMask} alt="token" />
                          ) : (
                            <img className="w-[1.5rem] h-[1.5rem]" src={Coinbase} alt="token" />
                          )}
                          <div className="font-inter font-bold leading-5 pl-4">
                            <div className="text-lg ">{connector.name}</div>
                          </div>
                          {WagmiConnector?.id === connector.id && (
                            <div
                              className="flex-1 text-right text-greyText"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                EVMDisconnect();
                              }}
                            >
                              {t('disconnect')}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </ul>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
