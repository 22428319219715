import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useStore } from 'store';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
let delayed: any;


export function ChartApp() {
  const { chartValue } = useStore();
  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context: any) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          // Customize the tooltip to display additional values
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            const Time = chartValue[context.dataIndex].timeDuration;
            const Eth = chartValue[context.dataIndex].eth;
            const lev = chartValue[context.dataIndex].leverage;
            

            if(label==='Long Switch'){
              return [
                `${'LS Return'}:  ${value} %`,
  
              ];
            }else{
              return [
                `${'SS Return'}:  ${value} %`,
                `ETH Price change : ${Eth} %`,
                `Leverage: ${lev}`,
                `Cycle duration : ${Time}`,

              ];
            }

          },
  
        },
      },
    },
  };
  const data = {
    labels: chartValue.map((item: any) => `${item.shortdate} ${item.time}`),
    datasets: [
      {
        label: 'Long Switch',
        data: chartValue.map((item: any) => item.long),
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 0',
      },
      {
        label: 'Short Switch',
        data: chartValue.map((item: any) => item.short),
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 1',
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
