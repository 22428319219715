import { FetchSignerResult } from '@wagmi/core';
import { ABIs, Addresses } from './data';
import { Contract, ethers } from 'ethers';
import { getERC20Allowance } from './helper';

export const executeOpenOrder = async (
  orderType: string,
  amount: number,
  signer: FetchSignerResult<ethers.Signer> | undefined
) => {
  try {
    if (!signer) {
      throw new Error('Signer is undefined');
    }
    const contract = new Contract(Addresses.Controller, ABIs.Controller, signer);
    const gp = await signer.getGasPrice();
    const overrides = {
      gasLimit: 1000000,
      gasPrice: gp,
    };

    let isAllowed: any;

    if (amount) {
      isAllowed = await getERC20Allowance(signer, Addresses.Controller, amount);
    }
    const amountInWei = ethers.utils.parseEther(amount.toString());
    let responseId: any;
    if (orderType === 'buyLong') {
      if (!isAllowed) {
        const contractProxyUSDC = new ethers.Contract(Addresses.ProxyUSDC, ABIs.ProxyUSDC, signer);
        const amountInAllowance = ethers.utils.parseEther(amount.toString());
        const tx = await contractProxyUSDC.connect(signer).approve(Addresses.Controller, amountInAllowance, overrides);
        await tx.wait();
      }
      const txContract = await contract.buyLongToken(amountInWei, overrides);
      responseId = await txContract.wait();
    } else if (orderType === 'buyShort') {
      if (!isAllowed) {
        const contractProxyUSDC = new ethers.Contract(Addresses.ProxyUSDC, ABIs.ProxyUSDC, signer);
        const amountInAllowance = ethers.utils.parseEther(amount.toString());
        const tx = await contractProxyUSDC.connect(signer).approve(Addresses.Controller, amountInAllowance, overrides);
        await tx.wait();
      }
      const txContract = await contract.buyShortToken(amountInWei, overrides);
      responseId = await txContract.wait();
    } else if (orderType === 'sellLong') {
      const txContract = await contract.sellLongToken(amountInWei, overrides);
      responseId = await txContract.wait();
    } else if (orderType === 'sellShort') {
      const txContract = await contract.sellShortToken(amountInWei, overrides);
      responseId = await txContract.wait();
    } else {
      console.log('Unsupported order type:', orderType);
    }
    await sleep(5000);

    return responseId;
  } catch (error: any) {
    if (error.toString().includes('Error: user rejected transaction')) {
      if (error['code'] === 'ACTION_REJECTED') {
        console.log('Error code is ACTION_REJECTED:', error['code']);
        // eslint-disable-next-line no-throw-literal
        throw 'ACTION_REJECTED'; // Re-throw the same error
      }
    } else {
      console.error('Error executing order:', error.toString());
      throw error; // Re-throw the same error
    }
  }
};

export const executeCancelOrder = async (type: string, signer: FetchSignerResult<ethers.Signer> | undefined) => {
  try {
    if (!signer) {
      throw new Error('Signer is undefined');
    }

    const contract = new Contract(Addresses.Controller, ABIs.Controller, signer);
    const gp = await signer.getGasPrice();
    const overrides = {
      gasLimit: 1000000,
      gasPrice: gp,
    };
    let responseId: any;
    if (type === 'Long Switch Token Buy') {
      const txContract = await contract.cancelLongTokenBuy(overrides);
      responseId = await txContract.wait();
    } else if (type === 'Long Switch Token Sell') {
      const txContract = await contract.cancelLongTokenSell(overrides);
      responseId = await txContract.wait();
    } else if (type === 'Short Switch Token Buy') {
      const txContract = await contract.cancelShortTokenBuy(overrides);
      responseId = await txContract.wait();
    } else if (type === 'Short Switch Token Sell') {
      const txContract = await contract.cancelShortTokenSell(overrides);
      responseId = await txContract.wait();
    }
    return responseId;
  } catch (error: any) {
    if (error.toString().includes('Error: user rejected transaction')) {
      if (error['code'] === 'ACTION_REJECTED') {
        console.log('Error code is ACTION_REJECTED:', error['code']);
        // eslint-disable-next-line no-throw-literal
        throw 'ACTION_REJECTED'; // Re-throw the same error
      }
    } else {
      console.error('Error executing order:', error.toString());
      throw error; // Re-throw the same error
    }
  }
};

export const executeClaimOrder = async (token_id: any, signer: FetchSignerResult<ethers.Signer> | undefined) => {
  try {
    if (!signer) {
      throw new Error('Signer is undefined');
    }
    const contract = new Contract(Addresses.Vault, ABIs.Vault, signer);
    const gp = await signer.getGasPrice();
    const overrides = {
      gasLimit: 1000000,
      gasPrice: gp,
    };
    let receipt: any;
    if (token_id) {
      const tx = await contract.balanceOf(signer.getAddress(), token_id);
      const txContract = await contract.claim(token_id, tx.toString(), overrides);
      receipt = await txContract.wait();
    }
    await sleep(5000);
    return receipt;
  } catch (error: any) {
    if (error.toString().includes('Error: user rejected transaction')) {
      if (error['code'] === 'ACTION_REJECTED') {
        console.log('Error code is ACTION_REJECTED:', error['code']);
        // eslint-disable-next-line no-throw-literal
        throw 'ACTION_REJECTED'; // Re-throw the same error
      }
    } else {
      console.error('Error executing order:', error.toString());
      throw error; // Re-throw the same error
    }
  }
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const submitFaucetPayload = async (type: string, signer: FetchSignerResult<ethers.Signer> | undefined) => {
  try {
    if (!signer) {
      throw new Error('Signer is undefined');
    }
    const amountInEth = 0.00001;
    const amountInWei = ethers.utils.parseEther(amountInEth.toString()); // Convert amount to Wei
    const contract = new Contract(Addresses.ProxyUSDC, ABIs.ProxyUSDC, signer);
    const gp = await signer.getGasPrice();
    const overrides = {
      value: amountInWei,
      gasLimit: 1000000,
      gasPrice: gp,
    };
    let responseId: any;
    if (type === 'pUSDC') {
      const txContract = await contract.mint(overrides);
      responseId = await txContract.wait();
    }
    return responseId;
  } catch (error: any) {
    if (error.toString().includes('Error: user rejected transaction')) {
      if (error['code'] === 'ACTION_REJECTED') {
        console.log('Error code is ACTION_REJECTED:', error['code']);
        // eslint-disable-next-line no-throw-literal
        throw 'ACTION_REJECTED'; // Re-throw the same error
      }
    } else {
      console.error('Error executing order:', error.toString());
      throw error; // Re-throw the same error
    }
  }
};
