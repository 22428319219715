export type Timing = {
  cycleStartTimestampFinal: string;
  tentativeCycleEndTimeFinal: string;
  cycleStartPriceFinal: string;
  tentativeCycleEndPriceFinal: string;
  triggerPriceUp: string;
  triggerPriceDown: string;
  currentExpectedPremium: string;
  nextExpectedPremium: string;
  currentPercentageChart: {
    longTokenPercentage: number;
    shortTokenPercentage: number;
  };
  nextPercentageChart: {
    longTokenPercentage: number;
    shortTokenPercentage: number;
  };
  currentCyclelongTokenSupply: number;
  currentCycleshortTokenSupply: number;
  nextCyclelongTokenSupply: number;
  nextCycleshortTokenSupply: number;
  currentLeverage:number;
  nextLeverage:number;
};
export type ExpectedReturType = {
  Long: String,
  Short: String,
};
export const initialTiming = {
  cycleStartTimestampFinal: '00:00',
  tentativeCycleEndTimeFinal: '00:00',
  cycleStartPriceFinal: '0.00',
  tentativeCycleEndPriceFinal: '0.00',
  triggerPriceUp: '0.00',
  triggerPriceDown: '0.00',
  currentExpectedPremium: '0.00',
  nextExpectedPremium: '0.00',
  currentPercentageChart: {
    longTokenPercentage: 0,
    shortTokenPercentage: 0,
  },
  nextPercentageChart: {
    longTokenPercentage: 0,
    shortTokenPercentage: 0,
  },
  currentCyclelongTokenSupply: 0,
  currentCycleshortTokenSupply: 0,
  nextCyclelongTokenSupply: 0,
  nextCycleshortTokenSupply: 0,
  currentLeverage:0,
  nextLeverage:0,
};

export const initialExpectedReturnValue = {
  Long: '0',
  Short: '0',
};