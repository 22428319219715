export const BINANCE_BSC = {
  chainId: 56,
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  symbol: 'BNB',
  name: 'BNB',
  decimals: 18,
  logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
};

export const ETHEREUM_ETH = {
  chainId: 1,
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  symbol: 'ETH',
  name: 'Ethereum',
  decimals: 18,
  logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
};

export const BINANCE_BSC_TESTNET = {
  chainId: 97,
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  symbol: 'BNB',
  name: 'BNB',
  decimals: 18,
  logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
};
export const BINANCE_BSC_TESTNET_LONGTOKEN = {
  chainId: 97,
  address: '0x71915496973eE59e36E140e5BBc2589d3282A453',
  symbol: 'LSWITCHETH',
  name: 'LongSWITCHETHUSD',
  decimals: 18,
};
export const BINANCE_BSC_TESTNET_SHORTTOKEN = {
  chainId: 97,
  address: '0xd3a6E24F03943bf56bdc275E5603bff8F7E2e4C8',
  symbol: 'SSWITCHETH',
  name: 'ShortSWITCHETHUSD',
  decimals: 18,
};
export const BINANCE_BSC_TESTNET_PROXYUSDC = {
  chainId: 97,
  address: '0x30b74a8766563dbabaf04e0ffe392817f2d02da1',
  symbol: 'pUSDC',
  name: 'ProxyUSDC',
  decimals: 18,
};
