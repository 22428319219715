import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

interface RunningClockProps {
  startTime: string;
  endTime: string;
}

const RunningClock: React.FC<RunningClockProps> = ({ startTime, endTime }) => {
  const [timeDifference, setTimeDifference] = useState<number>(0);

  useEffect(() => {
    const startDateTime = parseTimeToDateTime(startTime);
    const endDateTime = parseTimeToDateTime(endTime);

    const timer = setInterval(() => {
      const currentTime = DateTime.utc(); // Use Luxon's DateTime.utc()
      const difference = endDateTime.diff(currentTime, 'seconds').as('seconds');
      if (difference <= 0) {
        // The timer has reached the end time, clear the interval
        clearInterval(timer);
        setTimeDifference(0);
      } else {
        setTimeDifference(difference);
      }
    }, 1000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(timer);
  }, [startTime, endTime]);

  const hours = Math.floor(timeDifference / 3600);
  const minutes = Math.floor((timeDifference % 3600) / 60);
  return (
    <div>
      <div>
      {hours === 0 ?`${minutes} mins`: `${hours} hr ${minutes} mins`}
      </div>
    </div>
  );
};

// Function to parse "hh:mm AM/PM" time format to a Luxon DateTime object
const parseTimeToDateTime = (timeString: string): DateTime => {
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':');

  let hoursNum = parseInt(hours, 10);
  if (period === 'PM' && hoursNum !== 12) {
    hoursNum += 12;
  } else if (period === 'AM' && hoursNum === 12) {
    hoursNum = 0;
  }

  return DateTime.utc().set({
    hour: hoursNum,
    minute: parseInt(minutes, 10),
    second: 0,
  });
};

export default RunningClock;
