import React, { useCallback, useEffect } from 'react';
import kanalogo from '../assets/icons/kana_logo.svg';
import Logo from '../assets/icons/Logo.svg';
import twitter from '../assets/icons/twitter.svg';
import medium from '../assets/icons/medium.svg';
import discord from '../assets/icons/discord.svg';
import EthosX from '../assets/icons/EthosX.svg';
import telegram from '../assets/icons/telegram.svg';
import { useState } from 'react';
import Eth from '../assets/icons/Eth.svg';
import Vectordown from '../assets/icons/Vectordown.svg';
import menu from '../assets/icons/menu.svg';
import linechartup from '../assets/icons/linechartup.svg';
import { useStore } from 'store';
import kanablack from '../assets/icons/kanablack.svg';
import blacklinechart from '../assets/icons/blacklinechart.svg';
import menublack from '../assets/icons/menublack.svg';
import blackmedium from '../assets/icons/blackmedium.svg';
import blacktwitter from '../assets/icons/blacktwitter.svg';
import blackdiscord from '../assets/icons/blackdiscord.svg';
import blacktelegram from '../assets/icons/blacktelegram.svg';
import Vectorlight from '../assets/icons/Vectorlight.svg';
import MetaMask from '../assets/icons/metamask.svg';
import Coinbase from '../assets/icons/coinbase.svg';
import iconApple_white from '../assets/icons/icon-apple-white.svg';
import iconApple_black from '../assets/icons/icon-apple-black.svg';
import iconDiscord from '../assets/icons/icon-discord.png';
import iconFacebook from '../assets/icons/icon-facebook.png';
import iconGithub_white from '../assets/icons/github_white.svg';
import iconGithub_balck from '../assets/icons/github_black.svg';
import iconGoogle from '../assets/icons/icon-google.png';
import iconTwitch from '../assets/icons/icon-twitch.png';
import iconTwitter from '../assets/icons/icon-twitter.png';
import { useAccount, useDisconnect } from 'wagmi';
import { useSmartWalletProvider } from 'contexts/SmartWalletContext';
import { useTranslation } from 'react-i18next';
import Copy from '../assets/icons/copy.svg';
import Power from '../assets/icons/power.svg';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
const Sidebar = () => {
  const { disconnect: EVMDisconnect } = useDisconnect();
  const { t } = useTranslation();
  const [isDropdown, setIsDropdown] = useState<boolean>(false);
  const { isDarkMode, isSelectedChain, updateIsWalletConnectVisible, web3AuthInfo, updateActiveTab} = useStore();
  const { connector, address, isConnected: web3IsConnected } = useAccount();
  const { isSocialLogin } = useSmartWalletProvider();
  const navigate = useNavigate();
  const activeTab = localStorage.getItem('activeTab');
  const toggleDropdown = () => {
    setIsDropdown(!isDropdown);
  };
  const handleTabClick = (tabName: any) => {
    updateActiveTab(tabName);
    switch (tabName) {
      case 'Learn':
        navigate("/learn");
        break;
      case 'switch':
        navigate("/");
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    switch (isSelectedChain) {
      case 97:
        updateIsWalletConnectVisible(false);
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isSocialLogin]);
  const getLogo = (name: any) => {
    let id = '';
    switch (name) {
      case 'google':
        id = iconGoogle;
        break;
      case 'apple':
        id = isDarkMode ? iconApple_black : iconApple_white;
        break;
      case 'facebook':
        id = iconFacebook;
        break;
      case 'discord':
        id = iconDiscord;
        break;
      case 'twitter':
        id = iconTwitter;
        break;
      case 'github':
        id = isDarkMode ? iconGithub_balck : iconGithub_white;
        break;
      case 'twitch':
        id = iconTwitch;
        break;
    }
    return id;
  };

  const tabs = [
    { id: 'switch', label: 'Switch' },
    { id: 'Learn', label: 'Learn' },
  ];
  const copyHash = useCallback(async (hash: any) => {
    await navigator.clipboard.writeText(hash);
  }, []);
  return (
    <div className=" fixed  top-0 bottom-0 left-0 !z-[2]   transition-transform duration-300 ease-in  ">
      <div
        className={`${
          isDarkMode ? 'bg-white' : 'bg-[#1D1E20]'
        }    2xl:w-[19rem] xl:w-[19rem] lg:w-[19rem] md:w-auto sm:w-auto max-sm:w-auto h-full font-inter flex flex-col !justify-between`}
      >
        <div className="flex flex-col justify-start items-start">
          <div className="mx-[16px] 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden max-sm:hidden">
            <img src={isDarkMode ? kanablack : kanalogo} alt="/Kanalogo" className=" px-8 pt-8" />
          </div>
          <div className=" 2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline ">
            <div className=" flex justify-between p-[3%_4%]">
              <img src={Logo} alt="Logo" />
              <div>
                <div
                  className={`${
                    isDarkMode
                      ? ' 2xl:bg-[#ffffff0f] xl:bg-[#ffffff0f] lg:bg-[#ffffff0f] md:bg-[#EFF7F8] sm:bg-[#EFF7F8] max-sm:bg-[#EFF7F8] mt-2'
                      : ' 2xl:bg-[#ffffff0f] xl:bg-[#ffffff0f] lg:bg-[#ffffff0f] md:bg-[#252729] sm:bg-[#252729] max-sm:bg-[#252729] mt-2'
                  }2xl:w-[19.167rem] xl:w-[19.167rem] lg:w-[19.167rem] md:w-[19.167rem] sm:w-[13.813rem] max-sm:w-[13.813rem] h-[2.5rem] rounded-[2rem]`}
                >
                  <div className="flex justify-between cursor-pointer" onClick={toggleDropdown}>
                    <div className="flex pt-1 pl-6">
                      <img src={Eth} alt="logoURI" className="" />
                      <span className={`${isDarkMode ? ' text-black' : ' text-white'}pl-2.5 pt-0.5 font-bold`}>
                        {t('eth')}
                      </span>
                    </div>
                    <div>
                      <img src={isDarkMode ? Vectorlight : Vectordown} alt="VectorDown" className=" mr-5 pt-3.5" />
                    </div>
                    {isDropdown && (
                      <div
                        id="dropdown-options"
                        className="2xl:bg-[#ffffff0f] pl-4 leading-3 xl:bg-[#ffffff0f] lg:bg-[#ffffff0f] md:bg-[#ffffff0f] sm:bg-[#ffffff0f] max-sm:bg-[#ffffff0f] border-[#ffffff0f] border-2 absolute !z-[100] rounded-[1rem] overflow-auto scroll-smooth backdrop-blur-sm  2xl:w-[18.854rem] xl:w-[18.875rem] lg:w-[18.875rem] md:w-[18.875rem] sm:w-[14.625rem] max-sm:w-[14.625rem] 2xl:h-[10rem] xl:h-[10rem] lg:h-[10rem] md:h-[8.5rem] sm:h-[7.425rem] max-sm:h-[7.425rem] 2xl:left-[24rem] xl:left-[24rem] lg:left-[24rem] md:left-[16.5rem] sm:left-[6rem] max-sm:left-[6rem] top-[4.7rem] "
                      >
                        <div className=" flex pt-3.5">
                          <div>
                            <img src={Eth} alt="Eth" />
                          </div>
                          <div className="text-[#ffffff80] pl-3 pt-1.5">{t('etheruem')}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex pt-3">
                <img
                  src={isDarkMode ? blacklinechart : linechartup}
                  alt="linechartup"
                  className=" w-[1.5rem] h-[1.5rem]"
                />
                <img src={isDarkMode ? menublack : menu} alt="/menu" className=" ml-4 w-[1.5rem] h-[1.5rem]" />
              </div>
            </div>
          </div>
          <div className="2xl:hidden xl:hidden lg:hidden md:inline-flex sm:inline-flex max-sm:inline-flex pt-12 "></div>
          <div className="flex mx-[16px] 2xl:pt-12 xl:pt-12 lg:pt-12 md:pt-12 sm:pt-2 max-sm:pt-2">
            <ul className="text-[1rem] ">
              {tabs.map((tab) => (
                <li
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  className={`pl-6 pt-4 cursor-pointer text-[#FFFFFF66] h-[3.5rem] w-[17rem] rounded-[1rem] ${
                    activeTab === tab.id
                      ? isDarkMode
                        ? 'bg-[#A5F0FC] rounded-[1rem] text-[black] font-[400]'
                        : 'bg-[rgba(255,255,255,0.06)] rounded-[1rem] '
                      : isDarkMode
                      ? ' text-black font-[400] '
                      : ' text-[#164C63] font-[400]'
                  }`}
                >
                  <span
                    className={` ${
                      activeTab === tab.id
                        ? isDarkMode
                          ? 'text-[#164C63] font-[700]'
                          : 'text-[#ffffff] font-[700]'
                        : isDarkMode
                        ? 'hover:text-[#164C63] hover:font-[700]'
                        : 'hover:text-[#ffffff] hover:font-[700]   '
                    }`}
                  >
                    {tab.label}
                  </span>
                </li>
              ))}
              <li
                className={`${
                  isDarkMode ? 'text-[#364152]' : 'text-[#FFFFFF66]'
                }2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline  hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#67FFD1] to-[#4F9DFF] hover:font-bold h-[3.5rem] w-[17rem]  pl-6 pt-3 cursor-pointer hover:bg-[#101112] hover:rounded-[1rem]`}
              >
                <span className="text-[#FF3750]">Disconnect Wallet</span>
              </li>
            </ul>
          </div>
          <div className="2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline">
            <div
              className={`${
                isDarkMode
                  ? 'flex justify-center text-[0.875rem] text-[#364152] pt-28'
                  : 'flex justify-center text-[0.875rem] text-[white] pt-28'
              }`}
            >
              {t('follow_us')}
            </div>
            <div className=" flex justify-around p-[5%_14%]">
              <a href="https://t.me/kanalabsofficial" target="_blank" rel="noreferrer">
                <img src={isDarkMode ? blacktelegram : telegram} alt="telegram" className=" cursor-pointer" />
              </a>
              <a href="https://twitter.com/kanalabs" target="_blank" rel="noreferrer">
                <img src={isDarkMode ? blacktwitter : twitter} alt="twitter" className=" cursor-pointer" />
              </a>
              <a href="https://kanalabs.medium.com/" target="_blank" rel="noreferrer">
                <img src={isDarkMode ? blackmedium : medium} alt="medium" className=" cursor-pointer" />
              </a>
              <a href="https://discord.com/invite/CrqCaQ45kZ" target="_blank" rel="noreferrer">
                <img src={isDarkMode ? blackdiscord : discord} alt="discord" className=" cursor-pointer" />
              </a>
            </div>
            <div className=" flex justify-center">
              <a href="https://kanalabs.io/contact-us-dev">
                <button
                  className={`${
                    isDarkMode
                      ? 'text-[#364152] w-[20.5rem] h-[2.438rem] border-2 btn-gradient '
                      : 'w-[20.5rem] h-[2.438rem] border-2 btn-gradient text-white'
                  }`}
                >
                  {t('contact_us')}
                </button>
              </a>
            </div>
            <div className=" flex justify-around pt-12 text-[#FFFFFF66] text-[0.875rem]">
              <div>Switch</div>
              <div className="flex">
                {t('powered')}
                <img src={EthosX} alt="ethosx" className=" ml-2" />
              </div>
            </div>
            <div className=" mt-10">
              <br></br>
            </div>
          </div>
        </div>
        <div className="mx-[16px] 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden mb-5">
          <div
            className={`${
              isDarkMode ? 'text-[#202939]  bg-[#EFF7F8] ' : 'text-[#FFFFFF66] bg-[#101112] '
            } px-6 h-[3.5rem] w-[17rem] pt-4 cursor-pointer rounded-[1rem]`}
          >
            {web3IsConnected ? (
              <div className={`flex items-center   `}>
                <img
                  className="w-[1.3rem] h-[1.3rem] mr-1"
                  src={connector?.id === 'metaMask' ? MetaMask : Coinbase}
                  alt=""
                />
                {address?.slice(0, 7) + '..' + address?.toString()?.slice(-7)}

                <img
                  src={Copy}
                  alt="Copy"
                  className="ml-2"
                  onClick={() => {
                    copyHash(address);
                    notification.success({
                      message: 'Address Copied',
                    });
                  }}
                />
                <img
                  src={Power}
                  alt="Power "
                  className="ml-2"
                  onClick={() => {
                    EVMDisconnect();
                  }}
                />
              </div>
            ) : isSocialLogin ? (
              <div className={`flex items-center   `}>
                <img className="w-[1.3rem] h-[1.3rem] mr-1" src={getLogo(web3AuthInfo)} alt="" />
                {address?.slice(0, 10) + '..' + address?.toString()?.slice(-10)}
                <img src={Copy} alt="Copy" />
                <img src={Power} alt="Power " />
              </div>
            ) : (
              <span
                className={`${
                  isDarkMode
                    ? 'hover:bg-[#EFF7F8] gap-[8px] flex flex-row justify-start items-center text-[#2ED3B7] font-[400] hover:font-bold text-[#202939]'
                    : '  gap-[8px]  flex flex-row justify-start items-center  text-[#2ED3B7]  hover:font-bold'
                }`}
                onClick={() => {
                  updateIsWalletConnectVisible(true);
                }}
              >
                {t('connect_wallet')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
